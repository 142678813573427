<template>
  <ag-select
    :items="financialProfilesItems"
    v-model="financialProfilePublicId"
    :error="error"
    :value="financialProfilePublicId"
    :label="label"
    variant="outlined"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FinancialProfile } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "FinancialProfileDropdown",
  props: {
    error: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data(): {
    financialProfilePublicId: string | null;
  } {
    return {
      financialProfilePublicId: null,
    };
  },
  computed: {
    financialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },

    financialProfilesItems(): {
      label: string;
      value: string;
    }[] {
      if (this.financialProfiles) {
        return this.financialProfiles.map((item) => ({
          label: `${item.plan_name}: ${item.financial_profile_name} (${item.platform_id})`,
          value: item.public_id,
        }));
      } else {
        return [];
      }
    },
  },
  created() {
    if (this.financialProfiles) {
      let defaultFinancialProfile = this.financialProfiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.financialProfilePublicId = defaultFinancialProfile.public_id;
      }
    }
  },
  watch: {
    financialProfilePublicId(value) {
      this.$emit("onUpdateFinancialProfile", value);
    },
  },
});
</script>
