<template>
  <MDataTable
    :headers="tableHeaders"
    :data="travelers"
    :item-per-page="30"
    :is-api-paginated="false"
    :has-search="false"
    :has-pagination="false"
  >
  </MDataTable>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { MDataTable } from "@aeroglobe/ag-core-ui";

import { PassengerTraveler } from "@/modules/FlightIssuance/dtos/flightIssuance.dto";
import { PassengersList } from "@/ag-flight-components/types";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY_WITH_DASH } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "MFlightTravelers",
  components: {
    MDataTable,
  },
  data() {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      tableHeaders: [
        {
          title: "Passenger Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Surname",
          value: "surname",
          key: "surname",
          sortable: true,
        },
        {
          title: "Passenger Type",
          value: "type",
          key: "type",
          sortable: true,
        },
        {
          title: "Birth Date",
          value: "birthDate",
          key: "birthDate",
          sortable: true,
        },
        {
          title: "Base Fare",
          value: "base_fare",
          key: "base_fare",
          sortable: true,
        },
        {
          title: "Fees",
          value: "fees",
          key: "fees",
          sortable: true,
        },
        {
          title: "Tax",
          value: "taxes",
          key: "taxes",
          sortable: true,
        },
        {
          title: "Surcharges",
          value: "surcharges",
          key: "surcharges",
          sortable: true,
        },
        {
          title: "Total Fare",
          value: "total_fare",
          key: "total_fare",
          sortable: true,
        },
      ] as MDataTableHeader[],
      rowsPerPage: 10,
      currentPage: 1,
    };
  },
  watch: {
    rowsPerPage() {
      this.currentPage = 1;
    },
  },
  methods: {
    getFormattedDateTime,
    formatString<T extends number | string>(value: T | undefined): string {
      return value ? String(value) : typeof value === "number" ? "0" : "";
    },
  },
  computed: {
    travelers(): PassengerTraveler[] {
      const travelers = this.$store.getters.airTravelers.map(
        (traveler: PassengersList) => {
          const title = traveler.title;
          const personName = traveler.person_name;
          const surname = traveler.surname;
          const passengerTypeCode = this.formatString(
            traveler?.passenger_type_code
          );
          const birthdate = traveler?.birthdate
            ? new Date(traveler.birthdate)
            : "-";
          const formattedBirthdate =
            birthdate != "-"
              ? this.getFormattedDateTime(
                  birthdate,
                  FORMAT_DD_MMM_YYYY_WITH_DASH
                )
              : "-";
          const baseFare =
            traveler?.pricing_detail?.fare.value === null
              ? "-"
              : traveler?.pricing_detail?.fare.currency +
                " " +
                this.formatString(traveler?.pricing_detail?.fare.value);
          const surcharges =
            traveler?.pricing_detail?.surcharges.value === null
              ? "-"
              : traveler?.pricing_detail?.surcharges.currency +
                " " +
                this.formatString(traveler?.pricing_detail?.surcharges.value);
          const taxes =
            traveler?.pricing_detail?.total_tax.value === null
              ? "-"
              : traveler?.pricing_detail?.total_tax.currency +
                " " +
                this.formatString(traveler?.pricing_detail?.total_tax.value);
          const totalFare =
            traveler?.pricing_detail?.total_fare.value === null
              ? "-"
              : traveler?.pricing_detail?.total_fare.currency +
                " " +
                this.formatString(traveler?.pricing_detail?.total_fare.value);
          const fees =
            traveler?.pricing_detail?.fees.value === null
              ? "-"
              : traveler?.pricing_detail?.fees.currency +
                " " +
                this.formatString(traveler?.pricing_detail?.fees.value);

          return {
            name: `${title} ${personName}`,
            surname: surname,
            type: passengerTypeCode,
            birthDate: formattedBirthdate,
            base_fare: `${baseFare}`,
            surcharges: `${surcharges}`,
            taxes: `${taxes}`,
            total_fare: `${totalFare}`,
            fees: `${fees}`,
          };
        }
      );
      return travelers;
    },
  },
});
</script>
