import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bddc7a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "responsive-grid" }
const _hoisted_3 = { class: "grid-item" }
const _hoisted_4 = { class: "grid-item" }
const _hoisted_5 = { class: "grid-item" }
const _hoisted_6 = { class: "grid-item" }
const _hoisted_7 = { class: "grid-item" }
const _hoisted_8 = { class: "grid-item" }
const _hoisted_9 = { class: "grid-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_financial_profile_box = _resolveComponent("financial-profile-box")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgTextarea = _resolveComponent("AgTextarea")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!

  return (_ctx.isFetchingCreditLimitRequestDetail)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (!_ctx.isFetchingCreditLimitRequestDetail && _ctx.creditLimitRequestDetail)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MCard, { class: "m-card" }, {
            default: _withCtx(() => [
              _createVNode(_component_MTypography, { type: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Agent Information ")
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Email")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.creditLimitRequestDetail?.agentEmail || ""), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Sector")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.creditLimitRequestDetail?.agentSectorName || ""), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Request Priority")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formatWordIntoCapitalize(_ctx.creditLimitRequestDetail?.priority || "")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Requested Amount")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.requestedAmount), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Credit Days")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.requestedDays), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Request Status")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.requestStatus(_ctx.creditLimitRequestDetail?.status)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Agent Comments")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MTypography, {
                    type: "label",
                    class: "heading_label"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.creditLimitRequestDetail?.requestNotes || "N/A"), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            (_ctx.isFetchingFinancialProfile)
              ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
              : (!_ctx.isFetchingFinancialProfile && _ctx.financialProfile)
                ? (_openBlock(), _createBlock(_component_financial_profile_box, {
                    key: 1,
                    "financial-profile": _ctx.financialProfile
                  }, null, 8, ["financial-profile"]))
                : _createCommentVNode("", true)
          ]),
          (_ctx.creditLimitRequestDetail?.requestHistory?.length)
            ? (_openBlock(), _createBlock(_component_MCard, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_MTypography, {
                    class: "description",
                    type: "headline"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Request History ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MDataTable, {
                    headers: _ctx.headers,
                    data: _ctx.creditLimitRequestDetail?.requestHistory || [],
                    "items-per-page": _ctx.creditLimitRequestDetail?.requestHistory?.length || 0,
                    "has-pagination": false,
                    "has-search": false
                  }, {
                    lastUpdatedAt: _withCtx(({ item }) => [
                      (item.lastUpdatedAt)
                        ? (_openBlock(), _createBlock(_component_MTypography, {
                            key: 0,
                            class: "description",
                            type: "body"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.lastUpdatedDate(item.lastUpdatedAt)), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      (item.lastUpdatedAt)
                        ? (_openBlock(), _createBlock(_component_MTypography, {
                            key: 1,
                            class: "description",
                            type: "label"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.lastUpdatedTime(item.lastUpdatedAt)), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    comments: _withCtx(({ item }) => [
                      (item.comments)
                        ? (_openBlock(), _createBlock(_component_MTypography, {
                            key: 0,
                            class: "description",
                            type: "label"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.comments), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : (_openBlock(), _createBlock(_component_MTypography, {
                            key: 1,
                            class: "description",
                            type: "label"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" - ")
                            ]),
                            _: 1
                          }))
                    ]),
                    actionType: _withCtx(({ item }) => [
                      _createVNode(_component_MTypography, {
                        class: "description",
                        type: "label"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.actionType.replace(/_/g, " ")), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }, 8, ["headers", "data", "items-per-page"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.creditLimitRequestDetail?.canApprove)
            ? (_openBlock(), _createBlock(_component_ag_card, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_heading, {
                    variant: "h3",
                    title: "Sales Person Review",
                    class: "margin_bottom_30"
                  }),
                  _createVNode(_component_AgTextarea, {
                    rows: "",
                    modelValue: _ctx.salesPersonComments,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.salesPersonComments) = $event)),
                    value: _ctx.salesPersonComments,
                    label: "Comments",
                    error: "",
                    class: "margin_bottom_20"
                  }, null, 8, ["modelValue", "value"]),
                  _createVNode(_component_AGButton, {
                    "test-id": "",
                    "is-loading": _ctx.isProcessingCreditLimitRequest && _ctx.approvedStatus,
                    class: "margin_right_10",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onProcessCreditLimitRequest(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Approve ")
                    ]),
                    _: 1
                  }, 8, ["is-loading"]),
                  _createVNode(_component_AGButton, {
                    "test-id": "",
                    "is-loading": _ctx.isProcessingCreditLimitRequest && !_ctx.approvedStatus,
                    variant: "danger",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onProcessCreditLimitRequest(false)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Reject ")
                    ]),
                    _: 1
                  }, 8, ["is-loading"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(_component_ag_not_found, {
          key: 2,
          title: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND,
          description: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION
        }, null, 8, ["title", "description"]))
}