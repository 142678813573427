import { GetterTree } from "vuex";
import { IOrganizationState } from "@/ag-portal-common/interfaces/organizationState.interface";
import {
  IOrganization,
  ISaasEliteOrganization,
} from "@/ag-portal-common/interfaces/organization.interface";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";
import {
  IWebsiteConfig,
  IWebsiteContent,
} from "@/ag-portal-common/interfaces/website.interface";
import { IWhatsappMember } from "@/ag-portal-common/interfaces/whatsappMember.interface";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { IOrganizationStats } from "@/ag-portal-common/interfaces/organizationStatistics.interface";
import { IOrganizationProductivity } from "@/ag-portal-common/interfaces/organizationProductivity.interface";
import { CurrencyConversion } from "@/ag-portal-common/types/index";
import { ISalesSector } from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IOrganizationState, StoreState> = {
  organizations: (state): Array<IOrganization> => state.organizations,
  organization: (state): IOrganizationDetail | null => state.organization,
  isAddingAgent: (state): boolean => state.isAddingAgent,
  isFetchingOrganizations: (state): boolean => state.isFetchingOrganizations,
  isDeletingAgentFromOrganization: (state): boolean =>
    state.isDeletingAgentFromOrganization,
  isFetchingOrganization: (state): boolean => state.isFetchingOrganization,
  isCreatingOrganization: (state): boolean => state.isCreatingOrganization,
  isUpdatingOrganization: (state): boolean => state.isUpdatingOrganization,
  isUpdateWebsiteLoading: (state): boolean =>
    state.isUpdateWebsiteConfigurationLoading,
  isWebsiteConfigLoading: (state): boolean => state.isWebsiteConfigLoading,
  isWebsiteContentLoading: (state): boolean =>
    state.isUpdateWebsiteContentLoading,
  websiteConfig: (state): IWebsiteConfig | null => state.websiteConfig,
  websiteContent: (state): IWebsiteContent | null => state.websiteContent,
  isWhatsappMembersLoading: (state): boolean => state.isWhatsappMembersLoading,
  isWhatsappActionLoading: (state): boolean => state.whatsappActionLoading,
  whatsappMembers: (state): IWhatsappMember[] => state.whatsappMembers,
  organizationPassengers: (state): IPassenger[] | null => state.passengers,
  isFetchingOrganizationPassengers: (state): boolean =>
    state.isFetchingPassengers,
  passengerActionLoading: (state): boolean => state.passengerActionLoading,
  isGenerateOTPLoading: (state): boolean => state.isGenerateOTPLoading,
  isOrganizationStatsLoading: (state): boolean =>
    state.isOrganizationStatsLoading,
  isOrganizationProductivityLoading: (state): boolean =>
    state.isOrganizationProductivityLoading,
  organizationStats: (state): IOrganizationStats | null =>
    state.organizationStats,
  organizationProductivity: (state): IOrganizationProductivity | null =>
    state.organizationProductivity,
  isCurrenciesLoading: (state): boolean => state.isCurrenciesLoading,
  currencies: (state): CurrencyConversion[] => state.currencies,
  curreny: (state): string[] | null => state.curreny,
  organizationSectors: (state): ISalesSector[] | null => state.sectors,
  piaProviders: (state): string[] | null => state.pia_provider,
  isDocumentUploadLoading: (state): boolean => state.isDocumentUploadLoading,
  saasEliteOrganization: (state): ISaasEliteOrganization[] =>
    state.saasEliteOrganization,
  isEliteOrganizationStatusUpdating: (state): boolean =>
    state.isEliteOrganizationStatusUpdating,
  errorOrgnatizationNotFound: (state): boolean =>
    state.errorOrganizationNotFound,
};

export default getters;
