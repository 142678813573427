<template>
  <ag-loader v-if="$store.getters.isSalesMyTeamLoading" />
  <template v-else>
    <MCard>
      <MTypography class="description" type="headline">My Team</MTypography>
    </MCard>
    <div v-if="myTeam.length > 0">
      <MCard v-for="(item, index) in myTeam" :key="index">
        <AgIconInfoBar :title="renderSectoName(item.sector_name)" />
        <ag-accordion
          class="flight_accordion_wrap margin_bottom_0"
          :panel-value="[0]"
        >
          <ag-accordion-panel>
            <template #tabSection>
              <MDataTable
                :headers="headers"
                :data="item.my_team_members"
                :item-per-page="30"
                :has-search="false"
                :simpleDataTable="true"
              >
              </MDataTable>
            </template>
          </ag-accordion-panel>
        </ag-accordion>
      </MCard>
    </div>
    <MCard v-else class="m-card">
      <ResultNotFound title="No Sales Team Found" />
    </MCard>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IMyTeam } from "@/ag-portal-common/interfaces/salesMyTeam.interface";
import { MCard, MDataTable, MTypography } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";

import ResultNotFound from "@/components/ResultNotFound.vue";

export default defineComponent({
  name: "SalesMyTeam",
  components: {
    ResultNotFound,
    MCard,
    MDataTable,
    MTypography,
  },
  data(): { headers: MDataTableHeader[] } {
    return {
      headers: [
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
          contentAlign: "left",
          align: "left",
        },
        {
          title: "Role",
          value: "role",
          key: "role",
          sortable: true,
          contentAlign: "left",
          align: "left",
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    renderSectoName(city: string): string {
      return `${city} Sector`;
    },
  },
  computed: {
    myTeam(): IMyTeam[] {
      return this.$store.getters.salesMyTeam;
    },
  },
  mounted() {
    this.$store.dispatch("getSalesMyTeam");
  },
});
</script>
<style lang="css" scoped>
.m-card {
  margin-top: 10px;
}
</style>
