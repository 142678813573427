<template>
  <ag-modal :is-open="isOpen" modal-width="40%" @close="$emit('handleClose')">
    <template #body>
      <AgHeading
        title="Reject Organization Documents"
        variant="h3"
        test-id=""
      />
      <AgDiv
        >Are you sure to reject
        <b>{{ organization_name }} documents?</b>?</AgDiv
      >
      <p>
        <b>Note:</b> This action will reject documents which were uploaded by
        owner and grant access them to upload new documents.
      </p>
      <AgDiv class="btn_container">
        <AGButton
          class="reject_btn"
          test-id=""
          @click="$emit('handleClose')"
          variant="danger"
          type="button"
          >Close
        </AGButton>
        <AGButton
          :disbaled="isLoading"
          :is-loading="isLoading"
          test-id=""
          @click="$emit('handleReject')"
          variant="primary"
          type="button"
          >Reject</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RejectOrganizationDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organization_name: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isEliteOrganizationStatusUpdating;
    },
  },
});
</script>

<style scoped>
.btn_container {
  display: flex;
  float: right;
}
.reject_btn {
  margin-right: 10px;
}
</style>
