export const FLIGHT_API_PATH = {
  POST: "v5/flights/search/",
};

export const FLIGHT_RULES_PATH = {
  POST: "v1/flights/fare_rules/",
};

export const AIRPORT_API_PATH = {
  GET: "/v1/flights/locations/",
};

export const FLIGHT_BOOKING_INITIATE_PATH = {
  POST: "v4/flights/bookings/initiate/",
};

export const FLIGHT_ACTIVE_PROVIDERS_AND_AIRLINES = {
  GET: "v1/flights/active-providers-and-airlines/",
};

export const BOOKING_API_PATH = {
  GET: "v4/bookings/:id/",
  POST: "v4/flights/bookings/confirm/",
};

export const CANCEL_BOOKINGS_API_PATH = {
  POST: "v2/flights/bookings/cancel/",
};

export const DOWNLOAD_FLIGHT_TICKET = {
  GET: "download-ticket/:id/?render_fare=:render_fare&is_edited=:is_edited&per_adult_edited_fare=:per_adult_edited_fare&per_child_edited_fare=:per_child_edited_fare&per_infant_edited_fare=:per_infant_edited_fare",
};

export const FLIGHT_ISSUANCE_SALES_USER_API_PATH = {
  GET_SALES_USER: "salesuser/sales-user",
};

export const FLIGHTS_PROMO_API_PATH = {
  APPLY_PROMO: "promo/apply_promo",
};

export const FLIGHT_ISSUANCE_API_PATH = {
  PNR_DETAILS: "v2/flights/pnr-details",
  ISSUE_TICKET: "v2/flights/issue-ticket",
};

export const FINANCIAL_PROFILE_API_PATH = {
  GET_ALL: "v2/financial-profile/",
};

export const VOID_PIA_TICKET = {
  POST: "v2/flights/bookings/void/",
};

export const GET_WEBSITE_CONFIG = {
  GET: "v2/organization/{0}/website/content/",
};

export const ORGANIZATION_PASSENGERSS = {
  GET: "v2/organization/{0}/passengers",
  POST: "v2/organization/{0}/passengers",
};

export const SEND_AIRLINE_TICKET = {
  POST: "/download-airline-ticket",
};

export const FLIGHT_REFUNDS_API_PATH = {
  FETCH_DETAILS: "flights/fetch-read-refund-details",
  CALCULATE_DETAILS: "flights/calculate-refund-details",
  COMMIT: "flights/commit-refund-details",
};
