<template>
  <ag-modal persistent :is-open="isOpen" modal-width="40%">
    <template #body>
      <AgHeading title="Flight Price Updated!" variant="h2" test-id="" />
      <AgDiv style="text-align: center; font-size: 21px"
        >Price for this booking has been
        <b style="color: red">Updated!</b></AgDiv
      >
      <p style="text-align: center; font-size: 21px; margin-top: 10px">
        New price for this booking is <b>{{ updatedFare }}</b>
      </p>
      <p
        style="
          color: red;
          font-size: 21px;
          font-weight: bold;
          margin-top: 15px;
          text-align: center;
        "
      >
        Please verify before issuance.
      </p>
      <AgDiv style="display: flex; float: right">
        <AGButton
          test-id=""
          @click="handleAccpet"
          variant="primary"
          type="button"
          >Continue</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { ExtendedPrice } from "@/ag-flight-components/types";

export default defineComponent({
  name: "PriceUpdatedDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    fare: {
      type: Object as PropType<ExtendedPrice>,
      required: true,
    },
    handleAccpet: {
      type: Function,
      required: true,
    },
  },
  computed: {
    updatedFare(): string {
      return getCurrencyFormatter(this.fare.currency).format(this.fare.value);
    },
  },
});
</script>
