<template>
  <ag-modal
    class="fplinking-modal-financial-profile-modal"
    :is-open="isOpen"
    @close="toggleModal"
    modal-width="40%"
  >
    <template #header>
      <v-card-title>Link Financial Profile</v-card-title>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        :testId="
          genTestId(ELEMENT_TYPES.BUTTON, 'link-financial-profile-modal-close')
        "
        @click="toggleModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <MFinancialProfileCombobox
        v-model:inputValue="selectedFinancialProfile"
        label="Financial Profiles"
        itemValue="value"
        itemLabel="label"
        :options="financialProfileOptions"
        :disabled="isFinancialProfileFetching"
      />

      <div class="fplinking-modal-spacer" v-if="!showConfirmCheck"></div>

      <template v-if="showConfirmCheck">
        <hr />
        <ag-checkbox
          :testId="
            genTestId(
              ELEMENT_TYPES.CHECK_BOX,
              'link-financial-profile-modal-isConfirmed'
            )
          "
          v-model="isConfirmed"
          :label="isConfirmedLabel"
        />
      </template>
      <hr />
      <a-g-button
        class="fplinking-modal-link-btn"
        :disabled="getLinkBtnDisabled"
        :testId="
          genTestId(ELEMENT_TYPES.BUTTON, 'link-financial-profile-modal-submit')
        "
        @click="onLink"
        :is-loading="$store.getters.isLinkingFinancialProfile"
        >Link
      </a-g-button>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LinkOrganizationDto } from "@/modules/FinancialProfile/dtos/linkOrganization.dto";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { MFinancialProfileCombobox } from "@aeroglobe/ag-core-ui";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "LinkFinancialProfileModal",
  components: { MFinancialProfileCombobox },
  beforeMount() {
    this.$store.dispatch("fetchFinancialProfiles");
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ELEMENT_TYPES,
      selectedFinancialProfile: "",
      isConfirmed: false,
      isConfirmedLabel:
        "This financial profile is already linked with another organization. Are you sure you want to overwrite?",
      showConfirmCheck: false,
    };
  },
  computed: {
    financialProfileOptions(): FPComboboxOptions[] {
      const financialProfiles = this.$store.getters
        .financialProfiles as IFinancialProfile[];

      if (!financialProfiles) {
        return [];
      }

      return financialProfiles.map((fp: IFinancialProfile) => {
        const planType = fp.plan_name
          ?.split(" ")[1]
          ?.toLowerCase() as FPComboboxOptions["type"];
        const sector = fp.sector?.replace(/^Aeroglobe\s*-\s*/, "");

        return {
          id: fp.platform_id,
          label: fp.financial_profile_name,
          value: fp.public_id,
          isActive: fp.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp.status,
          sector: sector ?? "",
          type: planType,
        };
      });
    },
    getLinkBtnDisabled(): boolean {
      return (
        !this.selectedFinancialProfile ||
        this.$store.getters.isLinkingFinancialProfile
      );
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
  },
  methods: {
    genTestId,
    toggleModal() {
      this.$emit("toggle");
    },
    enableConfirmationCheckBox() {
      this.showConfirmCheck = true;
    },
    onLink() {
      const body: LinkOrganizationDto = {
        organization: this.organizationId,
        confirmed: this.isConfirmed,
      };
      this.$store.dispatch("linkFinancialProfileWithOrganization", {
        body,
        params: {
          financial_data: "show",
        },
        failureCallback: this.enableConfirmationCheckBox,
        successCallback: this.toggleModal,
        financialProfileId: this.selectedFinancialProfile,
      });
    },
  },
});
</script>

<style lang="scss">
.fplinking-modal-link-btn {
  float: right;
}
.fplinking-modal-spacer {
  margin-bottom: 130px;
}
.fplinking-modal-financial-profile-modal .v-overlay__content > div {
  overflow: visible;
}
</style>
