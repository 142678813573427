import { ExtendedPrice } from "@/ag-flight-components/types";

export interface IFlightRefundState {
  isPNRFetching: boolean;
  refundPNRDetails: FetchFlightRefundPNRResponse | null;
  refundPNR: string | null;
  canCalculateRefund: boolean;
  calculateDetails: CalculateFlightRefundPNRResponse | null;
  isCommitingRefund: boolean;
}

export interface PenaltyDetail {
  penalty_period: string;
  currency_code: string;
  amount: string;
}

export interface Penalty {
  arrival_city_code: string;
  departure_city_code: string;
  penalty_details: PenaltyDetail[];
}

export interface Penalties {
  penalties: Penalty[];
}

export interface TaxDetail {
  tax_code: string;
  amount: Amount;
}

export interface FeeDetail {
  fee_code: string;
  amount: Amount;
}

export interface Amount {
  value: number;
  currency: string;
}
export interface FareInfo {
  currency_code: string;
  tax_details: TaxDetail[];
  fee_details: FeeDetail[];
  total_fare: Amount;
  base_fare: Amount;
  taxes: Amount;
  fees: Amount;
  others: Amount;
}

export interface RefundCharges {
  base_refund: Amount;
  other_charges: Amount;
}

export interface FareDetail {
  public_id: string;
  fare_info: FareInfo;
  refund_charges: RefundCharges;
  status: string;
  currency_code: string;
}

export interface SegmentDetail {
  segment_status: string;
  departure_city_code: string;
  arrival_city_code: string;
  departure_date_time: string;
  arrival_date_time: string;
  airline_code: string;
  flight_number: string;
  flight_segment_number: string;
  selected: false;
}

export interface LegDetail {
  leg_public_id: string;
  segment_details: SegmentDetail[];
  fare_detail: FareDetail;
  fare_basis: string | null;
  selected: false;
}

export interface PassengerDetail {
  title: string;
  first_name: string;
  last_name: string;
  passenger_type: string;
  passenger_public_id: string;
  ticket_number: string;
  leg_details: LegDetail[];
  selected: false;
}

export interface PassengerDetails {
  passenger_details: PassengerDetail[];
}

export type FetchFlightRefundPNRPayload = {
  provider: string;
  pnr: string;
  financial_profile_public_id: string;
};

export type CalculateFlightRefundPNRPayload = {
  refund_request_ids: [];
  financial_profile_public_id: string;
};
export type CommitFlightRefundPNRPayload = {
  refund_reference_id: string;
  financial_profile_public_id: string;
};

export interface FlightRefundResponse {
  passenger_details: PassengerDetail[];
  refund_status: string;
}

export interface FetchFlightRefundPNRResponse extends FlightRefundResponse {
  penalties: Penalty[];
  refund_reference_id: string;
  refund_type: REFUND_TYPES;
}
export interface CalculateFlightRefundPNRResponse extends FlightRefundResponse {
  total_refund_charges: ExtendedPrice;
  total_amount: ExtendedPrice;
  refundable_amount: ExtendedPrice;
  refund_reference_public_id: string;
}

export enum REFUND_TYPES {
  PASSENGER_WISE = "PASSENGER_WISE",
  LEG_WISE = "LEG_WISE",
  BOTH = "PASSENGER_AND_LEG_WISE",
  FULL_PNR = "FULL_PNR",
}
