import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MFabButton = _resolveComponent("MFabButton")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_DeletePassengerConfirmationModel = _resolveComponent("DeletePassengerConfirmationModel")!
  const _component_AddPassengerModal = _resolveComponent("AddPassengerModal")!
  const _component_UpdatePassengerModal = _resolveComponent("UpdatePassengerModal")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, { "initial-open": true }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        title: "Passenger Management",
        class: "section-title",
        mPrepandIcon: "m-passenger-management"
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_MButton, {
        class: "add-passenger-button",
        onClick: _ctx.handleNewPassenger
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add New Passenger ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_ctx.isFetchingOrganizationPassengers)
        ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.showNoResult())
              ? (_openBlock(), _createBlock(_component_AgNotFound, {
                  key: 0,
                  "test-id": "",
                  heading: "No Passenger Found"
                }))
              : (_openBlock(), _createBlock(_component_MDataTable, {
                  key: 1,
                  headers: _ctx.headers,
                  data: _ctx.passengersList,
                  "item-per-page": 10,
                  "has-search": false
                }, {
                  passport_expiry: _withCtx(({ item }) => [
                    _createVNode(_component_MTypography, {
                      class: "description td-full-width",
                      type: "label"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formattedDate(item.passport_expiry)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  action: _withCtx(({ item }) => [
                    _createVNode(_component_MButton, {
                      disabled: item.agent_type === _ctx.AGENT_SUB_ROLES.OWNER,
                      onClick: ($event: any) => (_ctx.handleUpdate($event, item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.action_update), 1)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"])
                  ]),
                  remove: _withCtx(({ item }) => [
                    _createVNode(_component_MFabButton, {
                      class: "delete-icon",
                      icon: "m-delete",
                      onClick: ($event: any) => (_ctx.handleDelete($event, item))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["headers", "data"]))
          ], 64)),
      _createVNode(_component_DeletePassengerConfirmationModel, {
        "is-loading": _ctx.isConfirmationDialogLoading,
        "is-open": _ctx.confirmationDialogOpen,
        onHandleAccept: _ctx.handleConfirmDeletePassenger,
        onHandleReject: _ctx.handleDeletePassengerDialogClose
      }, null, 8, ["is-loading", "is-open", "onHandleAccept", "onHandleReject"]),
      _createVNode(_component_AddPassengerModal, {
        "is-open": _ctx.updatePassengerDialogOpen,
        "is-edit": _ctx.isPassengerEdit,
        onCloseModal: _ctx.handleClosePassengerModal,
        onOnPassengerSubmit: _ctx.handlePassengerSubmit
      }, null, 8, ["is-open", "is-edit", "onCloseModal", "onOnPassengerSubmit"]),
      (_ctx.selectedPassenger)
        ? (_openBlock(), _createBlock(_component_UpdatePassengerModal, {
            key: 2,
            passenger: _ctx.selectedPassenger,
            "is-open": _ctx.updatePassengerDialogOpen,
            "is-edit": _ctx.isPassengerEdit,
            onCloseModal: _ctx.handleClosePassengerModal,
            onOnPassengerSubmit: _ctx.handlePassengerSubmit
          }, null, 8, ["passenger", "is-open", "is-edit", "onCloseModal", "onOnPassengerSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}