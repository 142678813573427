export const PATH = {
  ROOT: "/",
  FORGOT_PASSWORD: "/forgot-password",
  SIGNUP: "/signup",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/dashboard",

  //Agent Routes
  AGENT: "/agent",
  AGENT_MANAGEMENT: "/agent/management",
  AGENT_PERMISSIONS: "/agent/permissions",
  AGENT_PRICING: "/agent/management/pricing",

  //Organization Routes
  ORGANIZATION: "/organization",
  PREFERENCE: "/organization/preference",
  CREATE_ORGANIZATION: "/organization/create",
  UPDATE_ORGANIZATION: "/organization/:id/update",
  WEBSITE_MANAGEMENT: "/organization/website-management",
  WHATSAPP_MANAGEMENT: "/organization/whatsapp-management",
  PASSENGER_MANAGEMENT: "/organization/passengers",
  ORGANIZATION_SNAPSHOT: "/organization/snapshot",
  SECTOR_SNAPSHOT: "/sector/snapshot",
  ORGANIZATION_DOCUMENTS: "/organization/documents",
  SAAS_ELITE_ORGANIZATION: "/organization/saas-elite-organization",

  // Sales Routes
  SALES: "/sales",
  SALES_SNAPSHOT: "/sales/snapshot",
  DAILY_ISSUANCE: "/daily-issuance",
  MY_TEAM: "/my-team",

  //SOA Routes
  SOA: "/soa",

  //My Booking routes
  MY_BOOKINGS: "/my-bookings",

  //Flights Routes
  FLIGHTS: "/flights",
  FLIGHTS_SEARCH_RESULT: "/flights/search/result",
  FLIGHTS_BOOKING_FORM: "/flights/booking/:id",
  FLIGHTS_BOOKING_DETAILS: "/flights/booking-details/:id",
  FLIGHT_ISSUANCE: "/flights/issuance",
  FLIGHT_REFUNDS: "/flights/refunds",

  //Payment routes
  PAYMENTS: "/payments",
  CREATE_PAYMENT: "/payments/create",
  PAYMENT_DETAIL: "/payments/detail/:id",
  DIGITAL_PAYMENTS: "/digital/payments",

  // Aeroglobe_Tutorials
  AEROGLOBE_TUTORIALS: "https://aeroglobe.carrd.co/",

  // FDG - Group Flights
  FIX_DEPARTURE_GROUP: "/fdg",
  FDG_FLIGHTS: "/fdg/flights",
  FDG_FLIGHT_DETAIL: "/fdg/flights/:id",
  FDG_FLIGHT_BOOKING_CONFIRMATION: "/fdg/booking/:id",
  FDG_BOOKING_DETAILS: "/fdg/booking-details/:id",

  // FDG - Umrah
  FDG_UMRAH: "/fdg/umrah",
  FDG_UMRAH_DETAIL: "/fdg/umrah/:id",
  FDG_UMRAH_BOOKING_CONFIRMATION: "/fdg/booking/:id",

  // fit
  FIT: "/fit",
  FIT_HOTELS: "/fit/umrah-hotels",

  // Hotel Routes
  HOTELS: "/hotels",
  HOTELS_SEARCH_RESULT: "/hotels/search/result",
  HOTEL_PROPERTY_VIEW: "/hotels/property/:id",
  HOTEL_PROPERTY_BOOKING_FORM: "/hotels/booking/:id",
  HOTEL_BOOKING_DETAILS: "/hotels/booking-details/:id",

  // profile routes
  USER_PROFILE: "/profile",
  CHANGE_PASSWORD: "/profile/changePassword",

  //Payment routes
  CREDIT_LIMIT_MANAGEMENT: "/credit-limit-management",
  CREATE_CREDIT_LIMIT_REQUEST: "/credit-limit-management/create",
  CREDIT_LIMIT_REQUEST_DETAIL: "/credit-limit-management/:id",

  // issuance logs
  ISSUANCE_LOGS: "/issuance-logs/:pnr",
  ISSUANCE_LOGS_WITHOUT_PNR: "/issuance-logs",

  SABRE_SEGMENT: "/sabre-segment/",

  NEWS: "/news/",

  FARE_MANAGEMENT: "/fare-management",

  AG_PRICING: "/ag-pricing",

  Error_403: "/403",

  Error_404: "/:pathMatch(.*)*",

  MANAGE_QUOTATIONS: "/manage-quotations",

  CURRENCY_CONVERSION: "/currency-conversion",
};
