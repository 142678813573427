<template>
  <template v-if="agentPricingResponse">
    <div class="manage-agent-pricing-page">
      <section>
        <MTypography type="display">Agent Details</MTypography>
        <MCard>
          <div class="ap-inputs">
            <div class="row">
              <MTextfield
                :inputValue="agentName"
                label="Agent Name"
                placeholder="Agent Name"
                :disabled="true"
              />

              <MTextfield
                :inputValue="agentEmail"
                label="Agent Email"
                placeholder="Agent Email"
                :disabled="true"
              />
            </div>
          </div>
        </MCard>
      </section>

      <section>
        <MTypography type="display">Manage Pricing</MTypography>

        <div class="manage-pricing">
          <div>
            <MCard>
              <div class="ap-inputs">
                <div class="row">
                  <MFinancialProfileCombobox
                    v-model:inputValue="
                      pricingInformation.financialProfile.value
                    "
                    label="Select Financial Profile"
                    itemValue="value"
                    itemLabel="label"
                    :hasError="pricingInformation.financialProfile.hasError"
                    :errorMessage="
                      pricingInformation.financialProfile.errorMessage
                    "
                    :options="financialProfiles"
                    class="no-margin"
                  />

                  <MCombobox
                    v-model:inputValue="pricingInformation.pricingType.value"
                    label="Select Pricing Type"
                    itemValue="value"
                    itemLabel="label"
                    :hasError="pricingInformation.pricingType.hasError"
                    :errorMessage="pricingInformation.pricingType.errorMessage"
                    :options="pricingTypes"
                    class="no-margin"
                  />
                </div>
              </div>
            </MCard>

            <div class="margin-sep-top"></div>

            <MCard>
              <MTypography type="title" class="margin-sep-bottom">
                Enter {{ isGlobalPricing ? "Global" : "Default" }} Pricing
              </MTypography>

              <div class="ap-inputs">
                <div class="row">
                  <MCombobox
                    v-model:inputValue="pricingInformation.amountType.value"
                    label="Select Amount Type"
                    itemValue="value"
                    itemLabel="label"
                    :hasError="pricingInformation.amountType.hasError"
                    :errorMessage="pricingInformation.amountType.errorMessage"
                    :options="amountTypes"
                    class="no-margin"
                  />

                  <MTextfield
                    v-model:inputValue="pricingInformation.amountValue.value"
                    type="number"
                    label="Enter Amount Value"
                    placeholder="Enter Amount Value"
                    :hasError="pricingInformation.amountValue.hasError"
                    :errorMessage="pricingInformation.amountValue.errorMessage"
                    class="no-margin"
                  />
                </div>
              </div>

              <div class="ap-actions">
                <MButton
                  class="issuance-action"
                  type="filled"
                  :disabled="
                    infoHasErrors() || (!isDataUpdated && isGlobalPricing)
                  "
                  @click="onApplyPricingHandler"
                >
                  {{
                    isAgentPricingApplied ? "Update Pricing" : "Apply Pricing"
                  }}
                </MButton>
              </div>

              <MTypography
                class="ap-note"
                type="label"
                v-show="isDefaultPricing"
              >
                Note: For any new airline introduced by Aeroglobe with custom
                pricing, the following default pricing will be applied.
              </MTypography>
            </MCard>

            <MTypography type="display" class="margin-sep-top">
              Airlines List
            </MTypography>

            <MDataTable
              :headers="airlineTableHeaders"
              :data="pricingAirlines"
              :item-per-page="pricingAirlines.length"
              :total-item-count="pricingAirlines.length"
              :has-pagination="false"
              :has-search="true"
              :is-loading="false"
            >
              <template #pricing_amount_type="{ item }">
                <template v-if="isDefaultPricing">
                  <MCombobox
                    v-model:inputValue="item.pricing_amount_type"
                    placeholder="Select Amount Type"
                    itemValue="value"
                    itemLabel="label"
                    :options="amountTypes"
                    :hasError="
                      item.pricing_amount_type.length < 1 && item.hasError
                    "
                    errorMessage="Type Can't be null"
                    class="no-margin"
                  />
                </template>
                <template v-else>
                  {{ item.pricing_amount_type }}
                </template>
              </template>

              <template #pricing_value="{ item }">
                <template v-if="isDefaultPricing">
                  <MTextfield
                    v-model:inputValue="item.pricing_value"
                    type="number"
                    placeholder="Enter Amount Value"
                    :hasError="item.pricing_value.length < 1 && item.hasError"
                    errorMessage="Amount Can't be null"
                    class="no-margin"
                  />
                </template>
                <template v-else>
                  {{ item.pricing_value }}
                </template>
              </template>
            </MDataTable>
          </div>

          <aside class="ap-info m-index-level-one" ref="sticky-ap-info">
            <MCard>
              <div class="ap-info-details">
                <MTypography type="label">Status</MTypography>
                <MChip
                  :variant="`${isAgentPricingApplied ? 'success' : 'error'}`"
                  :border-less="true"
                >
                  {{ isAgentPricingApplied ? "APPLIED" : "NOT_APPLIED" }}
                </MChip>
              </div>

              <template v-if="pricingTypeTemp">
                <div class="ap-info-details">
                  <MTypography type="label">Pricing Type</MTypography>
                  <MChip variant="info" :border-less="true">
                    {{ pricingTypeTemp }}
                  </MChip>
                </div>
              </template>

              <template v-if="amountTypeTemp">
                <div class="ap-info-details">
                  <MTypography type="label">Amount Type</MTypography>
                  <MChip variant="info" :border-less="true">
                    {{ amountTypeTemp }}
                  </MChip>
                </div>
              </template>

              <template v-if="amountValueTemp">
                <div class="ap-info-details">
                  <MTypography type="label">Amount Value</MTypography>
                  <MChip variant="info" :border-less="true">
                    {{ amountValueTemp }}
                  </MChip>
                </div>
              </template>
            </MCard>
          </aside>
        </div>
      </section>
    </div>
  </template>
  <template v-else-if="!isLoading">
    <MTypography type="body" class="agent-not-found">
      Agent not found.&nbsp;
      <span>
        <a href="" @click.prevent="onRouteBackToAgentManagementHandler">
          Go Back
        </a>
      </span>
    </MTypography>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Components
import {
  MCard,
  MChip,
  MTextfield,
  MTypography,
  MDataTable,
  MCombobox,
  MFinancialProfileCombobox,
} from "@aeroglobe/ag-core-ui";

// Types
import {
  AgentPricingAirlineExtended,
  AgentPricingResponse,
  AgentPricingUpdatePayload,
  FinancialProfile,
  PricingData,
} from "@/interfaces/agentState.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { ComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "AgentPricing",
  components: {
    MCard,
    MChip,
    MTextfield,
    MTypography,
    MDataTable,
    MCombobox,
    MFinancialProfileCombobox,
  },
  computed: {
    agentPricingResponse(): AgentPricingResponse | null {
      return this.$store.getters.agentPricingResponse;
    },

    agentName(): string {
      const data = this.agentPricingResponse;

      return data ? data.agent_name : "";
    },
    agentEmail(): string {
      const data = this.agentPricingResponse;

      return data ? data.agent_email : "";
    },
    globalPricingData(): PricingData | null {
      const data = this.agentPricingResponse;

      if (data) {
        return data.global_pricing_data;
      } else {
        return null;
      }
    },
    defaultPricingData(): PricingData | null {
      const data = this.agentPricingResponse;

      if (data) {
        return data.default_pricing_data;
      } else {
        return null;
      }
    },

    financialProfiles(): FPComboboxOptions[] {
      const data = this.agentPricingResponse;
      const financialProfiles = data ? data.financial_profile_list : [];
      const transformedFinancialProfiles = financialProfiles.map(
        (fp: FinancialProfile) => {
          const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase() as
            | "pro"
            | "elite"
            | "smart";
          const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
          const fpComboboxOptions: FPComboboxOptions = {
            id: fp?.platform_id,
            label: fp?.financial_profile_name,
            value: fp?.public_id,
            isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
            status: fp?.status,
            sector: sector,
            type: planType,
          };

          return fpComboboxOptions;
        }
      );

      return transformedFinancialProfiles;
    },
    pricingTypes(): ComboboxOptions[] {
      const data = this.agentPricingResponse;
      const pricingTypes = data ? data.agent_pricing_type_list : [];
      const transformedPricingTypes = pricingTypes.map((type: string) => {
        const ptComboboxOptions: ComboboxOptions = {
          label: type,
          value: type,
        };

        return ptComboboxOptions;
      });

      return transformedPricingTypes;
    },
    amountTypes(): ComboboxOptions[] {
      const data = this.agentPricingResponse;
      const amountTypes = data ? data.pricing_type_list : [];
      const transformedAmountTypes = amountTypes.map((type: string) => {
        const atComboboxOptions: ComboboxOptions = {
          label: type,
          value: type,
        };

        return atComboboxOptions;
      });

      return transformedAmountTypes;
    },

    isAgentPricingApplied(): boolean {
      const data = this.agentPricingResponse;

      if (data) {
        const publicId = data.agent_pricing_public_id;

        return publicId !== null;
      } else {
        return false;
      }
    },
    isDefaultPricing(): boolean {
      return this.pricingInformation.pricingType.value === "AIRLINE_WISE";
    },
    isGlobalPricing(): boolean {
      return this.pricingInformation.pricingType.value === "GLOBAL_PRICING";
    },
    isPricingTypeUpdated(): boolean {
      const { pricingType } = this.pricingInformation;

      const isPricingTypeUpdated =
        pricingType.value.length > 0 &&
        pricingType.value !== this.pricingTypeTemp;

      return isPricingTypeUpdated;
    },
    isAmountTypeUpdated(): boolean {
      const { amountType } = this.pricingInformation;

      const isAmountTypeUpdated =
        amountType.value.length > 0 && amountType.value !== this.amountTypeTemp;

      return isAmountTypeUpdated;
    },
    isAmountValueUpdated(): boolean {
      const { amountValue } = this.pricingInformation;

      const isAmountValueUpdated =
        amountValue.value.length > 0 &&
        amountValue.value !== this.amountValueTemp;

      return isAmountValueUpdated;
    },
    isDataUpdated(): boolean {
      return (
        this.isPricingTypeUpdated ||
        this.isAmountTypeUpdated ||
        this.isAmountValueUpdated
      );
    },
  },
  data() {
    return {
      isUpdating: false,

      pricingTypeTemp: "",
      amountTypeTemp: "",
      amountValueTemp: "",

      pricingInformation: {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pricingType: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        amountType: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        amountValue: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },

      airlineTableHeaders: [
        {
          title: "Airline Code",
          value: "airline_code",
          key: "airline_code",
          sortable: true,
        },
        {
          title: "Airline Name",
          value: "airline_name",
          key: "airline_name",
          sortable: true,
        },
        {
          title: "Pricing Amount Type",
          value: "pricing_amount_type",
          key: "pricing_amount_type",
          sortable: false,
        },
        {
          title: "Pricing Value",
          value: "pricing_value",
          key: "pricing_value",
          sortable: false,
        },
      ] as MDataTableHeader[],

      pricingAirlines: [] as AgentPricingAirlineExtended[],

      isLoading: true,
      validatePricingInfoErrors: false,
    };
  },
  async created(): Promise<void> {
    const agentId = this.$route.params.agentId;

    if (agentId) {
      await this.$store.dispatch("getAgentPricing", {
        agentId,
      });

      this.onAgentPricingDataSyncHandler();
    }

    this.isLoading = false;
  },
  beforeMount(): void {
    this.onDataResetHandler();
  },
  mounted() {
    const bodyElement = document.querySelector(".m-body");

    if (bodyElement) {
      bodyElement.addEventListener("scroll", this.onScrollStickyElementHandler);
      this.onScrollStickyElementHandler();
    }
  },
  unmounted(): void {
    const bodyElement = document.querySelector(".m-body");

    if (bodyElement) {
      bodyElement.removeEventListener(
        "scroll",
        this.onScrollStickyElementHandler
      );
    }

    this.onDataResetHandler();
  },
  methods: {
    onAgentPricingDataSyncHandler(): void {
      if (this.agentPricingResponse) {
        const defaultFinancialProfile =
          this.agentPricingResponse.agent_pricing_financial_profile;
        const defaultPricingType = this.agentPricingResponse.agent_pricing_type;

        const { financialProfile, pricingType, amountType, amountValue } =
          this.pricingInformation;

        if (defaultFinancialProfile) {
          financialProfile.value = defaultFinancialProfile.public_id;
        }

        if (defaultPricingType) {
          pricingType.value = defaultPricingType;
        }

        if (this.globalPricingData) {
          amountType.value = this.globalPricingData.pricing_type;
          amountValue.value = this.globalPricingData.pricing_value.toString();
        } else if (this.defaultPricingData) {
          amountType.value = this.defaultPricingData.pricing_type;
          amountValue.value = this.defaultPricingData.pricing_value.toString();
        }

        const pricingAirlines =
          this.agentPricingResponse.agent_pricing_line_data;

        this.pricingAirlines = pricingAirlines.map((airline) => {
          return {
            ...airline,
            pricing_value: airline.pricing_value.toString(),
            hasError: false,
          };
        });

        this.pricingTypeTemp = pricingType.value;
        this.amountTypeTemp = amountType.value;
        this.amountValueTemp = amountValue.value;
      }
    },

    async onApplyPricingHandler(): Promise<void> {
      this.validatePricingInfoErrors = true;

      this.handlePricingInfoErrors();

      if (this.infoHasErrors()) {
        return;
      }

      if (this.isGlobalPricing) {
        await this.onApplyGlobalPricingHandler();
      } else if (this.isDefaultPricing) {
        await this.onApplyDefaultPricingHandler();
      }
    },
    async onApplyDefaultPricingHandler(): Promise<void> {
      const agentId = this.$route.params.agentId;

      if (agentId) {
        this.isUpdating = true;

        const { financialProfile, pricingType, amountType, amountValue } =
          this.pricingInformation;
        const selectedAirlines = this.pricingAirlines;

        const pricingUpdatePayload: AgentPricingUpdatePayload = {
          financial_profile_id: financialProfile.value,
          pricing_type: pricingType.value,
          default_pricing_amount_type: amountType.value,
          default_pricing_value: Number(amountValue.value),
          airline_pricing_data: selectedAirlines,
        };

        await this.$store.dispatch("updateAgentPricing", {
          agentId,
          data: pricingUpdatePayload,
        });

        this.isUpdating = false;

        this.onAgentPricingDataSyncHandler();
      }
    },
    async onApplyGlobalPricingHandler(): Promise<void> {
      const agentId = this.$route.params.agentId;

      if (agentId) {
        this.isUpdating = true;

        const { financialProfile, pricingType, amountType, amountValue } =
          this.pricingInformation;

        const pricingUpdatePayload: AgentPricingUpdatePayload = {
          financial_profile_id: financialProfile.value,
          pricing_type: pricingType.value,
          global_pricing_amount_type: amountType.value,
          global_pricing_value: Number(amountValue.value),
        };

        await this.$store.dispatch("updateAgentPricing", {
          agentId,
          data: pricingUpdatePayload,
        });

        this.isUpdating = false;

        this.onAgentPricingDataSyncHandler();
      }
    },

    infoHasErrors(): boolean {
      let airlineErrors = false;
      const transformedArray = Object.keys(this.pricingInformation).map(
        (key) => (this.pricingInformation as any)[key]
      );

      this.handlePricingInfoErrors();

      if (this.isDefaultPricing) {
        airlineErrors = this.pricingAirlines.some((item) => item.hasError);
      }

      return transformedArray.some((item) => item.hasError) || airlineErrors;
    },
    handlePricingInfoErrors(): void {
      if (this.validatePricingInfoErrors) {
        const { financialProfile, pricingType, amountType, amountValue } =
          this.pricingInformation;

        if (financialProfile.value === "") {
          financialProfile.hasError = true;
          financialProfile.errorMessage = "Financial Profile is required";
        } else {
          financialProfile.hasError = false;
          financialProfile.errorMessage = "";
        }

        if (pricingType.value === "") {
          pricingType.hasError = true;
          pricingType.errorMessage = "Pricing Type is required";
        } else {
          pricingType.hasError = false;
          pricingType.errorMessage = "";
        }

        if (amountType.value === "") {
          amountType.hasError = true;
          amountType.errorMessage = "Amount Type is required";
        } else {
          amountType.hasError = false;
          amountType.errorMessage = "";
        }

        if (amountValue.value === "") {
          amountValue.hasError = true;
          amountValue.errorMessage = "Amount Value is required";
        } else {
          amountValue.hasError = false;
          amountValue.errorMessage = "";
        }

        this.pricingAirlines.forEach((airline) => {
          const isAmountTypeEmpty = airline.pricing_amount_type.length < 1;
          const isAmountValueEmpty =
            airline.pricing_value.toString().length < 1;

          airline.hasError = isAmountTypeEmpty || isAmountValueEmpty;
        });
      }
    },
    onDataResetHandler(): void {
      this.isUpdating = false;

      this.pricingTypeTemp = "";
      this.amountTypeTemp = "";
      this.amountValueTemp = "";

      this.pricingInformation = {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pricingType: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        amountType: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        amountValue: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.pricingAirlines = [];

      this.isLoading = true;
      this.validatePricingInfoErrors = false;

      this.$store.commit("saveAgentPricingResponse", null);
    },
    onRouteBackToAgentManagementHandler(): void {
      this.$router.push("/agent/management");
    },
    onScrollStickyElementHandler() {
      const targetElement = this.$refs["sticky-ap-info"] as HTMLElement | null;

      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        const isMobile = window.innerWidth < 1024;
        const classname = isMobile ? "activate-mob" : "activate-desk";

        if (rect.top <= 84) {
          targetElement.classList.add(classname);
        } else {
          targetElement.classList.remove("activate-mob", "activate-desk");
        }
      }
    },
  },
});
</script>

<style lang="css">
.manage-agent-pricing-page .m-table {
  min-width: 0;
}
</style>

<style lang="css" scoped>
.manage-agent-pricing-page .no-margin {
  margin: 0;
}
.manage-agent-pricing-page .margin-sep-top {
  margin-top: 24px;
}
.manage-agent-pricing-page .margin-sep-bottom {
  margin-bottom: 24px;
}

.agent-not-found {
  width: 100%;
  height: calc(100vh - (40px + 64px));

  display: flex;
  align-items: center;
  justify-content: center;
}
.manage-agent-pricing-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 50px;
}

.manage-agent-pricing-page .ap-actions {
  margin: 20px 0;
}

.manage-agent-pricing-page .ap-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.manage-agent-pricing-page .ap-inputs .row {
  display: flex;
  gap: 24px;
  width: 100%;
}

.manage-agent-pricing-page .manage-pricing {
  display: flex;
  gap: 24px;
}

.manage-agent-pricing-page .manage-pricing > :is(div, aside) {
  width: 100%;
}

.manage-agent-pricing-page .manage-pricing .ap-info {
  max-width: 280px;
  height: min-content;

  position: sticky;
  top: 0;
}
.manage-agent-pricing-page .manage-pricing .ap-info.activate-mob {
  top: -20px;
}
.manage-agent-pricing-page .manage-pricing .ap-info.activate-mob .m-card {
  padding-left: 0;
  padding-right: 0;
}

.manage-agent-pricing-page .manage-pricing .ap-info::after {
  content: "";

  position: absolute;
  top: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  transform: translateX(0);
  border-radius: 8px;

  background-color: var(--m-light-color);
  transition: all 0.5s ease-in-out;
}
.manage-agent-pricing-page .manage-pricing .ap-info.activate-desk::after {
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
}
.manage-agent-pricing-page .manage-pricing .ap-info.activate-mob::after {
  width: calc(100% + 40px);
  transform: translateX(-20px);
  border-radius: 0px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
}

.manage-agent-pricing-page .manage-pricing .ap-info .ap-info-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-agent-pricing-page
  .manage-pricing
  .ap-info
  .ap-info-details:not(:last-child) {
  margin-bottom: 20px;
}

.manage-agent-pricing-page .m-chip {
  width: min-content;
}

@media screen and (max-width: 1024px) {
  .manage-agent-pricing-page .manage-pricing {
    flex-direction: column-reverse;
  }

  .manage-agent-pricing-page .manage-pricing .ap-info {
    max-width: 100%;
  }

  .manage-agent-pricing-page .manage-pricing .ap-info > div {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  .manage-agent-pricing-page .manage-pricing .ap-info .ap-info-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .manage-agent-pricing-page
    .manage-pricing
    .ap-info
    .ap-info-details:not(:last-child) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .ap-inputs .row {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 400px) {
  .manage-agent-pricing-page .manage-pricing .ap-info .m-card {
    padding: 10px;
  }
}
</style>
