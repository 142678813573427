import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d0d9d26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "btn-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_AddAgentModal = _resolveComponent("AddAgentModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAddNewAgentBtn())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MButton, {
            variant: "filled",
            onClick: _ctx.onAddNewAgentBtnClick
          }, {
            default: _withCtx(() => [
              _createTextVNode("Add New Agent")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_MDataTable, {
      headers: _ctx.headers,
      data: _ctx.agentsList,
      "item-per-page": 30,
      "is-api-paginated": false,
      "has-search": true,
      "is-loading": _ctx.isFetchingOrganizationAgents
    }, {
      pricing: _withCtx(({ item }) => [
        _createVNode(_component_MButton, {
          disabled: _ctx.isOwnerUser(item.agent_type),
          onClick: ($event: any) => (_ctx.onClickManagePricing(item.id))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.pricing), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      action: _withCtx(({ item }) => [
        _createVNode(_component_MButton, {
          disabled: _ctx.isOwnerUser(item.agent_type),
          onClick: ($event: any) => (_ctx.onClickUpdatePermissions(item.id))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.action), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["headers", "data", "is-loading"]),
    _createVNode(_component_AddAgentModal, {
      "is-open": _ctx.isAddAgentDialogOpen,
      onHandleClose: _ctx.onCloseAddAgentDialog,
      onOnSubmit: _ctx.onAddEmail
    }, null, 8, ["is-open", "onHandleClose", "onOnSubmit"])
  ], 64))
}