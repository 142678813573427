<template>
  <MAccordion :initial-open="true">
    <template v-slot:title>
      <div class="accordion-title-container">
        <span class="accordion-title"
          >Organization Details - {{ organization?.name }}</span
        >
        <div class="accordion-chip-container">
          <MChip class="status-chip" :variant="isActive ? 'success' : 'error'">
            {{ organization?.status }}
          </MChip>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="content-container">
        <!-- Tiles container -->
        <div class="contact-details-container">
          <div class="container">
            <div class="contact-details">
              <div class="sub-container">
                <MIcon
                  name="m-phone"
                  class="details-type-icon icon"
                  width="20"
                  height="20"
                />
                <span class="value">{{
                  organization?.contact_person_phone
                    ? organization?.contact_person_phone
                    : "-"
                }}</span>
              </div>
              <span class="seperator">|</span>
              <div class="sub-container">
                <MIcon
                  name="m-email"
                  class="details-type-icon icon"
                  width="20"
                  height="20"
                />
                <span class="value">{{
                  organization?.contact_person_email
                }}</span>
              </div>
              <span class="seperator">|</span>
              <div class="currency-details">
                <div
                  class="flag"
                  :style="{
                    '--flag-url': `url(${countryFlag})`,
                  }"
                ></div>
                <span>{{ organization?.currency }}</span>
              </div>
            </div>
          </div>

          <div class="organization-action-btn-container">
            <MButton v-if="isSuperUser" @click="onActivateOrganizationBtnClick">
              {{ organizationStatus }} Account
            </MButton>
            <MButton
              class="delete-organization-btn"
              v-if="showDeleteOrganizationBtn"
              @click="onDeleteOrganizationBtnClick"
            >
              Delete Organization
            </MButton>
          </div>
        </div>

        <div class="tile-container">
          <MAccordionTile title="Sector Name" :value="organizationSector">
            <template #icon>
              <MIcon
                class="details-type-icon icon white-icon"
                name="m-location"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>

          <MAccordionTile title="Sales POC" :value="organizationSalesPOC">
            <template #icon>
              <MIcon
                class="details-type-icon icon white-icon"
                name="m-email"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>

          <MAccordionTile title="PIA Provider" :value="organizationPIAProvider">
            <template #icon>
              <MIcon
                class="details-type-icon icon white-icon"
                name="m-flight-takeoff"
                width="24"
                height="24"
              />
            </template>
          </MAccordionTile>
        </div>
      </div>
    </template>
  </MAccordion>
  <UpdateOrganizationStatusDialog
    @handleClose="handleReject"
    @handleAccept="handleApprove"
    :organization_name="organization?.name"
    :is-open="isUpdateOrganizationStatusDialogOpen"
  />

  <DeleteOrganizationConfirmationDialog
    @handleClose="handleDeleteOrganizationReject"
    @handleAccept="handleDeleteOrganizationApprove"
    :organization_name="organization?.name"
    :is-open="isDeleteOrganizationStatusDialogOpen"
  />
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { MIcon, MAccordion, MChip, MButton } from "@aeroglobe/ag-core-ui";
import MAccordionTile from "../MAccordionTile.vue";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";
import { flags, getFlagByCurrency } from "../../constants/flags";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import UpdateOrganizationStatusDialog from "./UpdateOrganizationStatusDialog.vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import UTILS from "@/ag-portal-common/utils";
import { PATH } from "@/ag-portal-common/constants/path";
import DeleteOrganizationConfirmationDialog from "./DeleteOrganizationConfirmationDialog.vue";

export default defineComponent({
  name: "OrganizationDetailsComponent",
  components: {
    MButton,
    MAccordionTile,
    MIcon,
    MAccordion,
    MChip,
    UpdateOrganizationStatusDialog,
    DeleteOrganizationConfirmationDialog,
  },
  data() {
    return {
      isUpdateOrganizationStatusDialogOpen: false,
      isDeleteOrganizationStatusDialogOpen: false,
      selectedOrganizationName: "",
    };
  },
  props: {
    organization: {
      type: Object as PropType<IOrganizationDetail>,
    },
  },
  computed: {
    organizationSector(): string {
      return this.organization?.sector as string;
    },
    organizationSalesPOC(): string {
      return this.organization?.sales_poc as string;
    },
    organizationPIAProvider(): string {
      return this.organization?.pia_provider as string;
    },
    countryFlag(): string {
      const currency = this.organization?.currency as keyof typeof flags;
      return this.getCurrencyFlag(currency);
    },
    isActive(): boolean {
      return this.organization?.status === ORGANIZATION_STATUSES.ACTIVE;
    },
    organizationStatus(): string {
      return this.organization?.status === ORGANIZATION_STATUSES.ACTIVE
        ? "Deactivate"
        : "Activate";
    },
    isSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    showDeleteOrganizationBtn(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS);
    },
  },

  methods: {
    getCurrencyFlag(currency: keyof typeof flags) {
      return getFlagByCurrency(currency);
    },
    onActivateOrganizationBtnClick() {
      this.isUpdateOrganizationStatusDialogOpen = true;
    },
    onDeleteOrganizationBtnClick() {
      this.isDeleteOrganizationStatusDialogOpen = true;
    },
    onSuccessHandler() {
      this.$emit("refetchOrganizationDetails");
      this.isUpdateOrganizationStatusDialogOpen = false;
    },
    handleApprove() {
      const payload = {
        status:
          this.organization?.status === ORGANIZATION_STATUSES.ACTIVE
            ? ORGANIZATION_STATUSES.INACTIVE
            : ORGANIZATION_STATUSES.ACTIVE,
        public_id: this.organization?.public_id,
      };
      this.$store.dispatch("updateStatus", {
        body: payload,
        callback: this.onSuccessHandler,
      });
    },
    handleReject() {
      this.isUpdateOrganizationStatusDialogOpen = false;
    },
    handleDeleteOrganizationReject() {
      this.isDeleteOrganizationStatusDialogOpen = false;
    },
    onDeleteOrganizationSuccessHandler() {
      this.isDeleteOrganizationStatusDialogOpen = false;
      if (this.isSuperUser) {
        this.$router.push(PATH.ORGANIZATION);
      } else {
        this.$router.push(PATH.SAAS_ELITE_ORGANIZATION);
      }
    },
    handleDeleteOrganizationApprove() {
      this.$store.dispatch("deleteOrganization", {
        organizationId: this.organization?.public_id,
        callback: this.onDeleteOrganizationSuccessHandler,
      });
    },
  },
});
</script>

<style scoped lang="scss">
.accordion-title-container {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
}
.accordion-chip-container {
  display: flex;
  gap: 10px;
}
.accordion-title {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 600;
  text-align: start;
  @media (max-width: 767px) {
    text-align: center;
  }
}
.content-container {
  padding: 26px 0 0 0;
}

.plan_name {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  margin: 10px;
}
.plan_name > .icon {
  margin-top: 1px;
  margin-right: 5px;
}
.tile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
  width: 100%;
}
.white-icon {
  color: #fff;
}
.contact-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  .container {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin-right: 10px;
    @media (max-width: 767px) {
      lex-direction: column-reverse;
      gap: 10px;
    }
    button {
      margin-left: 10px;
    }
    .contact-details {
      display: flex;
      @media (max-width: 767px) {
        button {
          margin-bottom: 10px;
        }
        flex-direction: column;
        align-items: center;
      }
      .seperator {
        margin: 0 10px;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .sub-container {
        display: flex;
        .icon {
          margin-top: 2px;
        }
        .value {
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }
  }
  .currency-details {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 1px;
    .flag {
      width: 22px;
      height: 16px;
      background-size: contain;
      background-image: var(--flag-url);
    }
  }
}
.status-chip {
  font-weight: 600;
}

.organization-action-btn-container {
  display: flex;
  gap: 12px;
}

.delete-organization-btn {
  background-color: var(--m-error-color);
}

@media (max-width: 767px) {
  .organization-action-btn-container {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }
}
</style>
