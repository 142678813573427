import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import FlightIssuanceService from "@/ag-flight-components/services/flightIssuance.service";
import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import {
  FETCH_FLIGHT_PNR_PAYLOAD,
  ISSUE_TICKET_PAYLOAD,
} from "@/ag-flight-components/types";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";
import { CACHE_NAMES } from "@/ag-portal-common/constants";
import { GetFinancialProfileDto } from "../dtos/financialProfile.dto";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { ApplyFlightPromoCodeDTO } from "@/modules/FlightIssuance/dtos/flightIssuance.dto";
import { StoreState } from "@/store/type";

const actions: ActionTree<IFlightIssuanceState, StoreState> = {
  // ****** Fetch PNR ******
  async fetchPNR(
    context: ActionContext<IFlightIssuanceState, StoreState>,
    params: FETCH_FLIGHT_PNR_PAYLOAD
  ) {
    const methodName = "actions.fetchPNR";
    context.commit("resetPNRDetails");
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);
      context.commit("fetchingPNR", true);
      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse = await flightIssuanceService.fetchPNR(
        params
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("fetchedPNRDetail", response.data.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("fetchingPNR", false);
    }
  },

  // ****** Issue Ticket ******
  async issueTicket(
    context: ActionContext<IFlightIssuanceState, StoreState>,
    params: ISSUE_TICKET_PAYLOAD
  ) {
    const methodName = "actions.issueTicket";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);
      context.commit("isIssueTicketLoading", true);
      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse = await flightIssuanceService.issueTicket(
        params
      );
      if (response.success && response.status === StatusCodes.OK) {
        await caches.delete(CACHE_NAMES.flightsBookings);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response.data?.message || "Ticket is successfully issued";
        notificationService.triggerNotification();
        context.commit("isTicketIssued", true);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("isIssueTicketLoading", false);
    }
  },

  // ****** Fetch Financial Profiles *****
  async fetchFinancialProfiles(
    context: ActionContext<IFlightIssuanceState, StoreState>,
    params: GetFinancialProfileDto
  ) {
    const methodName = "actions.fetchFinancialProfiles";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setFinancialProfileLoading", true);
      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse =
        await flightIssuanceService.getAllFinancialProfiles(params);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveIssuanceFinancialProfiles", response.data);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("setFinancialProfileLoading", false);
    }
  },

  // ****** Fetch Sales Users List For Approval *****
  async fetchSalesUsersListForApproval(
    context: ActionContext<IFlightIssuanceState, StoreState>
  ) {
    const methodName = "actions.fetchSalesUsersListForApproval";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse =
        await flightIssuanceService.getSalesUsersListForIssanceApproval();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveSalesUsersListForApproval", response.data);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    }
  },

  // ****** Apply Flight Promo Code *****
  async applyFlightPromoCode(
    context: ActionContext<IFlightIssuanceState, StoreState>,
    params: ApplyFlightPromoCodeDTO
  ) {
    const methodName = "actions.applyFlightPromoCode";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setApplyFlightPromoLoading", true);
      context.commit("setApplyFlightPromoResponse", null);

      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse =
        await flightIssuanceService.applyFlightsPromoCode(params);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("setApplyFlightPromoResponse", response.data?.data);
        context.commit("setApplyFlightPromoLoading", false);
      } else {
        context.commit("setApplyFlightPromoLoading", false);
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    }
  },
};

export default actions;
