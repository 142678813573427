import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlightSearchOptions = _resolveComponent("FlightSearchOptions")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_MulticityFlightSearch = _resolveComponent("MulticityFlightSearch")!
  const _component_NormalFlightSearch = _resolveComponent("NormalFlightSearch")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, {
    id: "m-flight-search-card",
    class: "m-flight-search-card"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ag_column, null, {
            default: _withCtx(() => [
              _createVNode(_component_FlightSearchOptions, { "route-type": _ctx.routeType }, null, 8, ["route-type"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.isMulticitySearch)
        ? (_openBlock(), _createBlock(_component_MulticityFlightSearch, {
            key: 0,
            errors: _ctx.multicityError,
            isSuperUserOrOperationUser: _ctx.isSuperUserOrOperationUser,
            onFlightSearch: _ctx.onFlightSearch,
            onValidateSearchFeilds: _ctx.validateSearchFlight
          }, {
            "financial-profile-combobox": _withCtx(() => [
              _withDirectives(_createVNode(_component_MFinancialProfileCombobox, {
                inputValue: _ctx.selectedFinancialProfile,
                "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFinancialProfile) = $event)),
                label: "Financial Profiles",
                placeholder: "Select Financial Profile",
                itemValue: "value",
                itemLabel: "label",
                hasError: !!_ctx.financialProfileErrorMessage,
                errorMessage: _ctx.financialProfileErrorMessage,
                disabled: _ctx.isFinancialProfilesLoading,
                options: _ctx.financialProfiles,
                isLargerDropdown: "",
                focusedBorderEnabled: ""
              }, null, 8, ["inputValue", "hasError", "errorMessage", "disabled", "options"]), [
                [_vShow, _ctx.isSuperUserOrOperationUser]
              ])
            ]),
            _: 1
          }, 8, ["errors", "isSuperUserOrOperationUser", "onFlightSearch", "onValidateSearchFeilds"]))
        : (_openBlock(), _createBlock(_component_NormalFlightSearch, {
            key: 1,
            errors: _ctx.normalError,
            isSuperUserOrOperationUser: _ctx.isSuperUserOrOperationUser,
            onFlightSearch: _ctx.onFlightSearch,
            onValidateSearchFeilds: _ctx.validateSearchFlight
          }, {
            "financial-profile-combobox": _withCtx(() => [
              _withDirectives(_createVNode(_component_MFinancialProfileCombobox, {
                inputValue: _ctx.selectedFinancialProfile,
                "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFinancialProfile) = $event)),
                label: "Financial Profiles",
                placeholder: "Select Financial Profile",
                itemValue: "value",
                itemLabel: "label",
                hasError: !!_ctx.financialProfileErrorMessage,
                errorMessage: _ctx.financialProfileErrorMessage,
                disabled: _ctx.isFinancialProfilesLoading,
                options: _ctx.financialProfiles,
                isLargerDropdown: "",
                focusedBorderEnabled: ""
              }, null, 8, ["inputValue", "hasError", "errorMessage", "disabled", "options"]), [
                [_vShow, _ctx.isSuperUserOrOperationUser]
              ])
            ]),
            _: 1
          }, 8, ["errors", "isSuperUserOrOperationUser", "onFlightSearch", "onValidateSearchFeilds"]))
    ]),
    _: 1
  }))
}