<template>
  <MCard class="m-card">
    <div>
      <template v-if="isAllowedUser()">
        <MFinancialProfileCombobox
          v-model:inputValue="organizationId"
          label="Organization"
          placeholder="Select Organization"
          itemValue="value"
          itemLabel="label"
          :hasError="null"
          :errorMessage="null"
          :disabled="isFetchingOrganizations"
          :options="organizations"
        />
      </template>
      <div class="sabre-segment-data-wrapper">
        <MDatePicker
          label="Start Date"
          :min-date="minStartDate"
          :maxDate="maxDate"
          @onRangeDateChange="changeStartDate"
          @rangeStartDate="minStartDate"
          :placeholder="startDate"
          dateType="startDate"
          :multiCalendar="false"
          :hasRange="false"
          :disabled="isFetchingOrganizations"
          class="sabre-segment-date-picker"
        />
        <MDatePicker
          label="End Date"
          :min-date="minStartDate"
          :maxDate="maxDate"
          @onRangeDateChange="changeEndDate"
          @rangeStartDate="minStartDate"
          :placeholder="endDate"
          dateType="startDate"
          :multiCalendar="false"
          :hasRange="false"
          :disabled="isFetchingOrganizations"
          class="sabre-segment-date-picker"
        />

        <MButton
          @click="fetchBookings"
          :disabled="isFetchingOrganizations || !canFetch() || isLoading"
        >
          {{
            isFetchingOrganizations && isAllowedUser() ? "Loading" : "Search"
          }}
        </MButton>
      </div>
    </div>
  </MCard>

  <MAccordion :initial-open="true">
    <template v-slot:title>
      <AgIconInfoBar
        test-id=""
        mPrepandIcon="m-sabre-segment-stats"
        title="Sabre Segment Stats"
        class="accordion-title"
      />
    </template>
    <template #content>
      <MDataTable
        :headers="statsTableHeader"
        :data="[statsDetails]"
        :is-api-paginated="false"
        :item-per-page="1"
        :has-search="false"
        class="item"
        v-if="statsDetails"
      >
        <template #start_date="{ item }">
          <MTypography class="description td-full-width" type="label">
            {{ formattedDate(item.start_date) }}
          </MTypography>
        </template>
        <template #end_date="{ item }">
          <MTypography class="description td-full-width" type="label">
            {{ formattedDate(item.end_date) }}
          </MTypography>
        </template>
      </MDataTable>

      <AgNotFound
        v-else
        test-id=""
        heading="No Sabre Segment Stats found in the following date range"
        description=""
      />
    </template>
  </MAccordion>
  <MAccordion :initial-open="true">
    <template v-slot:title>
      <AgIconInfoBar
        test-id=""
        mPrepandIcon="m-sabre-segment-list"
        title="Sabre Segment List"
        class="accordion-title"
      />
    </template>
    <template #content>
      <MDataTable
        :headers="listTableHeader"
        :data="listDetails"
        :is-api-paginated="false"
        :item-per-page="20"
        :has-search="false"
        class="item"
        v-if="listDetails.length"
      >
        <template #created_date="{ item }">
          <MTypography class="description td-full-width" type="label">
            {{ formattedDate(item.created_date) }}
          </MTypography>
        </template>
        <template #departure_datetime="{ item }">
          <MTypography class="description td-full-width" type="label">
            {{ formattedDate(item.departure_datetime) }}
          </MTypography>
        </template>
        <template #status="{ item }">
          <MChip class="item" variant="success">{{ item.status }} </MChip>
        </template>
      </MDataTable>
      <AgNotFound
        v-else
        test-id=""
        heading="No Sabre Segment List found in the following date range"
        description=""
      />
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { subDays } from "date-fns";
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";

import { IObject } from "@/ag-portal-common/interfaces/object.interface";

import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { SABRE_SEGMENT_ANALYTICS_EVENTS } from "@/modules/SabreSegment/constants/anaylticsEvents";

import analyticsService from "@/analytic.service";
import SABREService from "@/modules/SabreSegment/services/sabreSegment.service";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import { MDataTable, MTypography } from "@aeroglobe/ag-core-ui";

import { MDataTableHeader } from "@/ag-portal-common/types";
import UTILS from "@/ag-portal-common/utils";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import date from "yup/lib/date";

export default defineComponent({
  name: "SabreSegment",
  components: { MDataTable },
  data(): {
    ELEMENT_TYPES: IObject;
    startDate: Date;
    endDate: Date;
    maxDate: Date;
    minStartDate: Date;
    statsDetails: null;
    listDetails: any[];
    isLoading: boolean;
    statsTableHeader: MDataTableHeader[];
    listTableHeader: MDataTableHeader[];
    organizationId: string;
    FORMAT_DD_MMM_YYYY_WITH_DASH: string;
    FORMAT_YYY_MM_DD: string;
  } {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_YYY_MM_DD: FORMAT_YYY_MM_DD,
      ELEMENT_TYPES,
      statsDetails: null,
      listDetails: [] as any[],
      isLoading: false,
      startDate: new Date(),
      endDate: new Date(),
      maxDate: new Date(),
      minStartDate: subDays(new Date(), 90),
      statsTableHeader: [
        {
          title: "Start Date",
          value: "start_date",
          key: "start_date",
          sortable: false,
        },
        {
          title: "End Date",
          value: "end_date",
          key: "end_date",
          sortable: false,
        },
        {
          title: "Confirmed Segments",
          value: "confirmed_flight_segment_count",
          key: "confirmed_flight_segment_count",
          sortable: false,
        },
        {
          title: "Flown Segments",
          value: "flown_segment_count",
          key: "flown_segment_count",
          sortable: false,
        },
        {
          title: "Issued Segments",
          value: "issued_flight_segment_count",
          key: "issued_flight_segment_count",
          sortable: false,
        },
        {
          title: "Bonus Amount",
          value: "total_segment_bonus_amount",
          key: "total_segment_bonus_amount",
          sortable: false,
        },
      ],
      listTableHeader: [
        {
          title: "Created Date",
          value: "created_date",
          key: "created_date",
          sortable: false,
        },
        {
          title: "Departure Date",
          value: "departure_datetime",
          key: "departure_datetime",
          sortable: false,
        },
        {
          title: "Origin",
          value: "origin",
          key: "origin",
          sortable: false,
        },
        {
          title: "Destination",
          value: "destination",
          key: "destination",
          sortable: false,
        },
        {
          title: "Airline",
          value: "operating_airline",
          key: "operating_airline",
          sortable: false,
        },
        {
          title: "Amount",
          value: "total_amount",
          key: "total_amount",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Passenger",
          value: "passenger_name",
          key: "passenger_name",
          sortable: false,
        },
      ],
      organizationId: "",
    };
  },
  async created() {
    const sabreService = new SABREService();

    if (this.isAllowedUser()) {
      await this.$store.dispatch("fetchOrganizations");
      return;
    }

    const segmentStatsResponse = await sabreService.getByDefaultSegmentStats();
    const segmentListResponse = await sabreService.getByDefaultSegmentList();

    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 44);

    this.statsDetails = segmentStatsResponse?.data?.data;
    this.listDetails = segmentListResponse?.data?.data.segment_list;

    this.trackAnalytics();
  },
  computed: {
    organizations(): FPComboboxOptions[] {
      let organizations = this.$store.getters.organizations;

      if (organizations) {
        return organizations?.map(
          (fp: {
            default_financial_profile?: {
              plan_name: string;
              platform_id: string;
            };
            sector: string;
            name: string;
            public_id: string;
            status: ORGANIZATION_STATUSES;
          }) => {
            const planType =
              (fp?.default_financial_profile?.plan_name
                ?.split(" ")[1]
                ?.toLowerCase() as "pro" | "elite" | "smart") ?? "";
            const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
            return {
              id: fp?.default_financial_profile?.platform_id ?? "",
              label: fp?.name,
              value: fp?.public_id,
              isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
              status: fp?.status,
              sector: sector,
              type: planType,
            };
          }
        );
      } else {
        return [];
      }
    },
    isFetchingOrganizations() {
      return this.$store.getters.isFetchingOrganizations;
    },

    userRole(): USER_ROLES | null {
      return this.$store.getters[AUTH_GETTERS.USER_ROLE];
    },
  },
  methods: {
    genTestId,
    ...mapActions(["defaultSabreSegmentList", "defaultSabreSegmentStats"]),
    changeStartDate(newDate: Date) {
      this.startDate = newDate;
    },

    changeEndDate(newDate: Date) {
      this.endDate = newDate;
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_WITH_DASH);
    },

    async fetchBookings() {
      if (this.isFetchingOrganizations || !this.canFetch()) {
        return;
      }

      this.trackAnalytics();

      if (this.endDate && this.startDate) {
        const dateDiffInMillis =
          (this.endDate as any) - (this.startDate as any);

        const daysDiff = Math.floor(dateDiffInMillis / (1000 * 60 * 60 * 24)); // differnce nikaalra k 30 days sa ziada to ni ha
        if (daysDiff <= 45) {
          this.statsDetails = null;
          this.listDetails = [];
          this.isLoading = true;
          const isAllowedUser = this.isAllowedUser();

          const sabreService = new SABREService();

          const response = await sabreService.getCustomSegmenStats(
            getFormattedDateTime(this.startDate, FORMAT_YYY_MM_DD),
            getFormattedDateTime(this.endDate, FORMAT_YYY_MM_DD),
            isAllowedUser ? this.organizationId : ""
          );
          this.statsDetails = response?.data?.data;

          const response_2 = await sabreService.getCustomSegmenLists(
            getFormattedDateTime(this.startDate, FORMAT_YYY_MM_DD),
            getFormattedDateTime(this.endDate, FORMAT_YYY_MM_DD),
            isAllowedUser ? this.organizationId : ""
          );
          this.listDetails = response_2?.data?.data.segment_list;
          this.isLoading = false;
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "days cant be more than 45";
          notificationService.triggerNotification();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "please change date range";
        notificationService.triggerNotification();
      }
    },

    isIncluded(source: string, target: string): boolean {
      return source.toLowerCase().includes(target.toLowerCase());
    },
    isAllowedUser() {
      return UTILS.isUser(
        USER_ROLES.OPERATIONS,
        USER_ROLES.SALES,
        USER_ROLES.AG_SUPER_USER
      );
    },
    canFetch() {
      const isOrganizationSelected =
        this.organizationId === "" || this.organizationId === null;
      return this.isAllowedUser() ? !isOrganizationSelected : true;
    },
    trackAnalytics() {
      const payload = {
        "user-role": this.userRole,
        "organization-id": this.organizationId,
        "start-date": this.startDate,
        "end-date": this.endDate,
      };
      analyticsService.logActionEvent(
        SABRE_SEGMENT_ANALYTICS_EVENTS.FETCH_SEGMENTS,
        payload
      );
    },
    onChangeOrgInputHandler(event: InputEvent) {
      this.organizationId = (event.target as HTMLInputElement).value;
    },
  },
});
</script>
<style scoped lang="css">
.sabre-segment-data-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  .sabre-segment-date-picker {
    width: 250px;
  }
}
.m-card {
  margin-bottom: 10px;
}
.accordion-title {
  margin: 0;
  border: none;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .sabre-segment-data-wrapper {
    flex-direction: column;
    align-items: flex-start;
    .sabre-segment-date-picker {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.sabre-segment-data-wrapper .m-datepicker-vue-date-picker {
  margin-bottom: 0px;
}
.accordion-title {
  margin: 0;
  border: none;
  padding: 0;
}

.item {
  text-align: center;
}
.sb-btn {
  width: 100%;
  height: 56px !important;
}

.user-input {
  &,
  .v-input,
  .vue-date-picker {
    margin: 0 !important;
  }

  &[isdisabled="true"] {
    opacity: 0.5 !important;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed !important;
  }
}

.v-overlay {
  box-shadow: none;

  .v-overlay__content {
    box-shadow: none !important;

    .v-list {
      margin-top: 10px;
      box-shadow: none !important;
      padding: 0;
      border-radius: 4px;
      border: 1px solid #ddd;
      min-height: 55px;
      max-height: 300px;
      position: relative;
      width: 100%;
      z-index: 10;

      .item {
        border-bottom: 1px solid #ddd;
        height: 55px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #f8f7f7;
        }
      }
    }
  }
}
</style>
