<template>
  <MAccordion :initial-open="true">
    <template #title>
      <AgIconInfoBar
        test-id=""
        title="Passenger Management"
        class="section-title"
        mPrepandIcon="m-passenger-management"
      />
    </template>
    <template #content>
      <MButton class="add-passenger-button" @click="handleNewPassenger">
        Add New Passenger
      </MButton>
      <ag-loader v-if="isFetchingOrganizationPassengers" />
      <template v-else>
        <AgNotFound
          v-if="showNoResult()"
          test-id=""
          heading="No Passenger Found"
        />
        <MDataTable
          v-else
          :headers="headers"
          :data="passengersList"
          :item-per-page="10"
          :has-search="false"
        >
          <template #passport_expiry="{ item }">
            <MTypography class="description td-full-width" type="label">
              {{ formattedDate(item.passport_expiry) }}
            </MTypography>
          </template>
          <template #action="{ item }">
            <MButton
              :disabled="item.agent_type === AGENT_SUB_ROLES.OWNER"
              @click="handleUpdate($event, item)"
            >
              {{ item.action_update }}
            </MButton>
          </template>
          <template #remove="{ item }">
            <MFabButton
              class="delete-icon"
              icon="m-delete"
              @click="handleDelete($event, item)"
            />
          </template>
        </MDataTable>
      </template>
      <DeletePassengerConfirmationModel
        :is-loading="isConfirmationDialogLoading"
        :is-open="confirmationDialogOpen"
        @handleAccept="handleConfirmDeletePassenger"
        @handleReject="handleDeletePassengerDialogClose"
      />

      <AddPassengerModal
        :is-open="updatePassengerDialogOpen"
        :is-edit="isPassengerEdit"
        @closeModal="handleClosePassengerModal"
        @onPassengerSubmit="handlePassengerSubmit"
      />
      <UpdatePassengerModal
        v-if="selectedPassenger"
        :passenger="selectedPassenger"
        :is-open="updatePassengerDialogOpen"
        :is-edit="isPassengerEdit"
        @closeModal="handleClosePassengerModal"
        @onPassengerSubmit="handlePassengerSubmit"
      />
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import DeletePassengerConfirmationModel from "@/modules/Organization/components/DeletePassengerConfirmationModel.vue";
import AddPassengerModal from "@/modules/Organization/components/AddPassengerModal.vue";
import { format } from "date-fns";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import analyticsService from "@/analytic.service";
import { ORGANIZATION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";
import UpdatePassengerModal from "@/modules/Organization/components/AddPassengerModal.vue";
import { MDataTable, MTypography } from "@aeroglobe/ag-core-ui";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "PassengerManagement",
  components: {
    DeletePassengerConfirmationModel,
    AddPassengerModal,
    MDataTable,
    UpdatePassengerModal,
    MTypography,
  },
  mounted() {
    if (this.organization) {
      const organizationId = this.organization.organization_id;

      this.$store.dispatch("getOrganizationPassengers", organizationId);
    }
  },
  data() {
    return {
      ELEMENT_TYPES,
      AGENT_SUB_ROLES,
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      headers: [
        {
          title: "First Name",
          value: "first_name",
          key: "first_name",
          sortable: true,
        },
        {
          title: "Last Name",
          value: "last_name",
          key: "last_name",
          sortable: true,
        },
        {
          title: "CNIC",
          value: "cnic",
          key: "cnic",
          sortable: false,
        },
        {
          title: "Passport Number",
          value: "passport_number",
          key: "passport_number",
          sortable: false,
        },
        {
          title: "Passport Expiry",
          value: "passport_expiry",
          key: "passport_expiry",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
        {
          title: "Remove",
          value: "remove",
          key: "remove",
          sortable: false,
        },
      ] as MDataTableHeader[],
      selectedPassenger: null as IPassenger | null,
      confirmationDialogOpen: false,
      updatePassengerDialogOpen: false,
      isPassengerEdit: false,
    };
  },
  methods: {
    genTestId,
    formattedDate(date: Date | null) {
      if (date) {
        return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_WITH_DASH);
      } else {
        return "-";
      }
    },
    handleDelete(
      e: { stopImmediatePropagation: () => void },
      passenger: IPassenger
    ) {
      e.stopImmediatePropagation();
      this.selectedPassenger = passenger;
      this.confirmationDialogOpen = true;
    },
    handleUpdate(e: { stopImmediatePropagation: () => void }, passenger: null) {
      e.stopImmediatePropagation();
      this.selectedPassenger = passenger;
      this.isPassengerEdit = true;
      this.updatePassengerDialogOpen = true;
    },
    handleNewPassenger() {
      this.isPassengerEdit = false;
      this.updatePassengerDialogOpen = true;
    },
    handleConfirmDeletePassenger() {
      if (!this.organization) {
        return;
      }

      const organizationId = this.organization.organization_id;

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.DELETE_PASSENGER_MANAGEMENT,
        this.selectedPassenger
      );
      this.$store.dispatch("deleteOrganizationPassengerById", {
        passengerId: this.selectedPassenger?.public_id as string,
        organizationId,
      });
      this.selectedPassenger = null;
      this.confirmationDialogOpen = false;
    },
    onSuccessHandler(successCallback: () => void) {
      return () => {
        this.selectedPassenger = null;
        this.isPassengerEdit = false;
        this.updatePassengerDialogOpen = false;
        successCallback();
      };
    },
    handlePassengerSubmit({
      payload,
      successCallback,
    }: {
      payload: IPassenger;
      successCallback: () => void;
    }) {
      if (!this.organization) {
        return;
      }

      const organizationId = this.organization.organization_id;

      const updatedPayload = {
        ...payload,
        dob: format(new Date(payload.dob), FORMAT_YYY_MM_DD),
        passport_expiry: payload.passport_expiry
          ? format(new Date(payload.passport_expiry), FORMAT_YYY_MM_DD)
          : null,
        passport_number: payload.passport_number
          ? payload.passport_number
          : null,
        cnic: payload.cnic ? payload.cnic : null,
      };
      if (this.isPassengerEdit) {
        this.$store.dispatch("updateOrganizationPassengerById", {
          passengerId: this.selectedPassenger?.public_id,
          organizationId,
          body: updatedPayload,
          onSuccessHandler: this.onSuccessHandler(successCallback),
        });
      } else {
        this.$store.dispatch("createOrganizationPassenger", {
          organizationId,
          body: [updatedPayload],
          onSuccessHandler: this.onSuccessHandler(successCallback),
        });
      }
    },
    handleDeletePassengerDialogClose() {
      this.selectedPassenger = null;
      this.isPassengerEdit = false;
      this.confirmationDialogOpen = false;
    },
    handleClosePassengerModal() {
      this.selectedPassenger = null;
      this.isPassengerEdit = false;
      this.updatePassengerDialogOpen = false;
    },
    showNoResult(): boolean {
      return (
        !this.isFetchingOrganizationPassengers &&
        this.$store.getters.organizationPassengers.length < 1
      );
    },
  },
  computed: {
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    PATH(): typeof PATH {
      return PATH;
    },
    passengersList(): IPassenger[] {
      const passengersList = this.$store.getters
        .organizationPassengers as IPassenger[];

      return passengersList.map((item: IPassenger) => ({
        ...item,
        public_id: item.public_id,
        first_name: item?.first_name,
        last_name: item?.last_name,
        passport_number: item.passport_number ?? "-",
        passport_expiry: item.passport_expiry ?? null,
        cnic: item.cnic ?? "-",
        action_delete: "Delete",
        action_update: "Update",
      }));
    },
    isFetchingOrganizationPassengers(): boolean {
      return this.$store.getters.isFetchingOrganizationPassengers as boolean;
    },
    isConfirmationDialogLoading(): boolean {
      return this.$store.getters.setPassengerActionLoading as boolean;
    },
  },
});
</script>

<style lang="scss">
.passenger-action-btn-container {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-icon {
  color: #e65a5a !important;
}
</style>

<style scoped lang="css">
.section-title {
  margin: 0;
  border: none;
  padding: 0;
}
.add-passenger-button {
  float: right;
  margin: 20px;
}
</style>
