import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { FLIGHT_REFUNDS_API_PATH } from "@/ag-flight-components/constants/apiEndPoints";
import {
  FetchFlightRefundPNRPayload,
  CalculateFlightRefundPNRPayload,
  CommitFlightRefundPNRPayload,
  FetchFlightRefundPNRResponse,
  CalculateFlightRefundPNRResponse,
} from "@/ag-flight-components/interfaces/IFlightRefunds.interface";

class FlightRedundsService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public fetchRefundPNR(
    params: FetchFlightRefundPNRPayload
  ): Promise<IAGResponse<IAGResponse<FetchFlightRefundPNRResponse>>> {
    return this.restClient.post(FLIGHT_REFUNDS_API_PATH.FETCH_DETAILS, params);
  }
  public calculateRefundPNR(
    params: CalculateFlightRefundPNRPayload
  ): Promise<IAGResponse<IAGResponse<CalculateFlightRefundPNRResponse>>> {
    return this.restClient.post(
      FLIGHT_REFUNDS_API_PATH.CALCULATE_DETAILS,
      params
    );
  }
  public commitRefundPNR(
    params: CommitFlightRefundPNRPayload
  ): Promise<IAGResponse> {
    return this.restClient.post(FLIGHT_REFUNDS_API_PATH.COMMIT, params);
  }
}

export default FlightRedundsService;
