<template>
  <div class="refund-status-flag-banner">
    {{ title ?? "Status" }} :
    <span class="refund-status-flag-chip">
      {{ code }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RefundedChip",
  props: {
    code: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
});
</script>

<style scoped lang="css">
.refund-status-flag-banner {
  --theme-color: var(--green-color);
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  width: max-content;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 8px;
  background-color: color-mix(
    in srgb,
    var(--m-success-color) 10%,
    var(--m-light-color) 100%
  );
  color: black;
}
.refund-status-flag-banner {
  color: var(--m-success-color);
}
.refund-status-flag-chip {
  color: red;
}
.refund-status-flag-banner::after {
  content: " ";
  position: absolute;
  right: -25px;
  width: 25px;
  height: 100%;
  display: block;
  z-index: 12;
  clip-path: polygon(100% 0%, 25% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  background-color: inherit;
}
</style>
