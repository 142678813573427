<template>
  <table
    class="div-style"
    role="presentation"
    border="0"
    cellpadding="0"
    cellspacing="0"
    style="
      border-collapse: separate;
      mso-table-lspace: 0;
      mso-table-rspace: 0;
      min-width: 100%;
      width: 100%;
    "
  >
    <tr style="padding: 0">
      <td
        style="
          background-color: #ffffff;
          display: block;
          margin: 0 auto !important;
          max-width: 100%;
          width: 100%;
          padding: 1em;
        "
      >
        <div
          style="width: 100%; border-bottom: 2px solid #6b7280; padding: 1em 0"
        >
          <table border="0" cellpadding="0" cellspacing="0" style="width: 100%">
            <tr>
              <td>
                <div>
                  <img
                    style="
                      float: left;
                      border-right: 1px dashed #dedede;
                      padding-right: 30px;
                      margin-right: 30px;
                      max-height: 54px;
                    "
                    :src="logoUrl"
                    :alt="displayName"
                  />
                  <h1
                    style="
                      margin: 0;
                      position: relative;
                      top: 6px;
                      font-size: 30px;
                      color: #111827;
                    "
                  >
                    Booking Information
                  </h1>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div style="width: 100%; margin-top: 1em">
          <table border="0" cellpadding="0" cellspacing="6" style="width: 100%">
            <tr>
              <td>
                <div style="width: 100%">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    style="
                      width: 100%;
                      background-color: #ecf9f5;
                      padding: 1em;
                      box-sizing: border-box;
                      border-radius: 8px;
                    "
                    :style="{ 'background-color': primaryColorAlpha }"
                  >
                    <tr>
                      <td>
                        <div
                          style="
                            text-transform: uppercase;
                            color: #6b7280;
                            font-style: normal;
                            font-size: 12px;
                            margin-bottom: 1em;
                          "
                        >
                          Agency
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="text-transform: uppercase; color: #111827">
                          {{ displayName }}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
              <td>
                <div style="width: 100%">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    style="
                      width: 100%;
                      background-color: #ecf9f5;
                      padding: 1em;
                      box-sizing: border-box;
                      border-radius: 8px;
                    "
                    :style="{ 'background-color': primaryColorAlpha }"
                  >
                    <tr>
                      <td>
                        <div
                          style="
                            text-transform: uppercase;
                            color: #6b7280;
                            font-style: normal;
                            font-size: 12px;
                            margin-bottom: 1em;
                          "
                        >
                          Booking Status
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style="text-transform: uppercase; color: #111827">
                          Confirmed
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          style="
            width: 100%;
            margin-top: 2em;
            border-radius: 8px;
            background-color: #ffffff;
            box-sizing: border-box;
            border: 1px solid rgba(100, 116, 139, 0.12);
            margin-bottom: 1em;
          "
        >
          <div
            style="
              width: 100%;
              padding: 1em;
              box-sizing: border-box;
              background-color: #10b981;
              border-top-left-radius: inherit;
              border-top-right-radius: inherit;
            "
            :style="{ 'background-color': primaryColor }"
          >
            <div
              style="
                color: #ffffff;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-size: 14px;
              "
            >
              Flight Details
            </div>
          </div>
          <div
            style="
              border-bottom-left-radius: inherit;
              border-bottom-right-radius: inherit;
              padding-bottom: 10px;
            "
          >
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              style="width: 100%"
            >
              <tr>
                <td style="width: 100%">
                  <div
                    style="width: 100%; padding: 1em; box-sizing: border-box"
                  >
                    <table
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      style="width: 100%"
                    >
                      <tr>
                        <td>
                          <div style="width: 125px; height: 60px">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/st-airline-images/EK.png"
                              alt="flight-logo"
                              style="
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                              "
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            style="
                              letter-spacing: 1px;
                              text-transform: uppercase;
                              text-align: end;
                            "
                          >
                            ER-8380
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="width: 100%">
                  <div
                    style="
                      background-image: linear-gradient(
                        90deg,
                        rgba(222, 222, 222, 0.5),
                        rgba(222, 222, 222, 0.5) 75%,
                        transparent 75%,
                        transparent 100%
                      );
                      background-size: 15px 1px;
                      height: 2px;
                      width: 100%;
                    "
                  ></div>
                </td>
              </tr>
              <tr>
                <td>
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tr>
                      <td>
                        <div
                          style="
                            width: 100%;
                            margin: 1em 0;
                            padding: 0 1em;
                            background-color: #ffffff;
                          "
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="width: 100%"
                          >
                            <tr>
                              <td>
                                <div
                                  style="
                                    background-color: rgba(16, 185, 129, 0.08);
                                    box-sizing: border-box;
                                    border-radius: 4px;
                                    border-left: 4px solid rgb(11, 129, 90);
                                    padding: 1em;
                                    width: 100%;
                                  "
                                  :style="{
                                    'background-color': primaryColorAlpha,
                                    'border-color': primaryColor,
                                  }"
                                >
                                  <div
                                    style="
                                      text-transform: uppercase;
                                      font-size: 14px;
                                      font-weight: 600;
                                    "
                                  >
                                    Departure - Tuesday, March 11, 2022
                                  </div>
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <div style="width: 100%; margin: 1.5em 0">
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          style="width: 100%; padding: 0 6px"
                                        >
                                          <table>
                                            <tr>
                                              <td>03:55 PM</td>
                                            </tr>
                                            <tr>
                                              <td style="height: 60px"></td>
                                            </tr>
                                            <tr>
                                              <td>06:05 PM</td>
                                            </tr>
                                          </table>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          style="width: 100%; padding: 0 1em"
                                        >
                                          <table>
                                            <tr>
                                              <td>
                                                <div
                                                  style="
                                                    height: 8px;
                                                    min-height: 8px;
                                                    max-height: 8px;
                                                    width: 8px;
                                                    min-width: 8px;
                                                    max-width: 8px;
                                                    border-radius: 50%;
                                                    margin: 0 auto;
                                                  "
                                                  :style="{
                                                    'background-color':
                                                      primaryColor,
                                                  }"
                                                ></div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="height: 60px">
                                                <div
                                                  style="
                                                    height: 100%;
                                                    width: 2px;
                                                    margin: 0 auto;
                                                  "
                                                  :style="{
                                                    'background-color':
                                                      primaryColor,
                                                  }"
                                                ></div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div
                                                  style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    height: 12px;
                                                    min-height: 12px;
                                                    max-height: 12px;
                                                    width: 12px;
                                                    min-width: 12px;
                                                    max-width: 12px;
                                                    border-radius: 50%;
                                                  "
                                                  :style="{
                                                    'background-color':
                                                      primaryColor,
                                                  }"
                                                >
                                                  <div
                                                    style="
                                                      border: 2px solid white;
                                                      height: 8px;
                                                      min-height: 8px;
                                                      max-height: 8px;
                                                      width: 8px;
                                                      min-width: 8px;
                                                      max-width: 8px;
                                                      border-radius: 50%;
                                                      display: flex;
                                                      align-items: center;
                                                      text-align: center;
                                                    "
                                                    :style="{
                                                      'background-color':
                                                        primaryColor,
                                                    }"
                                                  ></div>
                                                </div>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </td>
                                      <td>
                                        <table>
                                          <tr>
                                            <td>
                                              <div
                                                style="
                                                  text-transform: uppercase;
                                                  font-size: 10px;
                                                  display: flex;
                                                  align-items: flex-end;
                                                  color: #111827;
                                                "
                                              >
                                                <div
                                                  style="
                                                    color: #000000;
                                                    font-size: 18px;
                                                  "
                                                >
                                                  Karachi /
                                                </div>
                                                Jinnah International Airport
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div
                                                style="flex: 1; margin: 1.5em 0"
                                              >
                                                <div
                                                  style="
                                                    color: #6b7280;
                                                    font-size: 12px;
                                                  "
                                                >
                                                  2 hrs 10 mins Economy
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div
                                                style="
                                                  text-transform: uppercase;
                                                  font-size: 10px;
                                                  display: flex;
                                                  align-items: flex-end;
                                                  color: #111827;
                                                "
                                              >
                                                <div
                                                  style="
                                                    color: #000000;
                                                    font-size: 18px;
                                                  "
                                                >
                                                  Abu Dhabi /
                                                </div>
                                                Abu Dhabi - International
                                                Airport
                                              </div>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  style="
                                    width: 100%;
                                    background-color: #eff8fe;
                                    border-radius: 4px;
                                    box-sizing: border-box;
                                    border-left: 4px solid #0b7cd4;
                                    padding: 1em;
                                  "
                                  :style="{
                                    'background-color': primaryColorAlpha,
                                    'border-color': primaryColor,
                                  }"
                                >
                                  <div
                                    style="
                                      text-transform: uppercase;
                                      font-size: 14px;
                                      font-weight: 600;
                                    "
                                  >
                                    Lahore (LHi) - 01 hrs 10 min stop
                                  </div>
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          style="
            width: 100%;
            margin-top: 2em;
            border-radius: 8px;
            background-color: #ffffff;
            box-sizing: border-box;
            border: 1px solid rgba(100, 116, 139, 0.12);
            margin-bottom: 1em;
          "
        >
          <div
            style="
              width: 100%;
              padding: 1em;
              box-sizing: border-box;
              background-color: #10b981;
              border-top-left-radius: inherit;
              border-top-right-radius: inherit;
            "
            :style="{ 'background-color': primaryColor }"
          >
            <div
              style="
                color: #ffffff;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-size: 14px;
              "
            >
              Passenger Details
            </div>
          </div>
          <div
            style="
              border-bottom-left-radius: inherit;
              border-bottom-right-radius: inherit;
            "
          >
            <table cellspacing="0" cellpadding="0" style="width: 100%">
              <thead style="background-color: #f7f7f8">
                <tr>
                  <th
                    style="
                      text-align: center;
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #6b7280;
                      font-size: 14px;
                      font-weight: normal;
                    "
                  >
                    Title
                  </th>
                  <th
                    style="
                      text-align: center;
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #6b7280;
                      font-size: 14px;
                      font-weight: normal;
                    "
                  >
                    First Name
                  </th>
                  <th
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #6b7280;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    Last Name
                  </th>
                  <th
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #6b7280;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    passenger type
                  </th>
                  <th
                    style="
                      text-align: start;
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #6b7280;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    date of <br />birth
                  </th>
                  <th
                    style="
                      text-align: start;
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #6b7280;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    passport number
                  </th>
                  <th
                    style="
                      text-align: start;
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #6b7280;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    passport expiry
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="text-transform: uppercase">
                  <td
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #000000;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    Mr
                  </td>
                  <td
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #000000;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    AliHasnain
                  </td>
                  <td
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #000000;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    Ladhani
                  </td>
                  <td
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #000000;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    Adult
                  </td>
                  <td
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #000000;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    Saturday, Jan 19 2002
                  </td>
                  <td
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #000000;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    42000-1234567-8
                  </td>
                  <td
                    style="
                      padding: 1em 5px;
                      text-transform: uppercase;
                      color: #000000;
                      font-size: 14px;
                      font-weight: normal !important;
                      text-align: center;
                    "
                  >
                    friday, Mar 11 2020
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "BookingTemplate",
  props: {
    logo: {
      type: String,
      required: true,
    },
    organizationName: {
      type: String,
      required: true,
    },
    defaultColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logoUrl: this.logo,
      displayName: this.organizationName,
      primaryColor: this.defaultColor,
      primaryColorAlpha: this.defaultColor + "19",
    };
  },
  watch: {
    organizationName: function () {
      this.displayName = this.organizationName;
    },
    defaultColor: function () {
      this.primaryColor = this.defaultColor;
      this.primaryColorAlpha = this.defaultColor + "19";
    },
    logo: function () {
      this.logoUrl = this.logo;
    },
  },
};
</script>

<style lang="css" scoped>
.div-style {
  margin-top: 10px;
}
</style>
