<template>
  <ag-modal
    :is-open="isEditModalOpen"
    @close="closeEditModal"
    modal-width="30%"
  >
    <template #body>
      <AgDiv style="align-items: center !important">
        <AGTextField name="Fare" label="Fare" v-model="inputAmount" />
        <AGButton test-id="" @click="(e: MouseEvent) => onSave()">
          Save
        </AGButton>
        <AGButton
          test-id=""
          @click="(e: MouseEvent) => closeEditModal()"
          style="margin-left: 20px"
        >
          Cancel
        </AGButton>
      </AgDiv>
    </template>
  </ag-modal>

  <AgNotFound
    test-id=""
    heading="No Quotations Added Yet"
    description=""
    v-if="!haveData && !haveReturnData"
  />

  <div v-else>
    <div class="manage-quotations" ref="manageQuotations">
      <AgDiv test-id="" class="text-right hideItem margin_bottom_10">
        <MButton test-id="" @click="(e: MouseEvent) => handleDownloadClick()">
          Download Quotations
        </MButton>
      </AgDiv>
      <AgDiv
        test-id=""
        class="d-flex justify-content-end margin_bottom_10 hideItem"
      >
        <AgFilterDropdown
          test-id=""
          class="fare_calculator_main hideItem hide-on-print"
          label="bulk edit"
          icon="calculator"
          @click.capture="null"
        >
          <template #Items>
            <AgFareCalculator test-id="">
              <AgTextFieldNew
                :label="renderLabel()"
                test-id=""
                type="number"
                variant="outlined"
                v-model="selectedAmount"
                :sign="renderSign()"
                :error="fieldError"
              ></AgTextFieldNew>
              <AgRow test-id="">
                <AgColumn test-id="">
                  <AgDiv test-id="" class="fare_calc_box">
                    <AgHeading
                      title="Select Amount Type"
                      variant="label"
                      test-id=""
                    ></AgHeading>
                    <AgRadio test-id="" v-model="selectedAmountType">
                      <AgRadioItem
                        test-id=""
                        name="amount_type"
                        value="fixed"
                        label="Fixed Amount"
                      ></AgRadioItem>
                      <AgRadioItem
                        test-id=""
                        name="amount_type"
                        value="percentage"
                        label="Percentage Amount"
                      ></AgRadioItem>
                    </AgRadio>
                  </AgDiv>
                </AgColumn>
              </AgRow>
              <AgRow test-id="">
                <AgColumn test-id="" class="text-right">
                  <MButton test-id="" type="filled" @click="handelApply"
                    >APPLY</MButton
                  ></AgColumn
                >
              </AgRow>
            </AgFareCalculator>
          </template>
        </AgFilterDropdown>
      </AgDiv>

      <div style="display: flex; align-content: end">
        <div>
          <img
            class="custom-print-content"
            :src="getOrgLogoHandler()"
            style="
              border-right: 1px dashed #dedede;
              max-height: 54px;
              padding: 0.25cm;
            "
          />
        </div>
        <div>
          <ag-heading
            style="margin-left: 0.25cm; margin-top: 8px; padding: auto"
            class="custom-print-content"
            variant="h1"
            :title="getOrganisationNameHandler()"
          ></ag-heading>
        </div>
      </div>

      <br />

      <div
        v-show="haveData"
        v-for="(flightItem, index) in (Data as any)"
        :key="index"
        style="margin-bottom: 0.5cm; background-color: white"
        class="showItem"
      >
        <AgColumn test-id="">
          <AgFlightDetailCard
            style="padding: auto"
            test-id=""
            :airline-logo="flightItem?.airline_logo"
            :airline-code="flightItem?.airline_code"
            :duration="flightItem.duration"
            :from-destination="flightItem?.from_destination"
            :from-time="flightItem?.from_time"
            :to-time="flightItem?.to_time"
            :to-destination="flightItem?.to_destination"
            :airline-name="flightItem?.airline_name"
            fare-info="true"
          >
          </AgFlightDetailCard>

          <hr class="border-dashed" />
          <AgRow test-id="">
            <AgFlightFare
              v-for="(fare, indexs) in flightItem?.rdbs"
              test-id=""
              :key="indexs"
              :type="fare?.rbd"
              :price="
                formatCurrency(
                  calculateFlightPrice(fare?.price),
                  fare.price.gross_fare.currency
                )
              "
              :piece="getBaggageInformation(fare.baggage_info)"
              pieceIcon="bagIcon"
            >
              <template #button>
                <div class="hideItem">
                  <MButton
                    test-id=""
                    @click="(e: MouseEvent) => handleEditClick(index,indexs)"
                  >
                    Edit Fare
                  </MButton>
                </div>
              </template>
            </AgFlightFare>
          </AgRow>

          <AgDiv test-id="" class="text-right" style="margin: 0.5cm">
            <AGButton class="compare_icon hideItem" variant="link">
              <ag-icon
                test-id="delete"
                name="deleteIcon"
                class="hideItem"
                @click="handleDeleteIconClick(index)"
              ></ag-icon>
            </AGButton>
          </AgDiv>
        </AgColumn>
      </div>

      <template v-if="haveReturnData">
        <div
          v-for="(pair, index) in returnFlightPairs"
          :key="`return-flight-${index}`"
          style="margin-bottom: 0.5cm; background-color: white"
          class="showItem return-flight-card"
        >
          <AgColumn test-id="">
            <ReturnFlightRouteCard
              :departure-airline-name="
                parseString(departureFlightOption(pair)?.airline.name)
              "
              :departure-airline-logo="
                parseString(departureFlightOption(pair)?.airline.logo)
              "
              :departure-airline-code="
                parseString(
                  departureFlightOption(pair)?.flight_numbers.join(' . ')
                )
              "
              :departure-duration="
                parseNumber(departureFlightOption(pair)?.duration)
              "
              :departure-from-time="
                parseString(departureFlightOption(pair)?.departure_time)
              "
              :departure-to-time="
                parseString(departureFlightOption(pair)?.arrival_time)
              "
              :departure-destination="parseString(departureDestination(pair))"
              :arrival-airline-code="
                parseString(
                  arrivalFlightOption(pair)?.flight_numbers.join(' . ')
                )
              "
              :arrival-duration="
                parseNumber(arrivalFlightOption(pair)?.duration)
              "
              :arrival-from-time="
                parseString(arrivalFlightOption(pair)?.departure_time)
              "
              :arrival-to-time="
                parseString(arrivalFlightOption(pair)?.arrival_time)
              "
              :arrival-destination="parseString(arrivalDestination(pair))"
            />
            <hr class="border-dashed" />
            <AgRow test-id="" style="align-items: center">
              <AgFlightFare
                :type="departureFareOption(pair).rbd"
                :price="
                  arrivalDestination(pair) + '-' + departureDestination(pair)
                "
                :piece="
                  getBaggageInformation(departureFareOption(pair).baggage_info)
                "
                pieceIcon="bagIcon"
              >
              </AgFlightFare>
              <AgFlightFare
                :type="arrivalFareOption(pair).rbd"
                :price="
                  departureDestination(pair) + '-' + arrivalDestination(pair)
                "
                :piece="
                  getBaggageInformation(arrivalFareOption(pair).baggage_info)
                "
                pieceIcon="bagIcon"
              >
              </AgFlightFare>
              <AgHeading
                variant="h2"
                class="return-flight-price"
                :title="getGrossFareHandler(pair)"
              />
              <AGButton
                test-id=""
                style="max-height: fit-content"
                @click="(e: MouseEvent) => handleReturnEditClick(index)"
                class="hideItem"
              >
                Edit Fare
              </AGButton>
            </AgRow>

            <AgDiv test-id="" class="text-right" style="margin: 0.5cm">
              <ag-icon
                test-id="delete"
                name="deleteIcon"
                class="hideItem"
                @click="handleReturnDeleteIconClick(index)"
              ></ag-icon>
            </AgDiv>
          </AgColumn>
        </div>
      </template>

      <div
        style="margin-left: 10px; align-items: center"
        class="custom-print-content-generated-by"
      >
        <ag-heading variant="h3" :title="generatedBy"></ag-heading>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { QUOTATIONS_ANALYTICS_EVENTS } from "@/modules/ManageQuotations/constants/anaylticsEvents";
import analyticsService from "@/analytic.service";

import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

import { Price } from "@/modules/ManageQuotations/views/utils/BookingResponse";
import { TimeUtility } from "@/modules/ManageQuotations/views/utils/TimeUtility";
import { getCurrencyFormatter } from "@/modules/ManageQuotations/views/utils/index";

import ReturnFlightRouteCard from "../../../ag-flight-components/components/FlightSearchResults/ReturnFlightRouteCard.vue";
import {
  BaggageInfo,
  FareOption,
  FlightOption,
  ReturnFlightPair,
} from "@/ag-flight-components/types";
import { getGrossFare } from "@/ag-flight-components/utils";
import { Organization, User } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "FlightDetailCardItem",
  components: { ReturnFlightRouteCard },
  data(): {
    fareOption: FareOption | null;
    isPanelExpanded: boolean;
    Data: FareOption | null;
    isEditModalOpen: boolean;
    inputAmount: number;
    mainIndex: string;
    subIndex: string;
    selectedAmount: string;
    selectedAmountType: string;
    selectedFareType: string;
    sign: string;
    fieldError: string;
    state: boolean;

    returnFlightPairs: ReturnFlightPair[];
  } {
    return {
      fareOption: null,
      isPanelExpanded: false,
      Data: null,
      isEditModalOpen: false,
      inputAmount: 0,
      mainIndex: "",
      subIndex: "",
      selectedAmount: "",
      selectedAmountType: "fixed",
      selectedFareType: "",
      sign: "PKR",
      fieldError: "",
      state: false,

      returnFlightPairs: [],
    };
  },
  computed: {
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    generatedBy(): string {
      const userEmail = this.user ? this.user.email : "";

      return `Quotations generated by ${userEmail}`;
    },
    haveData(): boolean {
      if (
        localStorage.getItem("quotations")?.length === 2 ||
        localStorage.getItem("quotations") === null
      ) {
        return false;
      } else {
        return true;
      }
    },
    haveReturnData(): boolean {
      if (
        localStorage.getItem("Quotations-Return")?.length === 2 ||
        localStorage.getItem("Quotations-Return") === null
      ) {
        return false;
      } else {
        return true;
      }
    },
    extractToDate(): {
      departureDate: string;
      returnDate: string;
    } {
      const url = this.$route.query.trips;
      const urlToString = new String(url);
      const [, , departureDate, returnDate] = urlToString?.split(",");

      return {
        departureDate,
        returnDate: returnDate || departureDate,
      };
    },
  },
  methods: {
    getGrossFare,

    parseString(str: string | undefined): string {
      return str ?? "";
    },
    parseNumber(num: number | undefined): number {
      return num ?? 0;
    },
    departureFlightOption(pair: ReturnFlightPair): FlightOption | undefined {
      return pair.departure.flight_option;
    },
    departureFareOption(pair: ReturnFlightPair): FareOption {
      return pair.departure.fare_option;
    },
    arrivalFlightOption(pair: ReturnFlightPair): FlightOption | undefined {
      return pair.arrival.flight_option;
    },
    arrivalFareOption(pair: ReturnFlightPair): FareOption {
      return pair.arrival.fare_option;
    },
    agPriceDifference(pair: ReturnFlightPair): number {
      return this.arrivalFareOption(pair).price.ag_price_difference.value;
    },
    departureDestination(pair: ReturnFlightPair): string {
      const segments = this.departureFareOption(pair).segments;

      return segments[0].destination.iata_code;
    },
    arrivalDestination(pair: ReturnFlightPair): string {
      const segments = this.arrivalFareOption(pair).segments;

      return segments[segments.length - 1].destination.iata_code;
    },
    getFareHandler(
      fareType:
        | "base_fare"
        | "gross_fare"
        | "ag_gross_fare"
        | "tax"
        | "ag_price_difference",
      pair: ReturnFlightPair
    ): string {
      const fareOption = this.arrivalFareOption(pair);

      const fare = fareOption.price[fareType];

      return fare
        ? this.formatCurrency(Number(fare.value), fare.currency ?? "")
        : "";
    },
    getGrossFareHandler(pair: ReturnFlightPair): string {
      const fareOption = this.arrivalFareOption(pair);

      const fare = this.getGrossFare(fareOption);

      return fare
        ? this.formatCurrency(
            Number(fare),
            fareOption.price.gross_fare.currency ?? ""
          )
        : "";
    },
    getBaggageInformation(baggage_info: BaggageInfo | undefined): string {
      if (baggage_info) {
        const { pieces, weight, unit } = baggage_info;
        if (pieces < 1) {
          return "Baggage included (As per airline policy)";
        }
        return `${pieces} piece - ${weight} ${unit}`;
      } else {
        return "No - Baggage";
      }
    },

    getOrgLogoHandler(): string {
      const DEFAULT_LOGO = "../../assets/logo.svg";
      const organization = this.organization;
      const org_logo = organization ? organization.org_logo : null;

      return org_logo ?? DEFAULT_LOGO;
    },
    getOrganisationNameHandler(): string {
      const DEFAULT_NAME = "Aeroglobe";
      const organization = this.organization;

      return organization ? organization.org_name_to_display : DEFAULT_NAME;
    },
    handleEditClick(index: any, indexs: any) {
      this.mainIndex = index;
      this.subIndex = indexs;
      this.isEditModalOpen = true;
      localStorage.setItem("isReturn", "false");
      analyticsService.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.EDIT_FARE, {
        editted: this.Data,
      });
    },
    handleReturnEditClick(index: any) {
      localStorage.setItem("isReturn", "true");
      this.mainIndex = index;
      this.isEditModalOpen = true;

      analyticsService.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.EDIT_FARE, {
        editted: this.Data,
      });
    },

    closeEditModal() {
      this.isEditModalOpen = false;
    },
    async handleDownloadClick() {
      const manageQuotations = this.$refs
        .manageQuotations as HTMLElement | null;

      if (manageQuotations) {
        const clone = document.createElement("div");

        clone.innerHTML = manageQuotations.innerHTML;
        clone.classList.toggle("on-print-qoutations");

        document.body.append(clone);
        window.print();
        document.body.lastChild?.remove();
      }

      analyticsService.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.DOWNLOAD_PDF,
        {
          downloadedQuotations: this.Data,
        }
      );
    },
    handelApply() {
      if (this.selectedAmountType !== AMOUNT_TYPE.PERCENTAGE) {
        this.handleFixedAmountToAdd();
      } else {
        this.handlePercentageAmountToAdd();
      }
    },
    handleFixedAmountToAdd() {
      const quotations = localStorage.getItem("quotations");
      const myList = quotations ? JSON.parse(quotations) : [];

      if (Number(this.selectedAmount) != 0) {
        for (let i = 0; i < myList.length; i++) {
          for (let j = 0; j < myList[i].rdbs.length; j++) {
            myList[i].rdbs[j].price.ag_gross_fare.value =
              Number(myList[i].rdbs[j].price.ag_gross_fare.value) +
              Number(this.selectedAmount);
          }
        }
        localStorage.setItem("quotations", JSON.stringify(myList));

        const quotations = localStorage.getItem("Quotations-Return");
        const tempPairs: ReturnFlightPair[] = quotations
          ? JSON.parse(quotations)
          : [];

        for (let i = 0; i < tempPairs.length; i++) {
          const fareOption = this.arrivalFareOption(tempPairs[i]);
          const fare = this.getGrossFare(fareOption);
          const price = fare + Number(this.selectedAmount);

          tempPairs[i] = this.onAddAmountToReturnFlightPairsHandler(
            tempPairs[i],
            price
          );
        }
        localStorage.setItem("Quotations-Return", JSON.stringify(tempPairs));
        this.selectedAmount = "";
        this.currentJourneyLeg();
        this.fetchReturnFlightPairs();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Enter Amount to continue";
        notificationService.triggerNotification();
      }
      return myList;
    },
    handlePercentageAmountToAdd() {
      const quotations = localStorage.getItem("quotations");
      const myList = quotations ? JSON.parse(quotations) : [];

      if (Number(this.selectedAmount) != 0) {
        const percentage = Number(this.selectedAmount) / 100;

        for (let i = 0; i < myList.length; i++) {
          for (let j = 0; j < myList[i].rdbs.length; j++) {
            const currentFareValue = Number(
              myList[i].rdbs[j].price.ag_gross_fare.value
            );
            const change = currentFareValue * percentage;
            myList[i].rdbs[j].price.ag_gross_fare.value =
              currentFareValue + change;
          }
        }
        localStorage.setItem("quotations", JSON.stringify(myList));

        const quotations = localStorage.getItem("Quotations-Return");
        const tempPairs: ReturnFlightPair[] = quotations
          ? JSON.parse(quotations)
          : [];

        for (let i = 0; i < tempPairs.length; i++) {
          const fareOption = this.arrivalFareOption(tempPairs[i]);
          const fare = this.getGrossFare(fareOption);
          const change = fare * percentage;
          const price = fare + change;

          tempPairs[i] = this.onAddAmountToReturnFlightPairsHandler(
            tempPairs[i],
            price
          );
        }

        localStorage.setItem("Quotations-Return", JSON.stringify(tempPairs));

        this.selectedAmount = "";
        this.currentJourneyLeg();
        this.fetchReturnFlightPairs();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Enter Percentage value to continue";
        notificationService.triggerNotification();
      }
      return myList;
    },
    handleDeleteIconClick(index: any) {
      const quotations = localStorage.getItem("quotations");
      let parsedQuotes = quotations ? JSON.parse(quotations) : [];

      const returnString = localStorage.getItem("Quotations-Return");
      let returnItem = returnString ? JSON.parse(returnString) : [];
      const indexToDelete = index;
      parsedQuotes.splice(indexToDelete, 1); // deleted
      localStorage.setItem("quotations", JSON.stringify(parsedQuotes));
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Deleted Flight Item Successfully";
      notificationService.triggerNotification();
      this.currentJourneyLeg();
      if (
        (JSON.stringify(parsedQuotes) === "[]" ||
          JSON.stringify(parsedQuotes) === null) &&
        (JSON.stringify(returnItem) === "[]" ||
          JSON.stringify(returnItem) === null)
      ) {
        localStorage.removeItem("quotations");
        window.location.reload();
      }
      this.currentJourneyLeg();
      analyticsService.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.DELETE_QUOTE,
        {
          deletedQuotations: this.Data,
        }
      );
    },
    handleReturnDeleteIconClick(index: any) {
      const quotations = localStorage.getItem("Quotations-Return");
      let parsedQuotes = quotations ? JSON.parse(quotations) : [];
      const returnString = localStorage.getItem("quotations");
      let returnItem = returnString ? JSON.parse(returnString) : [];
      const indexToDelete = index;
      parsedQuotes.splice(indexToDelete, 1); // deleted
      localStorage.setItem("Quotations-Return", JSON.stringify(parsedQuotes));
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Deleted Flight Item Successfully";
      notificationService.triggerNotification();
      this.fetchReturnFlightPairs();
      if (
        (JSON.stringify(parsedQuotes) === "[]" ||
          JSON.stringify(parsedQuotes) === null) &&
        (JSON.stringify(returnItem) === "[]" ||
          JSON.stringify(returnItem) === null)
      ) {
        localStorage.removeItem("Quotations-Return");
        window.location.reload();
      }
      this.fetchReturnFlightPairs();
      analyticsService.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.DELETE_QUOTE,
        {
          deletedQuotations: this.Data,
        }
      );
    },
    onSave() {
      //
      const isReturn = localStorage.getItem("isReturn");
      if (isReturn === "false") {
        const quotations = localStorage.getItem("quotations");
        const myList = quotations ? JSON.parse(quotations) : [];
        if (this.inputAmount > 0 && this.inputAmount) {
          myList[this.mainIndex].rdbs[this.subIndex].price.ag_gross_fare.value =
            this.inputAmount;
          localStorage.setItem("quotations", JSON.stringify(myList));
          this.currentJourneyLeg();
          this.isEditModalOpen = false;
          this.inputAmount = 0;

          analyticsService.logActionEvent(
            QUOTATIONS_ANALYTICS_EVENTS.SAVE_QUOTE,
            {
              savedQuotations: JSON.stringify(myList),
            }
          );
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description =
            "Fare cant be empty or negative or in words";
          notificationService.triggerNotification();
        }
      } else {
        const quotations = localStorage.getItem("Quotations-Return");
        const tempPairs: ReturnFlightPair[] = quotations
          ? JSON.parse(quotations)
          : [];

        if (this.inputAmount > 0 && this.inputAmount) {
          const index = Number(this.mainIndex);
          tempPairs[index] = this.onAddAmountToReturnFlightPairsHandler(
            tempPairs[index],
            this.inputAmount
          );

          localStorage.setItem("Quotations-Return", JSON.stringify(tempPairs));

          this.fetchReturnFlightPairs();
          this.isEditModalOpen = false;
          this.inputAmount = 0;

          analyticsService.logActionEvent(
            QUOTATIONS_ANALYTICS_EVENTS.SAVE_QUOTE,
            {
              savedQuotations: JSON.stringify(tempPairs),
            }
          );
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description =
            "Fare cant be empty or negative or in words";
          notificationService.triggerNotification();
        }
      }
    },

    onAddAmountToReturnFlightPairsHandler(
      pair: ReturnFlightPair,
      amount: number
    ) {
      const fareOption = pair.arrival.fare_option;

      if (
        fareOption.price.ag_gross_fare &&
        fareOption.price.ag_gross_fare.value
      ) {
        fareOption.price.ag_gross_fare.value = amount;
      } else {
        fareOption.price.gross_fare.value = amount;
      }

      pair.arrival.fare_option = fareOption;

      return pair;
    },

    title(amount: number) {
      if (amount > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration: (durationInNumber: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    calculateFlightPrice(priceObject: Price) {
      return Number(
        priceObject.ag_gross_fare?.value
          ? priceObject.ag_gross_fare.value
          : priceObject.gross_fare.value
      );
    },
    formatCurrency: (amount: number, currency_format: string) => {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    getNextDepartureTime(index: number) {
      return index + 1 < this.fareOption!.segments.length
        ? this.fareOption!.segments[index + 1].departure_datetime
        : new Date();
    },
    renderDepartureDate: (departure_date: Date) => {
      return TimeUtility.parsedFlightTimeorDate(
        departure_date,
        "EEEE, MMMM dd yyyy"
      );
    },
    renderRBD(rbd: string) {
      return `RBD: ${rbd}`;
    },
    currentJourneyLeg() {
      const quotations = localStorage.getItem("quotations");
      this.Data = quotations ? JSON.parse(quotations) : [];
      return this.Data;
    },
    fetchReturnFlightPairs() {
      const quotations = localStorage.getItem("Quotations-Return");
      this.returnFlightPairs = quotations ? JSON.parse(quotations) : [];
      return this.returnFlightPairs;
    },
    renderSign() {
      const currency = UTILS.getCurrency();
      return this.selectedAmountType === AMOUNT_TYPE.PERCENTAGE
        ? "%"
        : currency;
    },
    renderLabel() {
      return this.selectedAmountType === AMOUNT_TYPE.FIXED
        ? "Enter Fix Value of Amount"
        : "Enter Percentage of Amount";
    },
  },
  mounted() {
    this.currentJourneyLeg();
    this.fetchReturnFlightPairs();
  },
});
export enum AMOUNT_TYPE {
  FIXED = "fixed",
  PERCENTAGE = "percentage",
}

export enum FARE_TYPE {
  GROSS = "gross",
  BASE = "base",
}
</script>

<style>
.manage-quotations .return-flight-card {
  position: relative;
}
.manage-quotations .return-flight-price {
  position: absolute;
  right: 20px;
  top: 35px;
}
.manage-quotations .on-print-qoutations {
  width: 100%;
  overflow: scroll;
}

.showItem {
  background-color: white;
  color: black;
}
@media screen {
  .custom-print-content {
    display: none;
  }
  .custom-print-content-generated-by {
    display: none;
  }
}
@media print {
  .showItem {
    display: block;
  }
  .hideItem {
    display: none;
  }
  .custom-print-content {
    display: block !important;
  }
  .custom-print-content-generated-by {
    width: 100%;
    background-color: #f0f0f0;
    text-align: center;
    padding: 10px;
    bottom: 0;
  }
  #app,
  .m-header,
  .m-sidebar-wrapper,
  .hide-on-print {
    display: none !important;
  }
}
@page {
  size: A4;
}
</style>
