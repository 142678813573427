<template>
  <ag-loader
    v-if="
      $store.getters.isFetchingSectors ||
      $store.getters.isFetchingFinancialProfiles ||
      $store.getters.isFetchingOrganization
    "
  />
  <div v-else>
    <ag-card v-if="showSelectByDropdown">
      <ag-radio
        :testId="genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectBy')"
        v-model="selectBy"
        label="Whether to show individual or group?"
        inline
      >
        <ag-radio-item
          name="selectBy"
          :testId="
            genTestId(
              ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectBy-${selectByOpts.group.value}`
            )
          "
          :value="selectByOpts.group.value"
          :label="selectByOpts.group.label"
        ></ag-radio-item>
        <ag-radio-item
          name="selectBy"
          :testId="
            genTestId(
              ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectBy-${selectByOpts.individual.value}`
            )
          "
          :value="selectByOpts.individual.value"
          :label="selectByOpts.individual.label"
        ></ag-radio-item>
      </ag-radio>

      <div v-if="selectBy === selectByOpts.individual.value">
        <MFinancialProfileCombobox
          class="fp-combo"
          v-model:inputValue="financialProfilePublicId"
          label="Financial Profiles"
          itemValue="value"
          itemLabel="label"
          :disabled="isFinancialProfileFetching"
          :options="financialProfileOptions"
        />
      </div>
    </ag-card>

    <ag-card v-if="selectBy === selectByOpts.group.value">
      <ag-radio
        label="Please select the city."
        v-model="selectedCity"
        inline
        :testId="genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectedCity')"
      >
        <ag-radio-item
          name="selectedCity"
          v-for="(city, index) in cities"
          v-bind:key="index"
          :testId="
            genTestId(
              ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectedCity-${city.toLowerCase()}`
            )
          "
          :value="city.toLowerCase()"
          :label="city"
        ></ag-radio-item>
      </ag-radio>
    </ag-card>

    <ag-card v-if="selectBy === selectByOpts.group.value">
      <a-g-text-field label="Search Profile" v-model="searchProfile" />
      <ag-column class="agency-scroll">
        <ag-checkbox
          :testId="genTestId(ELEMENT_TYPES.CHECK_BOX, `soa-profiles-selectAll`)"
          v-if="!searchProfile"
          @click="onSelectAll"
          label="Select all"
          v-model="checkSelectAllCheckBox"
        />
        <ag-checkbox
          v-bind:key="profile.public_id"
          :testId="
            genTestId(
              ELEMENT_TYPES.CHECK_BOX,
              `soa-profiles-${profile.public_id}`
            )
          "
          v-model="selectedProfiles"
          v-for="profile in filteredProfiles"
          :value="profile.public_id"
          :label="`${profile.financial_profile_name} - ${profile.platform_id}`"
        />
      </ag-column>
    </ag-card>
    <GenerateSOA
      @fetchSOA="fetchSOA"
      :disableActionBtns="!isProfileSelected"
      @sendSOAEmail="sendSOAEmail"
      :disableSendBtn="!isGroupSelected"
      @downloadOldSOA="downloadOldSOA"
    />
  </div>
  <AgDiv>
    <ag-card>
      <AgIconInfoBar
        test-id=""
        icon="notInvoice"
        title="Pending Receipts and Invoices"
      />
      <AgDiv class="postings">
        <MAccordion
          :initial-open="true"
          v-if="pendingPostingFlightInvoices.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                icon="currentBalance"
                title="Flight Invoices"
                class="accordion-title soa-accordion"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="flightInvoicesHeaders"
              :data="pendingPostingFlightInvoices"
              :item-per-page="10"
              :total-item-count="pendingPostingFlightInvoices.length"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #ticket_no="{ item }">
                <MTypography class="description" type="body">
                  {{ item.ticket_no }}
                </MTypography>
              </template>
              <template #pnr="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip
                    class="chip-width"
                    :border-less="true"
                    variant="warning"
                    >{{ item.pnr }}
                  </MChip>
                </div>
              </template>
              <template #posting_status="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip class="chip-width" :border-less="true" variant="error"
                    >{{ item.posting_status }}
                  </MChip>
                </div>
              </template>
            </MDataTable>
          </template>
        </MAccordion>
        <MAccordion
          :initial-open="true"
          v-if="pendingPostingFlightReceipts.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                icon="currentBalance"
                title="Flight Receipts"
                class="accordion-title soa-accordion"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="flightReceiptsHeaders"
              :data="pendingPostingFlightReceipts"
              :item-per-page="10"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #payment_id="{ item }">
                <MTypography class="description" type="body">
                  {{ item.payment_id }}
                </MTypography>
              </template>
              <template #amount="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip
                    class="chip-width"
                    :border-less="true"
                    variant="warning"
                    >{{ item.amount }}
                  </MChip>
                </div>
              </template>
              <template #posting_status="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip class="chip-width" :border-less="true" variant="error"
                    >{{ item.posting_status }}
                  </MChip>
                </div>
              </template>
            </MDataTable>
          </template>
        </MAccordion>

        <MAccordion
          :initial-open="true"
          v-if="pendingPostingHotelInvoices.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                icon="currentBalance"
                title="Hotel Invoices"
                class="accordion-title soa-accordion"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="hotelInvoicesHeaders"
              :data="pendingPostingHotelInvoices"
              :item-per-page="10"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #booking_id="{ item }">
                <MTypography class="description" type="body">
                  {{ item.booking_id }}
                </MTypography>
              </template>
              <template #status="{ item }">
                <div class="mchip-alignment td-full-width">
                  <MChip class="chip-width" :border-less="true" variant="error"
                    >{{ item.status }}
                  </MChip>
                </div>
              </template>
            </MDataTable>
          </template>
        </MAccordion>
      </AgDiv>
      <AgNotFound
        v-if="
          pendingPostingFlightInvoices.length === 0 &&
          pendingPostingFlightReceipts.length === 0 &&
          pendingPostingHotelInvoices.length === 0
        "
        test-id=""
        heading="No Pending Receipts & Invoices Found"
        description=""
      />
    </ag-card>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { SOA_DATE_FORMAT } from "@/modules/SOA/constants";
import GenerateSOA from "@/modules/SOA/components/GenerateSOA.vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import {
  MDataTable,
  MFinancialProfileCombobox,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import {
  PostingInvoice,
  PostingReceipts,
  HotelInvoices,
  sendSOAType,
} from "@/ag-portal-common/types";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import UTILS from "@/ag-portal-common/utils";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

const selectByOpts = {
  group: { label: "Group", value: "group" },
  individual: { label: "Individual", value: "individual" },
};
export default defineComponent({
  name: "SOA",
  components: { GenerateSOA, MFinancialProfileCombobox, MDataTable },
  data(): {
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    showSelectByDropdown: boolean;
    selectByOpts: {
      group: IObject;
      individual: IObject;
    };
    searchProfile: string;
    selectBy: string;
    financialProfilePublicId: string;
    selectedCity: string;
    filteredProfiles: IFinancialProfile[];
    selectedProfiles: string[];
    cities: string[];
    pendingPostingFlightReceipts: PostingReceipts[];
    pendingPostingFlightInvoices: PostingInvoice[];
    pendingPostingHotelInvoices: HotelInvoices[];
    flightReceiptsHeaders: MDataTableHeader[];
    flightInvoicesHeaders: MDataTableHeader[];
    hotelInvoicesHeaders: MDataTableHeader[];
    checkSelectAllCheckBox: boolean;
  } {
    return {
      ELEMENT_TYPES,
      checkSelectAllCheckBox: false,
      showSelectByDropdown: false,
      selectByOpts,
      searchProfile: "",
      selectBy: "",
      financialProfilePublicId: "",
      selectedCity: "",
      filteredProfiles: [],
      selectedProfiles: [],
      cities: [],
      pendingPostingFlightReceipts: [],
      pendingPostingFlightInvoices: [],
      pendingPostingHotelInvoices: [],
      hotelInvoicesHeaders: [
        {
          title: "Booking Id",
          value: "booking_id",
          key: "booking_id",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
      ],
      flightReceiptsHeaders: [
        {
          title: "Payment Id",
          value: "payment_id",
          key: "payment_id",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
      flightInvoicesHeaders: [
        {
          title: "Ticket Number",
          value: "ticket_no",
          key: "ticket_no",
          sortable: false,
          align: "center",
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
    };
  },
  async beforeMount() {
    if (!this.isAgentUser) {
      this.$store.dispatch("fetchFinancialProfiles");

      await this.$store.dispatch("fetchSectors");
      this.cities = this.$store.getters.sectors.map(
        (sector: ISector) => sector.city
      );
      this.showSelectByDropdown = true;
    } else {
      const organizationId = this.organization
        ? this.organization.organization_id
        : "";
      this.$store.dispatch("fetchOrganization", organizationId);
    }

    if (this.isAgentUser && this.organization) {
      let defaultFinancialProfile = this.organization.financial_profiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        await this.$store.dispatch("fetchPendingReceiptAndInvoice", {
          id: defaultFinancialProfile.public_id,
        });
        this.pendingPostingFlightInvoices = this.getPendingFlightInvoices();
        this.pendingPostingFlightReceipts = this.getPendingFlightReceipts();
        this.pendingPostingHotelInvoices = this.getPendingHotelInvoices();
      }
    }
  },
  computed: {
    isAgentUser(): boolean {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },

    isProfileSelected() {
      if (!this.isAgentUser) {
        if (
          this.selectBy === selectByOpts.group.value &&
          this.selectedProfiles.length
        ) {
          return true;
        } else if (
          this.selectBy === selectByOpts.individual.value &&
          this.financialProfilePublicId
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    isGroupSelected() {
      if (!this.isAgentUser) {
        if (
          this.selectBy === selectByOpts.group.value &&
          this.selectedProfiles.length
        ) {
          return true;
        } else if (
          this.selectBy === selectByOpts.individual.value &&
          this.financialProfilePublicId
        ) {
          return false;
        }
        return false;
      }
      return false;
    },
    financialProfileOptions(): FPComboboxOptions[] {
      const financialProfiles = this.$store.getters
        .financialProfiles as IFinancialProfile[];

      if (financialProfiles) {
        const fpComboboxOptions = financialProfiles?.map(
          (fp: IFinancialProfile) => {
            const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase() as
              | "pro"
              | "elite"
              | "smart";
            const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
            const status: string = fp?.status;

            const fpComboboxOption = {
              id: fp?.platform_id,
              label: fp?.financial_profile_name,
              value: fp?.public_id,
              isActive: status === ORGANIZATION_STATUSES.ACTIVE,
              status: status,
              sector: sector ?? "",
              type: planType,
            };

            return fpComboboxOption;
          }
        );

        return fpComboboxOptions;
      } else {
        return [];
      }
    },
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    getPendingPostingInvoices() {
      return this.$store.getters.getPendingPostingInvoices;
    },
  },
  methods: {
    getPendingHotelInvoices(): HotelInvoices[] {
      const invoices = this.getPendingPostingInvoices;
      return invoices.hotelInvoices;
    },
    getPendingFlightInvoices(): PostingInvoice[] {
      const invoices = this.getPendingPostingInvoices;
      return invoices.flightInvoices || [];
    },
    getPendingFlightReceipts(): PostingReceipts[] {
      const invoices = this.getPendingPostingInvoices;
      return invoices.flightReceipts || [];
    },
    genTestId,
    onSelectAll() {
      if (this.selectedProfiles.length === this.filteredProfiles.length) {
        this.selectedProfiles = [];
      } else {
        this.selectedProfiles = this.filteredProfiles.map(
          (profile: IFinancialProfile) => profile.public_id
        );
      }
    },
    filterProfileByGroup() {
      return this.$store.getters.financialProfiles.filter(
        (profile: IFinancialProfile) =>
          this.selectedCity &&
          profile?.sector?.toLowerCase()?.includes(this.selectedCity)
      );
    },
    async fetchSOA(payload: {
      startDate: Date;
      endDate: Date;
      financialProfilePublicId: string;
      sendEmail: boolean;
      sortBy: string;
    }) {
      let financialProfilePublicIds = [];
      let { startDate, endDate, financialProfilePublicId } = payload;

      let customerNameIfUserIsAgent = "";
      if (this.isAgentUser) {
        let financialProfileIfUserIsAgent =
          this.$store.getters.organization?.financial_profiles.find(
            (item: IFinancialProfile) =>
              item.public_id === financialProfilePublicId
          );
        financialProfilePublicIds.push(financialProfilePublicId);
        customerNameIfUserIsAgent =
          financialProfileIfUserIsAgent.financial_profile_name;
      } else {
        if (this.selectBy === this.selectByOpts.individual.value) {
          let financialProfile = this.$store.getters.financialProfiles.find(
            (profile: IFinancialProfile) =>
              profile.public_id === this.financialProfilePublicId
          );
          if (financialProfile) {
            financialProfilePublicIds.push(financialProfile.public_id);
          }
        } else {
          financialProfilePublicIds.push(...this.selectedProfiles);
        }
      }
      for (let financialProfilePublicId of financialProfilePublicIds) {
        let customer = this.$store.getters.financialProfiles.find(
          (profile: IFinancialProfile) =>
            profile.public_id === financialProfilePublicId
        );
        let formattedStartDate = format(startDate, SOA_DATE_FORMAT);
        let formattedEndDate = format(endDate, SOA_DATE_FORMAT);
        const fileInfo = {
          customerName:
            customer?.financial_profile_name || customerNameIfUserIsAgent,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        const params = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          send_email: payload.sendEmail,
          sort_by: payload.sortBy.toLowerCase(),
          financial_profile_public_id: financialProfilePublicId,
        };

        await this.$store.dispatch("fetchSOA", {
          params,
          fileInfo,
        });
      }
    },
    async downloadOldSOA(payload: sendSOAType) {
      let financialProfilePublicIds = [];
      let { startDate, endDate, financialProfilePublicId } = payload;

      let customerNameIfUserIsAgent = "";
      if (this.isAgentUser) {
        let financialProfileIfUserIsAgent =
          this.$store.getters.organization?.financial_profiles.find(
            (item: IFinancialProfile) =>
              item.public_id === financialProfilePublicId
          );
        financialProfilePublicIds.push(financialProfilePublicId);
        customerNameIfUserIsAgent =
          financialProfileIfUserIsAgent.financial_profile_name;
      } else {
        if (this.selectBy === this.selectByOpts.individual.value) {
          let financialProfile = this.$store.getters.financialProfiles.find(
            (profile: IFinancialProfile) =>
              profile.public_id === this.financialProfilePublicId
          );
          if (financialProfile) {
            financialProfilePublicIds.push(financialProfile.public_id);
          }
        } else {
          financialProfilePublicIds.push(...this.selectedProfiles);
        }
      }
      for (let financialProfilePublicId of financialProfilePublicIds) {
        let customer = this.$store.getters.financialProfiles.find(
          (profile: IFinancialProfile) =>
            profile.public_id === financialProfilePublicId
        );
        let formattedStartDate = format(startDate, SOA_DATE_FORMAT);
        let formattedEndDate = format(endDate, SOA_DATE_FORMAT);
        const fileInfo = {
          customerName:
            customer?.financial_profile_name || customerNameIfUserIsAgent,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        const params = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          send_email: payload.sendEmail,
          sort_by: payload.sortBy.toLowerCase(),
          financial_profile_public_id: financialProfilePublicId,
        };

        await this.$store.dispatch("sendSOAEmail", {
          params,
          fileInfo,
        });
      }
    },
    async sendSOAEmail(payload: sendSOAType) {
      let financialProfilePublicIds = [];
      let { startDate, endDate, financialProfilePublicId } = payload;

      let customerNameIfUserIsAgent = "";
      if (this.isAgentUser) {
        let financialProfileIfUserIsAgent =
          this.$store.getters.organization?.financial_profiles.find(
            (item: IFinancialProfile) =>
              item.public_id === financialProfilePublicId
          );
        financialProfilePublicIds.push(financialProfilePublicId);
        customerNameIfUserIsAgent =
          financialProfileIfUserIsAgent.financial_profile_name;
      } else {
        if (this.selectBy === this.selectByOpts.individual.value) {
          let financialProfile = this.$store.getters.financialProfiles.find(
            (profile: IFinancialProfile) =>
              profile.public_id === this.financialProfilePublicId
          );
          if (financialProfile) {
            financialProfilePublicIds.push(financialProfile.public_id);
          }
        } else {
          financialProfilePublicIds.push(...this.selectedProfiles);
        }
      }
      let formattedStartDate = format(startDate, SOA_DATE_FORMAT);
      let formattedEndDate = format(endDate, SOA_DATE_FORMAT);
      let customer = this.$store.getters.financialProfiles.find(
        (profile: IFinancialProfile) =>
          profile.public_id === financialProfilePublicId
      );
      const fileInfo = {
        customerName:
          customer?.financial_profile_name || customerNameIfUserIsAgent,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };
      const requestPayload = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        send_email: payload.sendEmail,
        sort_by: payload.sortBy.toLowerCase(),
        financial_profile_public_id: financialProfilePublicIds,
      };

      await this.$store.dispatch("sendSOAEmail", {
        requestPayload,
        fileInfo,
      });
    },
  },
  watch: {
    selectedCity() {
      this.filteredProfiles = this.filterProfileByGroup();
      this.selectedProfiles = this.filteredProfiles.map(
        (profile) => profile.public_id
      );
    },
    selectBy(value) {
      if (value === selectByOpts.individual.value) {
        this.selectedCity = "";
        this.selectedProfiles = [];
        this.filteredProfiles = [];
      } else if (value === selectByOpts.group.value) {
        this.financialProfilePublicId = "";
      }
    },
    searchProfile(value) {
      if (value) {
        this.filteredProfiles = this.filterProfileByGroup().filter(
          (profile: IFinancialProfile) => {
            return (
              profile.platform_id.includes(value) ||
              profile.financial_profile_name
                .toLowerCase()
                .includes(value.toLowerCase())
            );
          }
        );
      } else {
        this.filteredProfiles = this.filterProfileByGroup();
      }
    },
    selectedProfiles(value: string[]) {
      if (value.length === this.filteredProfiles.length) {
        this.checkSelectAllCheckBox = true;
      } else {
        this.checkSelectAllCheckBox = false;
      }
    },
  },
});
</script>

<style scoped lang="css">
.chip-width {
  max-width: max-content;
}
.postings {
  padding-left: 16px;
}
.soa-accordion {
  border: none;
  margin: 0%;
  padding: 0%;
}
</style>
