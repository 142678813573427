import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notification-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_snack_bar = _resolveComponent("ag-snack-bar")!
  const _component_NotificationPopup = _resolveComponent("NotificationPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.notificationStyle === _ctx.NOTIFICATION_STYLES.SNACKBAR)
        ? (_openBlock(), _createBlock(_component_ag_snack_bar, {
            key: 0,
            "is-open": _ctx.show,
            description: _ctx.description,
            type: _ctx.type,
            testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.SNACK_BAR, `${_ctx.type}-notification`),
            icon: "mdi-information",
            onClose: _ctx.disable
          }, null, 8, ["is-open", "description", "type", "testId", "onClose"]))
        : (_ctx.notificationStyle === _ctx.NOTIFICATION_STYLES.POPUP)
          ? (_openBlock(), _createBlock(_component_NotificationPopup, {
              key: 1,
              "show-modal": _ctx.show,
              title: _ctx.title,
              onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false)),
              description: _ctx.description,
              "action-button-text": _ctx.actionButtonText,
              onOnClickAction: _ctx.action
            }, null, 8, ["show-modal", "title", "description", "action-button-text", "onOnClickAction"]))
          : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}