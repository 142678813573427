<template>
  <my-bookings-filters-view
    v-show="showFilters"
    @fetchBookings="onUpdateFilter"
  />

  <div>
    <MDataTable
      :is-api-paginated="true"
      :total-item-count="totalPageCount"
      :headers="headers"
      :item-per-page="30"
      :data="bookings"
      :is-loading="$store.getters.isFetchingTourBookings"
      @onPageChange="onPageChange"
    >
      <template #bookingId="{ item }">
        <router-link
          :data-test-id="
            genTestId(ELEMENT_TYPES.LINK, `tour-booking-${item.bookingId}`)
          "
          :to="
            formatStringToRoutePath(PATH.FDG_BOOKING_DETAILS, {
              id: item.bookingId,
            })
          "
        >
          <MTypography class="description td-full-width booking-id" type="body">
            {{ item.bookingId }}
          </MTypography>
        </router-link>
      </template>
      <template #title="{ item }">
        <div class="title">
          <MTypography class="description td-full-width" type="label">
            {{ item.title }}
          </MTypography>
        </div>
      </template>
      <template #status="{ item }">
        <div class="status-container">
          <MChip :variant="bookingStatus(item.status)">{{ item.status }}</MChip>
        </div>
      </template>
      <template #status_remarks="{ item }">
        <div class="status-container">
          <MTypography
            class="description td-full-width"
            type="label"
            v-if="isPNRExpiryNotAvailable(item)"
          >
            PNR Expiry will be available soon
          </MTypography>
          <MTypography
            class="description td-full-width"
            type="label"
            v-else-if="isPNRExpired(item)"
          >
            Expired
          </MTypography>
          <div v-else-if="!checkStatus(item)" class="status_msg expires_in_msg">
            Expires in <br />
            <AgDuration
              :to="
                  getConfirmOrInitiateDate(item.status, item.initiatedBookingExpiresAt, item.confirmedBookingExpiresAt) as Date
                "
            />
          </div>
          <div v-else class="text-align-center">-</div>
        </div>
      </template>
      <template #bookingTime="{ item }">
        <MTypography class="description td-full-width" type="body">
          {{
            getFormattedDateTime(item.bookingTime, FORMAT_DD_MMM_YYYY_WITH_DASH)
          }}
        </MTypography>
        <MTypography class="description" type="label">
          {{ getFormattedDateTime(item.bookingTime, FORMAT_HH_SS_24_HOURS) }}
        </MTypography>
      </template>
      <template #travellers="{ item }">
        <MTypography
          class="description td-full-width"
          type="label"
          v-if="isInitiated(item?.status)"
        >
          -
        </MTypography>

        <div v-else>
          <AGButtonDropDown
            class="table_dropdown_wrap"
            text="Test"
            :items="item.travellers"
          >
            <template #label>
              <i>
                <MTypography class="description td-full-width" type="label">
                  {{ item.travellers[0] }}</MTypography
                >
              </i>
            </template>
            <AGDropDownButtonItem
              v-for="(dditem, index) in item.travellers"
              :key="index"
              :name="dditem"
            />
          </AGButtonDropDown>
        </div>
      </template>
      <template #startDate="{ item }">
        <MTypography class="description td-full-width" type="body">
          {{
            getFormattedDateTime(item.startDate, FORMAT_DD_MMM_YYYY_WITH_DASH)
          }}
        </MTypography>
      </template>
      <template #endDate="{ item }">
        <MTypography class="description td-full-width" type="body">
          {{ getFormattedDateTime(item.endDate, FORMAT_DD_MMM_YYYY_WITH_DASH) }}
        </MTypography>
      </template>
      <template #number_of_guest="{ item }">
        <MTypography class="description td-full-width" type="label">
          {{ item.guests }}
        </MTypography>
      </template>
      <template #tour_type="{ item }">
        <MTypography class="description td-full-width" type="label">
          {{ parse(item.tourType) }}
        </MTypography>
      </template>
      <template #price="{ item }">
        <div class="price-chip-container">
          <MChip :border-less="true" variant="success"
            >{{ item.currency }} {{ item.price }}</MChip
          >
        </div>
      </template>
    </MDataTable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  genTestId,
  getFormattedDateTime,
  isDateExpired,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import MyBookingsFiltersView from "@/modules/MyBookings/components/MyTourBookingsFilters.vue";
import { MDataTable, MChip } from "@aeroglobe/ag-core-ui";

import analyticsService from "@/analytic.service";
import { MY_BOOKING_ANALYTICS_EVENTS } from "@/modules/MyBookings/constants/anaylticsEvents";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { BOOKING_STATUSES_MAPPING } from "../constants";
import { IFlightBookings } from "@/interfaces/flightBookings.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { IHotelBookings } from "@/interfaces/hotelBookings.interface";

export default defineComponent({
  name: "TourBookings",
  components: { MyBookingsFiltersView, MDataTable, MChip },
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.fetchBookings(this.setItemsPerPage);
  },
  computed: {
    bookings(): {
      status_remarks: string;
      id: string;
      bookingId: string;
      guests: string[];
      bookingTime: string;
      status: string;
      checkIn: string;
      checkOut: string;
      initiatedBookingExpiresAt: Date;
      confirmedBookingExpiresAt: Date | null;
    }[] {
      const bookings = this.$store.getters.tourBookings
        .bookings as IHotelBookings[];

      return bookings.map((booking: IHotelBookings) => {
        return {
          ...booking,
          status_remarks: booking.status,
        };
      });
    },
    showFilters(): boolean {
      return this.isDashboardView === false;
    },
    totalPageCount(): number {
      return this.$store.getters.tourBookings.count;
    },
    isIssued(): string {
      return (
        BOOKING_STATUS.ISSUED.toUpperCase() ||
        BOOKING_STATUS.ISSUED_BUT_NOT_PAID.toUpperCase()
      );
    },
    PATH(): typeof PATH {
      return PATH;
    },
    isCanceled(): string {
      return BOOKING_STATUS.CANCELED.toUpperCase();
    },
  },
  methods: {
    genTestId,
    isDateExpired,
    formatStringToRoutePath,
    getFormattedDateTime,
    onUpdateFilter(filters: FetchBookingsParamsRequestModel) {
      this.fetchBookingParams = {
        ...filters,
        booking_type: this.fetchBookingParams.booking_type,
        page: 1,
      };
      this.fetchBookings();
    },
    parse(value: string) {
      if (!value) {
        return "-";
      }

      const processedValue = value.replace(/_/g, " ").trim();

      return processedValue;
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.flightBookings.next;
      let prevPage = this.$store.getters.flightBookings.previous;
      let currentPage: number = this.fetchBookingParams.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.fetchBookingParams.page = num;
        this.fetchBookings();
      }
    },
    setItemsPerPage() {
      let bookingsLength = this.$store.getters.tourBookings.bookings.length;
      this.itemsPerPage = bookingsLength;
    },
    getConfirmOrInitiateDate(
      status: BOOKING_STATUS,
      initiatedBookingExpiresAt: Date,
      confirmedBookingExpiresAt: null | Date
    ) {
      if (status === BOOKING_STATUS.CONFIRMED.toUpperCase())
        return confirmedBookingExpiresAt || NOTIFICATION_MESSAGES.INVALID_DATE;
      return initiatedBookingExpiresAt;
    },
    fetchBookings(callback?: () => void) {
      const payload = this.fetchBookingParams;

      let eventName = MY_BOOKING_ANALYTICS_EVENTS.TOURS_SEARCH;

      analyticsService.logActionEvent(eventName, payload);

      this.$store.dispatch("fetchTourBookings", {
        payload,
        callback,
      });
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_WITH_DASH);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_24_HOURS);
    },
    isInitiated(status: BOOKING_STATUS) {
      return status === BOOKING_STATUS.INITIATED.toUpperCase();
    },
    isPNRExpiryNotAvailable(item: IFlightBookings) {
      const isNotIssued = item.status !== this.isIssued;
      const isNotInitiated = !this.isInitiated(item.status as BOOKING_STATUS);
      const isNotCanceled = item.status !== this.isCanceled;
      return (
        item.confirmedBookingExpiresAt === null &&
        isNotIssued &&
        isNotInitiated &&
        isNotCanceled
      );
    },
    isPNRExpired(item: IFlightBookings) {
      if (BOOKING_STATUS.INITIATED.toUpperCase() === item.status) {
        return false;
      } else if (BOOKING_STATUS.CANCELED.toUpperCase() === item.status) {
        return true;
      }
      return (
        this.isDateExpired(
          this.getConfirmOrInitiateDate(
            item.status as BOOKING_STATUS,
            item.initiatedBookingExpiresAt,
            item.confirmedBookingExpiresAt
          ) as Date
        ) && item.status !== this.isIssued
      );
    },
    bookingStatus(status: BOOKING_STATUS): ChipVariant {
      return BOOKING_STATUSES_MAPPING[status] as ChipVariant;
    },
    checkStatus(item: IFlightBookings): boolean {
      return [
        BOOKING_STATUS.CANCELED.toUpperCase(),
        BOOKING_STATUS.ISSUED.toUpperCase(),
        BOOKING_STATUS.INITIATED.toUpperCase(),
      ].includes(item.status);
    },
  },
  data(): {
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    headers: MDataTableHeader[];
    NOTIFICATION_MESSAGES: IObject;
    fetchBookingParams: FetchBookingsParamsRequestModel;
    itemsPerPage: number;
    FORMAT_DD_MMM_YYYY_WITH_DASH: string;
    FORMAT_HH_SS_24_HOURS: string;
  } {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      itemsPerPage: 0,
      fetchBookingParams: {
        search: "",
        start: null,
        end: null,
        page: 1,
        booking_type: BOOKING_TYPES.TOUR,
      },
      NOTIFICATION_MESSAGES,
      ELEMENT_TYPES,
      headers: [
        {
          title: "Booking ID",
          value: "bookingId",
          key: "bookingId",
          sortable: false,
        },
        {
          title: "Title",
          value: "title",
          key: "title",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Status Remarks",
          value: "status_remarks",
          key: "status_remarks",
          sortable: false,
        },
        {
          title: "Tour Type",
          value: "tour_type",
          key: "tour_type",
          sortable: false,
        },
        {
          title: "Booking Time",
          value: "bookingTime",
          key: "bookingTime",
          sortable: false,
        },
        {
          title: "Start Time",
          value: "startDate",
          key: "startDate",
          sortable: false,
        },
        {
          title: "End Date",
          value: "endDate",
          key: "endDate",
          sortable: false,
        },
        {
          title: "Passengers",
          value: "travellers",
          key: "travellers",
          sortable: false,
        },
        {
          title: "Guests",
          value: "number_of_guest",
          key: "number_of_guest",
          sortable: false,
        },
        {
          title: "Price",
          value: "price",
          key: "price",
          sortable: false,
        },

        {
          title: "Agent",
          value: "agent",
          key: "agent",
          sortable: false,
        },
      ],
    };
  },
});
</script>

<style scoped>
.booking-id {
  color: var(--green-color);
}
.title {
  width: 150px;
  font-weight: 500;
  text-align: center;
}
.date-container {
  text-align: center;
}
.booking-time-container {
  text-align: center;
}
.status-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.status_msg {
  font-size: 12px;
  line-height: 1.4;
  display: inline;
  width: 110px;
  text-align: center;
  font-weight: 500;
}
.expired {
  font-weight: bold;
  color: red;
}
.expires_in_msg {
  color: #ff8600;
  width: 150px;
}
.guest-dropdown-container {
  display: flex;
  justify-content: center;
}
.price-chip-container {
  display: flex;
  justify-content: center;
  width: 100px;
}
.tour-type-container {
  width: 150px;
}
.width-150 {
  width: 150px;
}
.text-align-center {
  text-align: center;
}
.booking-time {
  margin-left: 5px;
}
</style>
