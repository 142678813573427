import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import FlightRefundService from "@/ag-flight-components/services/flightRefunds.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import {
  CalculateFlightRefundPNRPayload,
  FetchFlightRefundPNRPayload,
  CommitFlightRefundPNRPayload,
} from "@/ag-flight-components/interfaces/IFlightRefunds.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IFlightRefundState } from "@/ag-flight-components/interfaces/IFlightRefunds.interface";
import { StoreState } from "@/store/type";

const actions: ActionTree<IFlightRefundState, StoreState> = {
  // ****** Fetch Refund PNR ******
  async fetchRefundPNR(
    context: ActionContext<IFlightRefundState, StoreState>,
    params: FetchFlightRefundPNRPayload
  ) {
    const methodName = "actions.fetchRefundPNR";

    context.commit("resetPNRDetails");

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);

      context.commit("isPNRFetching", true);

      const flightRefundService = new FlightRefundService();
      const response = await flightRefundService.fetchRefundPNR(params);

      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data?.data
      ) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        context.commit("fetchedPNRDetail", response.data.data);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("isPNRFetching", false);
    }
  },
  async calculateRefundPNR(
    context: ActionContext<IFlightRefundState, StoreState>,
    params: CalculateFlightRefundPNRPayload
  ) {
    const methodName = "actions.calculateRefundPNR";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);

      context.commit("fetchingCalculateRefundPNR", true);

      const flightRefundService = new FlightRefundService();
      const response = await flightRefundService.calculateRefundPNR(params);

      if (
        response.success &&
        response.status === StatusCodes.OK &&
        response.data?.data
      ) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        context.commit("calculatePNRDetail", response.data.data);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("fetchingCalculateRefundPNR", false);
    }
  },
  async commitRefundPNR(
    context: ActionContext<IFlightRefundState, StoreState>,
    params: CommitFlightRefundPNRPayload
  ) {
    const methodName = "actions.commitRefundPNR";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);

      context.commit("fetchingCommitRefundPNR", true);

      const flightRefundService = new FlightRefundService();
      const response: IAGResponse = await flightRefundService.commitRefundPNR(
        params
      );

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        context.commit("commitPNRDetail", response.data.data);

        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Refunded Successfully";
        notificationService.triggerNotification();

        context.commit("resetRefundPNRDetails");
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("fetchingCommitRefundPNR", false);
    }
  },
};

export default actions;
