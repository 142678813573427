<template>
  <ag-modal :is-open="isOpen" @close="onCloseModal" modal-width="40%">
    <template #header>
      <v-card-title>Credit Limit Request Confirmation</v-card-title>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="onCloseModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <p>
        You have requested credit
        <span class="amount">{{ formattedAmountRequested }}</span>
      </p>
      <p>
        <span class="amount">{{ formattedAmountFee }}</span> processing fee will
        be applied.
      </p>

      <hr />
      <div class="action-btn-container">
        <a-g-button variant="danger" @click="onCloseModal">Cancel</a-g-button>
        <span>&nbsp;&nbsp;</span>
        <a-g-button
          :is-loading="$store.getters.isCreatingCreditLimitRequest"
          @click="onConfirm"
          >Confirm</a-g-button
        >
      </div>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";

export default defineComponent({
  name: "CreditLimitConfirmationModal",
  events: ["onCloseModal", "onConfirm"],
  props: {
    isOpen: { type: Boolean, required: true },
    amountRequested: { type: Number, required: true },
    amountFee: { type: Number, required: true },
  },
  methods: {
    formattedAmount(amount: number) {
      if (amount) {
        const currency = "PKR";
        const formattedAmount = getCurrencyFormatter(currency).format(amount);
        return formattedAmount;
      }
      return "0";
    },
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onConfirm() {
      this.$emit("onConfirm");
    },
  },
  computed: {
    formattedAmountRequested(): string {
      return this.formattedAmount(this.amountRequested);
    },
    formattedAmountFee(): string {
      return this.formattedAmount(this.amountFee);
    },
  },
});
</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
}

.action-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
