<template>
  <MCard class="search-bar-card">
    <AgRow>
      <AgColumn sm="12" md="8" lg="8" cols="12">
        <MFinancialProfileCombobox
          class="fp-combo"
          v-model:inputValue="financialProfileInput"
          label="Financial Profiles"
          itemValue="value"
          itemLabel="label"
          :disabled="isFinancialProfileFetching"
          :options="financialProfileOptions"
          :hasError="hasError"
          :errorMessage="errorMessage"
        />
      </AgColumn>

      <AgColumn sm="12" md="4" lg="4" cols="12">
        <div class="btn-container">
          <MButton
            @click="onFetchAgPricing"
            class="get-price-detail-btn"
            :disabled="getPriceDetailsButtonDisabled"
          >
            Get Pricing
          </MButton>
          <MButton type="outlined" @click="handleClear"> Clear </MButton>
        </div>
      </AgColumn>
    </AgRow>
  </MCard>

  <MDataTable
    :headers="tableHeaders"
    :is-loading="isLoading"
    :data="pricings || []"
    :item-per-page="100"
    :has-search="true"
    v-if="showTable"
  >
    <template #sales_channel="{ item }">
      <div>
        <MTypography class="description td-full-width" type="body">
          {{ item.sales_channel }}
        </MTypography>
      </div>
    </template>
    <template #airline="{ item }">
      <MTypography class="description td-full-width" type="label">
        {{ item.airline }}
      </MTypography>
    </template>
    <template #pricing="{ item }">
      <div class="table-column-width pricing-chip-container">
        <MChip
          :border-less="true"
          class="pricing-chip"
          :variant="renderChipVariant(item.pricing)"
        >
          {{ item.pricing }}
        </MChip>
      </div>
    </template>
    <template #apply_on_gross_fare="{ item }">
      <RoundCheckCrossIcon :is-check="item.apply_on_gross_fare" />
    </template>
    <template #sale_on_and_after="{ item }">
      <MTypography class="description td-full-width" type="label">
        {{ getFormattedDates(item.sale_on_and_after) }}
      </MTypography>
    </template>
    <template #sale_on_and_before="{ item }">
      <MTypography class="description td-full-width" type="label">
        {{ getFormattedDates(item.sale_on_and_before) }}
      </MTypography>
    </template>
    <template #travel_on_and_after="{ item }">
      <MTypography class="description td-full-width" type="label">
        {{ getFormattedDates(item.travel_on_and_after) }}
      </MTypography>
    </template>
    <template #travel_on_and_before="{ item }">
      <MTypography class="description td-full-width" type="label">
        {{ getFormattedDates(item.travel_on_and_before) }}
      </MTypography>
    </template>
    <template #remarks="{ item }">
      <MTypography class="description td-full-width" type="label">
        {{ getRemarks(item.remarks) }}
      </MTypography>
    </template>
    <template #rbds="{ item }">
      <MTypography class="description td-full-width" type="label">
        {{ displayRbds(item.rbds) }}
      </MTypography>
    </template>
  </MDataTable>
</template>

<script lang="ts">
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { defineComponent } from "vue";

import analyticsService from "@/analytic.service";
import AgPricingService from "@/modules/AgPricing/services/agPricing.service";
import notificationService from "@/ag-portal-common/services/notification.service";

import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { AG_PRICING_ANALYTICS_EVENTS } from "@/modules/AgPricing/constants/anaylticsEvents";
import RoundCheckCrossIcon from "@/modules/Organization/components/OrganizationDetails/CheckCrossIconComponent.vue";
import {
  MFinancialProfileCombobox,
  MButton,
  MDataTable,
  MChip,
  MCard,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import UTILS from "@/ag-portal-common/utils";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { FinancialProfile } from "@/modules/Auth/types";
import { MDataTableHeader } from "@/ag-portal-common/types";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "AgentPricing",
  components: {
    MFinancialProfileCombobox,
    MButton,
    MChip,
    MDataTable,
    MCard,
    MTypography,
    RoundCheckCrossIcon,
  },
  data() {
    return {
      ELEMENT_TYPES,
      financialProfileInput: "",
      disableButtons: false,
      isLoading: false,
      pricings: [],
      hasError: false,
      errorMessage: "",
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      validateFinancialProfileErrors: false,
      tableHeaders: [
        {
          title: "Sales Channel",
          value: "sales_channel",
          key: "sales_channel",
          sortable: true,
        },
        {
          title: "Airline",
          value: "airline",
          key: "airline",
          sortable: true,
        },
        {
          title: "Pricing",
          value: "pricing",
          key: "pricing",
          sortable: true,
        },
        {
          title: "Region",
          value: "region",
          key: "region",
          sortable: true,
        },
        {
          title: "Apply on gross fare",
          value: "apply_on_gross_fare",
          key: "apply_on_gross_fare",
          sortable: true,
        },
        {
          title: "Rbds",
          value: "rbds",
          key: "rbds",
          sortable: true,
        },
        {
          title: "Sale on and after",
          value: "sale_on_and_after",
          key: "sale_on_and_after",
          sortable: true,
        },
        {
          title: "Sale on and before",
          value: "sale_on_and_before",
          key: "sale_on_and_before",
          sortable: true,
        },
        {
          title: "Travel on and after",
          value: "travel_on_and_after",
          key: "travel_on_and_after",
          sortable: true,
        },
        {
          title: "Travel on and before",
          value: "travel_on_and_before",
          key: "travel_on_and_before",
          sortable: true,
        },
        {
          title: "Remarks",
          value: "remarks",
          key: "remarks",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  async created() {
    await this.$store.dispatch("fetchFinancialProfiles");
  },
  async mounted() {
    if (!this.isSuperUserOrOperationUserOrSalesUser && this.financialProfiles) {
      let defaultFinancialProfile = this.financialProfiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.financialProfileInput = defaultFinancialProfile.public_id;
      }
    }
  },
  computed: {
    userRole(): USER_ROLES | null {
      return this.$store.getters[AUTH_GETTERS.USER_ROLE];
    },
    financialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },

    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    financialProfileOptions(): FPComboboxOptions[] {
      let financialProfiles;
      if (this.isSuperUserOrOperationUserOrSalesUser) {
        financialProfiles = this.$store.getters.financialProfiles;
      } else {
        if (this.financialProfiles) {
          financialProfiles = this.financialProfiles;
        }
      }

      return financialProfiles?.map((fp: IFinancialProfile) => {
        const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
        const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
        return {
          id: fp?.platform_id,
          label: fp?.financial_profile_name,
          value: fp?.public_id,
          isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
          status: fp?.status,
          sector: sector,
          type: planType,
        };
      });
    },
    getPriceDetailsButtonDisabled(): boolean {
      return (
        this.isFinancialProfileFetching || this.financialProfileInput === ""
      );
    },
    resultNotFound(): boolean {
      return this.isLoading === false && this.pricings.length < 1;
    },
    showTable(): boolean {
      return this.isLoading || this.pricings.length > 0;
    },
    isSuperUserOrOperationUserOrSalesUser(): boolean {
      return UTILS.isUser(
        USER_ROLES.AG_SUPER_USER,
        USER_ROLES.OPERATIONS,
        USER_ROLES.SALES
      );
    },
  },
  methods: {
    genTestId,
    getFormattedDateTime,
    getRemarks(remarks: string) {
      return remarks ? remarks : "-";
    },
    getFormattedDates(date: Date) {
      return date
        ? this.getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_WITH_DASH)
        : "-";
    },
    displayRbds(rbds: []): string {
      if (rbds.length === 0) {
        return "-";
      } else {
        return rbds.join(" ").toUpperCase();
      }
    },
    async onFetchAgPricing(): Promise<void> {
      this.pricings = [];
      this.isLoading = true;

      this.trackAnalytics();

      try {
        const service = new AgPricingService();
        const response = await service.get(this.financialProfileInput);
        this.pricings = response.data;
        this.isLoading = false;
      } catch {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Error while fetching ag-pricing";
        notificationService.triggerNotification();
      }
    },

    canFetch(): boolean {
      return this.financialProfileInput !== "";
    },
    trackAnalytics(): void {
      const payload = {
        "user-role": this.userRole,
        "financial-profile-id": this.financialProfileInput,
      };
      analyticsService.logActionEvent(
        AG_PRICING_ANALYTICS_EVENTS.FETCH_AG_PRICING,
        payload
      );
    },
    handleClear() {
      this.financialProfileInput = "";
      this.pricings = [];
      this.isLoading = false;
    },
    renderChipVariant(pricing: string): ChipVariant {
      return pricing.startsWith("-") ? "success" : "warning";
    },
    handleFinancialProfileErrors() {
      if (this.validateFinancialProfileErrors) {
        if (this.financialProfileInput === "") {
          this.hasError = true;
          this.errorMessage = "Please select financial profile";
        } else {
          this.hasError = false;
          this.errorMessage = "";
        }
      }
    },
  },
  watch: {
    financialProfileInput() {
      localStorage.setItem("fp", this.financialProfileInput);
    },
  },
});
</script>

<style lang="scss" scoped>
.table-column-width {
  min-width: 150px;
  text-align: center;
}
.search-bar-card {
  margin-bottom: 15px;
}
.pricing-chip {
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.btn-container {
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.fp-combo {
  margin-bottom: 0px !important;
}
.pricing-chip-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
