<template>
  <ag-modal persistent :is-open="isOpen" modal-width="400px">
    <template #body>
      <CancellationDialogBody
        v-if="showCancellation"
        :handle-continue="handleContinue"
        :handle-close-dialog="handleCloseIssuanceDialog"
      />
      <OtpDialogBody v-else @handleCloseDialog="handleCloseIssuanceDialog" />
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CancellationDialogBody from "../components/HotelIssuanceDialog/CancellationDialogBody.vue";
import OtpDialogBody from "../components/HotelIssuanceDialog/OtpDialogBody.vue";
export default defineComponent({
  name: "HotelIssuanceDialog",
  components: {
    CancellationDialogBody,
    OtpDialogBody,
  },
  data() {
    return {
      showCancellation: true,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    handleCloseDialog: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleContinue() {
      this.showCancellation = false;
    },
    handleCloseIssuanceDialog() {
      this.showCancellation = true;
      this.handleCloseDialog();
    },
  },
  beforeUnmount() {
    this.showCancellation = false;
  },
});
</script>
