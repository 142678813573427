import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41332cc7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "up-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_AgPhoneField = _resolveComponent("AgPhoneField")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, { class: "form-container" }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createVNode(_component_ag_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTextfield, {
                  inputValue: _ctx.name,
                  "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  label: "Name",
                  hasError: !!_ctx.errors?.name,
                  errorMessage: _ctx.errors?.name
                }, null, 8, ["inputValue", "hasError", "errorMessage"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MCombobox, {
                  inputValue: _ctx.selectedSector,
                  "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSector) = $event)),
                  label: "Sector Name",
                  itemValue: "value",
                  itemLabel: "label",
                  options: _ctx.sectors,
                  hasError: !!_ctx.errors?.sector,
                  errorMessage: _ctx.errors?.sector,
                  disabled: _ctx.isUpdateOrganization
                }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MCombobox, {
                  inputValue: _ctx.pia_provider,
                  "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pia_provider) = $event)),
                  label: "PIA Provider",
                  itemValue: "value",
                  itemLabel: "label",
                  options: _ctx.piaProviders,
                  hasError: !!_ctx.errors?.pia_provider,
                  errorMessage: _ctx.errors?.pia_provider
                }, null, 8, ["inputValue", "options", "hasError", "errorMessage"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTextfield, {
                  inputValue: _ctx.contact_person_email,
                  "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contact_person_email) = $event)),
                  type: "email",
                  label: "Email",
                  hasError: !!_ctx.errors?.contact_person_email,
                  errorMessage: _ctx.errors?.contact_person_email
                }, null, 8, ["inputValue", "hasError", "errorMessage"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_AgPhoneField, {
                    class: "up-text-field up-phone-field",
                    "test-id": "",
                    "default-value": _ctx.contact_person_phone,
                    error: _ctx.errors?.contact_person_phone,
                    onUpdateValue: _ctx.onParseWhatsAppNumberHandler
                  }, null, 8, ["default-value", "error", "onUpdateValue"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MCombobox, {
                  inputValue: _ctx.selectedCurrency,
                  "onUpdate:inputValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
                  label: "Currency",
                  itemValue: "value",
                  itemLabel: "label",
                  options: _ctx.currency,
                  hasError: !!_ctx.errors?.currency,
                  errorMessage: _ctx.errors?.currency,
                  disabled: _ctx.isUpdateOrganization
                }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_MCombobox, {
                  inputValue: _ctx.selectedRelatedPerson,
                  "onUpdate:inputValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedRelatedPerson) = $event)),
                  label: "Related Person",
                  itemValue: "value",
                  itemLabel: "label",
                  options: _ctx.relatedPersons,
                  hasError: !!_ctx.errors?.relatedPerson,
                  errorMessage: _ctx.errors?.relatedPerson
                }, null, 8, ["inputValue", "options", "hasError", "errorMessage"]), [
                  [_vShow, _ctx.selectedSector]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_MButton, {
          behaviour: "submit",
          class: "update-btn",
          disabled: _ctx.onLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isCreateOrganization ? "Create" : "Update"), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ], 32)
    ]),
    _: 1
  }))
}