<template>
  <ag-modal
    persistent
    :is-open="open"
    class="flight_details_modal"
    modal-width="1170"
  >
    <template #header>
      <v-card-title>Your Flights</v-card-title>

      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="handleDialogClose"
        >Close</a-g-button
      >
    </template>
    <template #body>
      <AgDiv class="complete_flight_details">
        <AgRow test-id="">
          <AgColumn test-id="" sm="3" md="3" lg="3">
            <AgIconBox
              test-id=""
              title="Departure"
              :sub-title="renderDepartureDate(flightItem.departure_time)"
              icon="departureIcon"
            ></AgIconBox>
          </AgColumn>
          <AgColumn test-id="" sm="9" md="9" lg="9">
            <AgDiv class="inner_left_border">
              <AgFlightDetailCard
                test-id=""
                :airline-logo="flightItem.airline?.logo"
                :airline-code="flightItem.flight_numbers[0]"
                :duration="legDuration(flightItem!.duration)"
                :from-destination="currentJourneyLeg?.origin?.iata_code"
                :from-time="formatTime(flightItem.departure_time)"
                :to-time="formatTime(flightItem.arrival_time)"
                :to-destination="currentJourneyLeg?.destination?.iata_code"
                :airline-name="flightItem.name"
                fare-info="true"
              />
              <hr />
              <AgHeading
                class="flight_info_green_box"
                :title="renderDepartureDate(flightItem.departure_time)"
                variant="h4"
                test-id=""
              />
              <a-g-button type="button">
                {{
                  formatCurrency(
                    fareOption?.price.ag_gross_fare?.value
                      ? fareOption?.price.ag_gross_fare?.value
                      : (fareOption?.price.gross_fare?.value as number),
                    fareOption?.price.ag_gross_fare?.currency
                      ? fareOption?.price.ag_gross_fare?.currency
                      : (fareOption?.price.gross_fare?.currency as string)
                  )
                }}
              </a-g-button>
              <FlightRoute
                v-for="(segment,index) in fareOption!.segments"
                :next-departure-time="getNextDepartureTime(index)"
                :show-lay-off="index + 1 < fareOption!.segments.length"
                :key="index"
                :segment="segment"
              />
            </AgDiv>
          </AgColumn>
        </AgRow>
      </AgDiv>

      <AgDiv class="margin_top_20 margin_bottom_20">
        <a-g-button type="button" @click="handleDialogClose" variant="link"
          ><strong>Change Flight</strong></a-g-button
        >
      </AgDiv>
    </template>
    <template #footer>
      <AgSelectedFlightInfo
        class="d-flex align-items-center"
        :destination="flightItem.airline?.name"
        :date="renderTripDates"
        :adult="$store.state.flightModule.adult_count"
        :children="$store.state.flightModule.child_count"
        :infant="$store.state.flightModule.infant_count"
      >
      </AgSelectedFlightInfo>
      <a-g-button
        type="button"
        :is-loading="$store.state.flightModule.isLoading"
        @click="(e:MouseEvent) => handleSelectFare(e)"
      >
        Continue Booking</a-g-button
      >
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { FareOption, FlightOption } from "../../types/Flights";
import { TimeUtility } from "../../utils/TimeUtility";
import { formatTripDates } from "../../utils";
import FlightRoute from "./FlightRoute.vue";
import { formatStringToRoutePath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import analyticsService from "@/analytic.service";
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents";
import { getCurrencyFormatter } from "../../utils";
import { JourneyLeg } from "@/ag-flight-components/types";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "FareBookingDialog",
  components: {
    FlightRoute,
  },
  data() {
    return {
      open: this.isOpen,
    };
  },
  props: {
    flightItem: {
      type: Object as PropType<FlightOption>,
      default: () => ({}),
    },
    fareOption: {
      type: Object as PropType<FareOption>,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  computed: {
    renderTripDates(): string {
      const { route_type, trips } = this.$route.query;
      return formatTripDates(trips as string, route_type as string);
    },
    currentJourneyLeg(): JourneyLeg {
      return this.$store.getters.currentJourneyLeg;
    },
    isSuperUserOrOperationUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS);
    },
  },
  watch: {
    isOpen: {
      handler(newVal) {
        this.open = newVal;
      },
    },
    selectedFlightLegs: {
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      deep: true,
    },
  },
  methods: {
    formatCurrency(amount: number, currency_format: string): string {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    handleDialogClose(): void {
      this.open = false;
      this.$emit("handleDialogClose");
    },
    formatTime(time: string): string {
      return TimeUtility.parsedFlightTimeorDate(time ?? "");
    },
    legDuration(durationInNumber: number): string {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    renderDepartureDate: (departure_date: string) => {
      return TimeUtility.parsedFlightTimeorDate(
        departure_date ?? "",
        "EEEE, MMMM dd yyyy"
      );
    },
    getNextDepartureTime(index: number): Date {
      return index + 1 < this.fareOption!.segments.length
        ? this.fareOption!.segments[index + 1].departure_datetime
        : new Date();
    },
    handleSuccessCallBack(id: string): void {
      const { org_id } = this.$route.query;

      this.handleDialogClose();
      const path = formatStringToRoutePath(PATH.FLIGHTS_BOOKING_FORM, { id });
      const updatedPath = path + (org_id ? `?org_id=${org_id}` : "");
      this.$router.push(updatedPath);
    },
    handleSelectFare(e: MouseEvent): void {
      e.stopPropagation();
      const journeyLeg = this.$store.getters.currentJourneyLeg;
      const { currentLegIndex, lastLegIndex } = this.$store.state.flightModule;
      const { fp } = this.$route.query;

      if (currentLegIndex < lastLegIndex) {
        const payload = {
          ...this.flightItem,
          origin: journeyLeg.origin,
          destination: journeyLeg.destination,
        };
        this.$store.commit("selectFlightLeg", payload);
        this.$store.commit("selectFlightFare", this.fareOption);
        this.handleDialogClose();
      } else {
        const payload = {
          pre_booking_tokens: [this.fareOption?.pre_booking_token],
          ...(this.isSuperUserOrOperationUser &&
            fp && {
              financial_profile_id: fp,
            }),
        };

        const analyticsPayload = {
          from: this.currentJourneyLeg?.origin?.iata_code,
          to: this.currentJourneyLeg?.destination?.iata_code,
          "departure-date": this.renderDepartureDate(
            this.flightItem.departure_time
          ),
          "departure-time": this.formatTime(this.flightItem.departure_time),
          "return-time": this.formatTime(this.flightItem.arrival_time as any),
          "airline-name": this.flightItem.name,
          "airline-code": this.flightItem.flight_numbers[0],
          price: this.formatCurrency(
            this.fareOption?.price.ag_gross_fare?.value
              ? this.fareOption?.price.ag_gross_fare?.value
              : this.fareOption?.price.gross_fare?.value,
            this.fareOption?.price.ag_gross_fare?.currency
              ? this.fareOption?.price.ag_gross_fare?.currency
              : this.fareOption?.price.gross_fare?.currency
          ),
          "adult-traveler-count": this.$store.state.flightModule.adult_count,
          "children-traveler-count": this.$store.state.flightModule.child_count,
          "infant-traveler-count": this.$store.state.flightModule.infant_count,
        };

        analyticsService.logActionEvent(
          FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOKING_CONFIRMED,
          analyticsPayload
        );

        this.$store.dispatch("initiateBooking", {
          payload,
          successCallback: this.handleSuccessCallBack,
        });
      }
    },
  },
});
</script>
