import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83d9c28a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_ShimmerCard = _resolveComponent("ShimmerCard")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MSnapshotItemCountTile = _resolveComponent("MSnapshotItemCountTile")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_SnapshotDetailAccordion = _resolveComponent("SnapshotDetailAccordion")!
  const _component_ag_color_chip = _resolveComponent("ag-color-chip")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "sector-snapshot-header" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MCombobox, {
          inputValue: _ctx.sector,
          "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sector) = $event)),
          label: "Select Sector",
          itemValue: "value",
          itemLabel: "label",
          options: _ctx.sectors,
          disabled: _ctx.$store.getters.isFetchingSectors
        }, null, 8, ["inputValue", "options", "disabled"])
      ]),
      (_ctx.$store.getters.isSectorStatsLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "200px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "150px" }),
            _createVNode(_component_ShimmerCard, { "card-height": "100px" })
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.showDetails)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_SnapshotDetailAccordion, { title: "General Stats" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Enabled Websites",
                            value: _ctx.sectorStats?.aggregates?.enabled_website_count
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-website-management",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Disabled Websites",
                            value: _ctx.sectorStats?.aggregates?.disabled_website_count
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-website-management",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Logo Added",
                            value: _ctx.sectorStats?.aggregates?.logo_added_count
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-logo-icon",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Color Added",
                            value: _ctx.sectorStats?.aggregates?.color_added_count
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-color-icon",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Default Color",
                            value: _ctx.sectorStats?.aggregates?.default_color_count
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-default-color-fill",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Default Logo",
                            value: _ctx.sectorStats?.aggregates?.default_logo_count
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-default-logo-icon",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Users" }, {
                    overview: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Owners",
                            value: _ctx.sectorStats?.users?.owners
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-my-team",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Employees",
                            value: _ctx.sectorStats?.users?.employees
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-my-team",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        lg: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MSnapshotItemCountTile, {
                            title: "Sub Agents",
                            value: _ctx.sectorStats?.users?.sub_agents
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_MIcon, {
                                class: "details-type-icon icon white-icon",
                                name: "m-my-team",
                                width: "24",
                                height: "24"
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_SnapshotDetailAccordion, { title: "Organizations" }, {
                    "accordion-content": _withCtx(() => [
                      (_ctx.showOrganizationTable)
                        ? (_openBlock(), _createBlock(_component_MDataTable, {
                            key: 0,
                            headers: _ctx.headers,
                            data: _ctx.sectorOrganizations,
                            "items-per-page": 10,
                            simpleDataTable: true
                          }, {
                            logo: _withCtx(({ item }) => [
                              _createElementVNode("div", null, [
                                _createElementVNode("img", {
                                  width: "60",
                                  src: item.logo
                                }, null, 8, _hoisted_2)
                              ])
                            ]),
                            org_color: _withCtx(({ item }) => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_ag_color_chip, {
                                  "color-name": item.org_color,
                                  "color-code": item.org_color
                                }, null, 8, ["color-name", "color-code"])
                              ])
                            ]),
                            name: _withCtx(({ item }) => [
                              _createElementVNode("div", {
                                onClick: ($event: any) => (_ctx.handleRowClick($event, item))
                              }, [
                                _createVNode(_component_MTypography, {
                                  class: "description organization-link",
                                  type: "label"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ], 8, _hoisted_3)
                            ]),
                            domain_name: _withCtx(({ item }) => [
                              (item.domain_name)
                                ? (_openBlock(), _createBlock(_component_MTypography, {
                                    key: 0,
                                    class: "description td-full-width",
                                    type: "label"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.domain_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_openBlock(), _createBlock(_component_MTypography, {
                                    key: 1,
                                    class: "description td-full-width",
                                    type: "label"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" N/A ")
                                    ]),
                                    _: 1
                                  }))
                            ]),
                            display_name: _withCtx(({ item }) => [
                              (item.display_name)
                                ? (_openBlock(), _createBlock(_component_MTypography, {
                                    key: 0,
                                    class: "description td-full-width",
                                    type: "label"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.display_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_openBlock(), _createBlock(_component_MTypography, {
                                    key: 1,
                                    class: "description td-full-width",
                                    type: "label"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" N/A ")
                                    ]),
                                    _: 1
                                  }))
                            ]),
                            _: 1
                          }, 8, ["headers", "data"]))
                        : (_openBlock(), _createBlock(_component_ResultNotFound, {
                            key: 1,
                            "test-id": "",
                            title: "Organizations not found"
                          }))
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.showResultNotFound)
              ? (_openBlock(), _createBlock(_component_ResultNotFound, {
                  key: 1,
                  "test-id": "",
                  title: "Sector Details Not Found"
                }))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _: 1
  }))
}