<template>
  <ag-modal scrollable :is-open="isOpen" @close="closeModal" modal-width="40%">
    <template #header>
      <v-card-title>{{ isEdit ? "Edit" : "Add" }} Passenger</v-card-title>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <form @submit="onSubmit">
        <ag-row>
          <ag-column sm="12" md="6" cols="6">
            <m-combobox
              v-model:inputValue="passenger_type"
              :hasError="errors?.passenger_type"
              :errorMessage="errors?.passenger_type"
              label="Passenger Type"
              itemValue="value"
              itemLabel="label"
              :options="passenger_types"
            />
          </ag-column>
          <ag-column sm="12" md="6" cols="6">
            <m-combobox
              v-model:inputValue="title"
              :hasError="errors?.title"
              :errorMessage="errors?.title"
              label="Passenger Type"
              itemValue="value"
              itemLabel="label"
              :options="renderTitleData"
            />
          </ag-column>

          <ag-column sm="12" md="6" cols="6">
            <m-textfield
              v-model:inputValue.trim="first_name"
              label="First Name and Middle Name (if any)"
              :hasError="errors?.first_name"
              :errorMessage="errors?.first_name"
            />
          </ag-column>

          <ag-column sm="12" md="6" cols="6">
            <m-textfield
              v-model:inputValue.trim="last_name"
              label="Last Name"
              :hasError="errors?.last_name"
              :errorMessage="errors?.last_name"
            />
          </ag-column>

          <ag-column sm="12" md="6" cols="6">
            <m-combobox
              v-model:inputValue="nationality"
              :hasError="errors?.nationality"
              :errorMessage="errors?.nationality"
              label="Nationality"
              itemValue="value"
              itemLabel="label"
              :options="nationalities"
            />
          </ag-column>
          <ag-column sm="12" md="6" cols="6">
            <m-textfield
              v-model:inputValue.trim="cnic"
              label="CNIC Number"
              :hasError="errors?.cnic"
              :errorMessage="errors?.cnic"
            />
          </ag-column>

          <ag-column sm="12" md="6" cols="6">
            <m-combobox
              v-model:inputValue="gender"
              :hasError="errors?.gender"
              :errorMessage="errors?.gender"
              label="Gender"
              itemValue="value"
              itemLabel="label"
              :options="genders"
            />
          </ag-column>

          <ag-column sm="12" md="6" cols="6">
            <m-textfield
              v-model:inputValue.trim="passport_number"
              label="Passport Number"
              :hasError="errors?.passport_number"
              :errorMessage="errors?.passport_number"
            />
          </ag-column>

          <ag-column sm="12" md="12" cols="12">
            <ag-calendar
              label="Passport Expiry"
              @update:passport_expiry="(value:any) => passport_expiry = value"
              calendar-name="passport_expiry"
              :date="passport_expiry"
              :min-date="currentDate"
              :error="errors?.passport_expiry"
            />
          </ag-column>

          <ag-column sm="12" md="12" cols="12">
            <ag-calendar
              label="Date of Birth"
              @update:dob="(value:any) => dob = value"
              calendar-name="dob"
              :min-date="disabledDates.to"
              :max-date="disabledDates.from"
              :date="dob ? dob : disabledDates.from"
              :year-range="disabledDates.yearsToShow"
              :error="errors?.dob"
            />
          </ag-column>
        </ag-row>
        <AGButton
          test-id=""
          :is-loading="isLoading"
          type="submit"
          style="float: right"
          >{{ isEdit ? "Save" : "Add" }}</AGButton
        >
      </form>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ValidationError } from "yup";
import {
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import {
  PAX_TITLES,
  PAX_TYPES as passenger_types,
} from "@/ag-portal-common/constants/paxTitles";
import { genders } from "@/ag-portal-common/constants/genders";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { addMonths, addYears } from "date-fns";
import Nationalities from "@/ag-portal-common/constants/nationalities";
import { addPassengerValidation } from "@/modules/Organization/validations/addPassenger.validation";
import { PASSENGER_TYPE } from "@/ag-portal-common/enums/PASSENGER_TYPES";
import analyticsService from "@/analytic.service";
import { ORGANIZATION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";

export default defineComponent({
  name: "AddPassengerModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    passenger: {
      type: (Object as PropType<IPassenger>) || null,
    },
  },
  data(): {
    cnic: string | null;
    passport_number: string | null;
    passport_expiry: Date | null;
    dob: Date | null;
    first_name: string;
    last_name: string;
    gender: string;
    nationality: string;
    passenger_type: string;
    title: string;
    ELEMENT_TYPES: IObject;
    genders: IObject[];
    nationalities: IObject[];
    passenger_types: IObject[];
    errors: any;
  } {
    return {
      cnic: "",
      passport_number: "",
      passport_expiry: new Date(),
      dob: new Date(),
      first_name: "",
      last_name: "",
      gender: "",
      nationality: "PK",
      passenger_type: PASSENGER_TYPE.ADULT,
      title: "",
      ELEMENT_TYPES,
      nationalities: Nationalities,
      genders,
      passenger_types,
      errors: {},
    };
  },
  computed: {
    renderTitleData(): {
      label: string;
      value: string;
      type: PASSENGER_TYPE;
    }[] {
      return PAX_TITLES.filter(
        (x) => x.type === (this.passenger_type as string)
      );
    },
    disabledDates(): {
      to: Date | null;
      from: Date;
      yearsToShow: string[];
    } {
      const currentDate = new Date();
      let minDate = null;
      let maxDate = new Date();
      let yearsToShow: Array<string> = [];

      const passengerType = this.passenger_type;

      switch (passengerType) {
        case PASSENGER_TYPE.ADULT:
          minDate = addYears(currentDate, -100);
          maxDate = addYears(currentDate, -12);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
          break;
        case PASSENGER_TYPE.CHILD:
          minDate = addYears(currentDate, -12);
          maxDate = addYears(currentDate, -2);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];

          break;
        case PASSENGER_TYPE.INFANT:
          minDate = addMonths(currentDate, -24);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
          break;
      }

      return {
        to: minDate,
        from: maxDate,
        yearsToShow: yearsToShow,
      };
    },
    currentDate(): Date {
      return new Date();
    },
    isLoading(): boolean {
      return this.$store.getters.passengerActionLoading;
    },
  },
  methods: {
    genTestId,
    closeModal() {
      this.$emit("closeModal");
      this.clearState();
    },
    clearState() {
      this.cnic = "";
      this.passport_number = "";
      this.passport_expiry = new Date();
      this.dob = new Date();
      this.first_name = "";
      this.last_name = "";
      this.gender = "";
      this.nationality = "PK";
      this.passenger_type = PASSENGER_TYPE.ADULT;
      this.title = "";
    },
    async onSubmit(e: Event) {
      this.errors = {};
      e.preventDefault();
      try {
        const payload = await addPassengerValidation.validate(
          {
            cnic: this.cnic,
            passport_number: this.passport_number,
            passport_expiry: this.passport_expiry,
            dob: this.dob,
            first_name: this.first_name,
            last_name: this.last_name,
            gender: this.gender,
            nationality: this.nationality,
            passenger_type: this.passenger_type,
            title: this.title,
          },
          { abortEarly: false }
        );

        const EVENT = this.isEdit
          ? ORGANIZATION_ANALYTICS_EVENTS.UPDATE_PASSENGER_MANAGEMENT
          : ORGANIZATION_ANALYTICS_EVENTS.ADD_NEW_PASSENGER_MANAGEMENT;

        analyticsService.logActionEvent(EVENT, payload);

        this.$emit("onPassengerSubmit", {
          payload,
          successCallback: this.clearState,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
      }
    },
  },
  watch: {
    passenger: {
      immediate: true,
      handler(passenger: IPassenger) {
        if (this.isEdit) {
          this.cnic = passenger?.cnic || "";
          this.passport_number = passenger?.passport_number || "";
          this.first_name = passenger?.first_name || "";
          this.last_name = passenger?.last_name || "";
          this.gender = passenger?.gender || "";
          this.nationality = passenger?.nationality || "";
          this.passenger_type = passenger?.passenger_type || "";
          this.title = passenger?.title || "";

          if (passenger?.passport_expiry) {
            this.passport_expiry = new Date(passenger?.passport_expiry);
          } else {
            this.passport_expiry = addMonths(new Date(), 6);
          }

          if (passenger?.dob) {
            this.dob = new Date(passenger?.dob);
          } else {
            this.dob = this.disabledDates.from;
          }
        }
      },
    },
  },
  beforeUnmount() {
    this.clearState();
  },
});
</script>
