<template>
  <div class="btn-container" v-if="showAddNewAgentBtn()">
    <MButton variant="filled" @click="onAddNewAgentBtnClick"
      >Add New Agent</MButton
    >
  </div>
  <MDataTable
    :headers="headers"
    :data="agentsList"
    :item-per-page="30"
    :is-api-paginated="false"
    :has-search="true"
    :is-loading="isFetchingOrganizationAgents"
  >
    <template #pricing="{ item }">
      <MButton
        :disabled="isOwnerUser(item.agent_type)"
        @click="onClickManagePricing(item.id)"
      >
        {{ item.pricing }}
      </MButton>
    </template>

    <template #action="{ item }">
      <MButton
        :disabled="isOwnerUser(item.agent_type)"
        @click="onClickUpdatePermissions(item.id)"
      >
        {{ item.action }}
      </MButton>
    </template>
  </MDataTable>
  <AddAgentModal
    :is-open="isAddAgentDialogOpen"
    @handleClose="onCloseAddAgentDialog"
    @onSubmit="onAddEmail"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import {
  IAgentDetail,
  IAgentV2,
} from "@/ag-portal-common/interfaces/agent.interface";
import { Organization, USER_ROLES } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import UTILS from "@/ag-portal-common/utils";
import AddAgentModal from "@/modules/Organization/components/AddAgentModal.vue";
import { AddAgentDto } from "@/modules/Organization/dtos/agent.dto";
import analyticsService from "@/analytic.service";
import { AGENT_ANALYTICS_EVENTS } from "@/modules/Agent/constants/analyticsEvents";

interface AgentList {
  id: string | number | null;
  name: string | undefined;
  email: string | null;
  is_active: string;
  agent_type: AGENT_SUB_ROLES;
  pricing: string;
  action: string;
}

export default defineComponent({
  name: "AgentManagement",
  components: {
    MDataTable,
    AddAgentModal,
  },
  data() {
    return {
      isAddAgentDialogOpen: false,
      AGENT_SUB_ROLES,
      headers: [
        {
          title: "Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Agent Type",
          value: "agent_type",
          key: "agent_type",
          sortable: true,
        },
        {
          title: "Active",
          value: "is_active",
          key: "is_active",
          sortable: true,
        },
        {
          title: "Flight Pricing",
          value: "pricing",
          key: "pricing",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    PATH(): typeof PATH {
      return PATH;
    },
    isUserAgent() {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    agentsList(): AgentList[] {
      const agentsList = this.$store.getters
        .organizationAgents as IAgentDetail[];

      return agentsList.map((item) => ({
        id: item.agent_id || item.user.id,
        name: item.user.full_name,
        email: item.user.email,
        is_active: item.user.is_active ? "Active" : "In-Active",
        agent_type: item.user.role_sub_type,
        pricing: "Manage Pricing",
        action: "Update Permissions",
      }));
    },
    isFetchingOrganizationAgents(): boolean {
      return this.$store.getters.isFetchingOrganizationAgents;
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
  },
  beforeMount() {
    if (this.organization) {
      const id = this.organization.organization_id;

      this.$store.dispatch("getOrganizationAgents", id);
    }
  },
  methods: {
    genTestId,
    showAddNewAgentBtn() {
      return this.isUserAgent || this.isSuperUser;
    },
    onAddEmail(payload: AddAgentDto) {
      this.$store.dispatch("addAgentInOrganization", {
        body: payload,
        orgId: this.organization?.organization_id,
        callback: this.onCloseAddAgentDialog,
      });

      const analyticsPayload = {
        "organisation-id": this.organization?.organization_id,
        "user-email": payload.email,
        "user-fullname": payload.full_name,
        "user-role": payload.role,
      };

      analyticsService.logActionEvent(
        AGENT_ANALYTICS_EVENTS.AGENT_ADD,
        analyticsPayload
      );
    },
    onCloseAddAgentDialog() {
      this.isAddAgentDialogOpen = false;
      const id = this.organization?.organization_id;

      this.$store.dispatch("getOrganizationAgents", id);
    },
    onClickUpdatePermissions(agentId: string): void {
      this.$router.push(`${PATH.AGENT_PERMISSIONS}/${agentId}`);
    },
    onClickManagePricing(agentId: string): void {
      this.$router.push(`${PATH.AGENT_PRICING}/${agentId}`);
    },
    isOwnerUser(userType: string): boolean {
      return userType === AGENT_SUB_ROLES.OWNER;
    },
    onAddNewAgentBtnClick() {
      this.isAddAgentDialogOpen = true;
    },
  },
});
</script>

<style lang="css" scoped>
.btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 10px 20px;
  }
}
</style>
