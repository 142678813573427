import { GetterTree } from "vuex";
import { IMyBookingsState } from "@/interfaces/myBookingsState.interface";
import { IMyBookingsFlight } from "@/interfaces/flightBookings.interface";
import { IMyBookingsHotel } from "@/interfaces/hotelBookings.interface";
import { IMyBookingsTour } from "@/interfaces/tourBookings.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IMyBookingsState, StoreState> = {
  isFetchingFlightBookings: (state): boolean => state.isFetchingFlightBookings,
  isFetchingHotelBookings: (state): boolean => state.isFetchingHotelBookings,
  isFetchingTourBookings: (state): boolean => state.isFetchingTourBookings,
  isDownloadingBrandTicket: (state): boolean => state.isDownloadingBrandTicket,
  isSendingAirlineTicket: (state): boolean => state.isSendingAirlineTicket,
  flightBookings: (state): IMyBookingsFlight => state.flightBookings,
  hotelBookings: (state): IMyBookingsHotel => state.hotelBookings,
  tourBookings: (state): IMyBookingsTour => state.tourBookings,
  isDownloadingMBHotelVoucher: (state) => state.isDownloadingMBHotelVoucher,
  voucherUrl: (state) => state.voucherUrl,
};

export default getters;
