import CryptoJS from "crypto-js";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import { ValidationError } from "yup";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format as dateFnsFormat,
  getDay,
  isBefore,
  parse,
} from "date-fns";

const VUE_APP_APP_SALT = process.env.VUE_APP_APP_SALT || "";

export const stringEncrypt = (string: string) =>
  CryptoJS.AES.encrypt(string, VUE_APP_APP_SALT).toString();

// String decryption
export const stringDecrypt = (ciphertext: any) => {
  const methodName = "stringDecrypt";
  let value = null;
  try {
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, ciphertext);
    value = CryptoJS.AES.decrypt(ciphertext, VUE_APP_APP_SALT, {
      mode: CryptoJS.mode.CBC,
    }).toString(CryptoJS.enc.Utf8);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, value);
  } catch (error) {
    loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
  }
  return value;
};

export const formatNumber = (num: number | string): string => {
  let temp: any = num;
  if (typeof num === "number") {
    temp = num % 1 !== 0 ? num.toFixed(2) : num.toString();
  }
  return temp?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

export function formatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function formatStringToRoutePath(
  str: string,
  values: { [key: string]: string }
) {
  for (const key in values) {
    str = str.replace(`:${key}`, values[key]);
  }
  return str;
}

export function isRoleAllowed(
  userRole: USER_ROLES,
  allowedRoles: Array<USER_ROLES>
): boolean {
  return !allowedRoles.length || allowedRoles.includes(userRole);
}

export function isUserPermitted(
  allowedPermissions: PERMISSIONS[],
  userPermissions: PERMISSIONS[]
): boolean {
  return allowedPermissions.every((permission) => {
    return userPermissions.includes(permission);
  });
}

export function yupValidationErrorAsSchema(
  validationError: ValidationError
): any {
  return validationError.inner.reduce(
    (accumulator, error) => ({
      ...accumulator,
      ...(error.path && { [error.path]: error.message }),
    }),
    {}
  );
}

export const genTestId = (type: ELEMENT_TYPES, name: string) => {
  return `${type}-${name}`;
};

export const getCurrencyFormatter = (currency = "PKR") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  });
};

export const getFormattedDateTime = (timeStamp: Date, format: string) => {
  return dateFnsFormat(new Date(timeStamp), format);
};

export const getDayFromDate = (timeStamp: Date) => {
  return getDay(new Date(timeStamp));
};

export const isDateExpired = (from: Date, to?: Date): boolean => {
  to = to || new Date();
  return isBefore(from, to || new Date(Date.now()));
};

export const validateEmail = (email: string) => {
  const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
  return emailRegex.test(email);
};

export const validateMobileNumber = (mobileNumber: string) => {
  const regex = /^03\d{9}$/;
  return regex.test(mobileNumber);
};

export const getDurationText = (from?: Date, to?: Date): string => {
  const _from = from || new Date(Date.now());
  const _to = to || new Date(Date.now());
  const minutesDiff = differenceInMinutes(_to, _from);
  const hoursDiff = differenceInHours(_to, _from);

  const daysDiff = differenceInDays(_to, _from);
  const remainingMinutes = minutesDiff % 60;
  const remainingHours = hoursDiff % 24;

  const duration = {
    days: daysDiff,
    minutes: remainingMinutes,
    hours: remainingHours,
  };

  let durationText = "";
  durationText += duration.days
    ? `${duration.days} ${duration.days > 1 ? "days" : "day"} `
    : "";
  durationText += duration.hours
    ? `${duration.hours} ${duration.hours > 1 ? "hrs" : "hr"} `
    : "";
  durationText += duration.hours
    ? `${duration.minutes} ${duration.minutes > 1 ? "mins" : "min"} `
    : "";

  return durationText;
};

export const formatQueryPath = (obj: any) => {
  let path = "";
  Object.keys(obj).forEach((item, index) => {
    const prefix = index === 0 ? `?` : `&`;
    path += `${prefix}${item}=${obj[item]}`;
  });
  return path;
};

export const formatImageUrl = (url: string) => {
  const firstDoubleSlashIndex = url.indexOf("//");
  return (
    url.slice(0, firstDoubleSlashIndex + 2) +
    url.slice(firstDoubleSlashIndex + 2).replace(/\/\//g, "/")
  );
};

export const formatWordIntoCapitalize = (str: string) =>
  str.toLowerCase().replace(/(?:^|\s|[-"'([{])+\S/g, (c) => c.toUpperCase());

export const hotelNumberOfNightStayText = (
  _checkInDate: Date,
  _checkOutDate: Date
) => {
  const daysDiff = differenceInDays(_checkOutDate, _checkInDate);

  return `${daysDiff} NIGHT(S) STAY`;
};

export const parseIntoCurrentDateIgnoringTimezone = (date: string) => {
  const timezone = date.substring(date.length - 6, date.length); // eg: +03:00
  const timezoneOffset = parseInt(timezone) * 60;
  const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
  const parseDateTimezoneOffset = parsedDate.getTimezoneOffset();
  const getDifferenceInHours = parseDateTimezoneOffset + timezoneOffset;
  const dateIgnoringTimezone = new Date(
    parsedDate.getTime() + getDifferenceInHours * 60 * 1000
  );
  return dateIgnoringTimezone;
};

export const clearCache = () => {
  caches.keys().then((keyList) =>
    Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    )
  );
};
