<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "fitMainPage",
});
</script>

<template>
  <div class="container">
    <ag-card style="margin-left: 10%; margin-right: 10%">
      <div class="d-flex justify-content-between align-items-center">
        <ag-heading
          title="Umrah Hotels"
          variant="h2"
          style="margin-bottom: 0"
        />

        <router-link to="/fit/umrah-hotels">
          <AGButton variant="primary">Explore</AGButton>
        </router-link>
      </div>
    </ag-card>
  </div>
</template>

<style>
.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}
</style>
