<template>
  <template v-if="departureFlightOption && arrivalFlightOption">
    <MCard class="flight-details-card">
      <DiscountFlag
        v-if="agDealDiscount"
        :code="discountData.discount_code"
        :title="parseDiscountLabel(discountData)"
      />

      <ag-accordion
        class="flight_accordion_wrap return-flight-card"
        :panel-value="[1, 2]"
      >
        <ag-accordion-panel title="">
          <template #flightInfo>
            <AgRow style="display: flex; align-items: center">
              <AgColumn style="flex: 4; margin-right: 10px">
                <ReturnFlightRouteCard
                  :departure-airline-name="departureFlightOption.airline.name"
                  :departure-airline-logo="departureFlightOption.airline.logo"
                  :departure-airline-code="
                    departureFlightOption.flight_numbers.join(' . ')
                  "
                  :departure-duration="departureFlightOption.duration"
                  :departure-from-time="departureFlightOption.departure_time"
                  :departure-to-time="departureFlightOption.arrival_time"
                  :arrival-airline-code="
                    arrivalFlightOption.flight_numbers.join(' . ')
                  "
                  :arrival-duration="arrivalFlightOption.duration"
                  :arrival-from-time="arrivalFlightOption.departure_time"
                  :arrival-to-time="arrivalFlightOption.arrival_time"
                  :departure-origin-segment-iata-code="
                    departureOriginSegmentIataCode
                  "
                  :departure-destination-segment-iata-code="
                    departureDestinationSegmentIataCode
                  "
                  :arrival-origin-segment-iata-code="
                    arrivalOriginSegmentIataCode
                  "
                  :arrival-destination-segment-iata-code="
                    arrivalDestinationSegmentIataCode
                  "
                />
                <AgFilterDropdown
                  class="flight_fare_wrap"
                  label=""
                  icon="infoIcon"
                  cart="cartIcon"
                  :provider="departureFlightOption.provider"
                  @add:quote="addToQuote(returnFlightPair)"
                  v-show="showFareBreakDown"
                >
                  <template #Items>
                    <AgFareInfo>
                      <AgHeading
                        :title="
                          renderRBDHandler([
                            departureFareOption.rbd,
                            arrivalFareOption.rbd,
                          ])
                        "
                        variant="h3"
                      ></AgHeading>
                      <AgPriceSummary
                        info="Base Fare"
                        :price="getFareHandler('base_fare')"
                      ></AgPriceSummary>
                      <AgPriceSummary
                        info="Tax"
                        :price="getFareHandler('tax')"
                      ></AgPriceSummary>
                      <AgPriceSummary
                        info="Gross Fare"
                        :price="getFareHandler('gross_fare')"
                      ></AgPriceSummary>
                      <AgPriceSummary
                        v-if="agPriceDifference != null"
                        :info="getSummaryTitleHandler(agPriceDifference || 0)"
                        :price="getFareHandler('ag_price_difference')"
                      ></AgPriceSummary>
                      <template v-if="agDealDiscount">
                        <AgPriceSummary
                          info="Deal Discount"
                          :price="
                            formatCurrency(
                              Number(agDealDiscount.value),
                              agDealDiscount.currency
                            )
                          "
                        ></AgPriceSummary>
                      </template>
                      <AgPriceSummary
                        info="Total"
                        :price="getGrossFareHandler()"
                        total-price="true"
                      ></AgPriceSummary>
                    </AgFareInfo>
                  </template>
                </AgFilterDropdown>
              </AgColumn>
              <AgColumn style="flex: 4">
                <AgRow style="align-items: center">
                  <AgFlightFare
                    :type="departureFareOption.rbd"
                    :price="arrivalDestination + '-' + departureDestination"
                    :piece="
                      getBaggageInformation(departureFareOption.baggage_info)
                    "
                    pieceIcon="bagIcon"
                  >
                  </AgFlightFare>
                  <AgFlightFare
                    :type="arrivalFareOption.rbd"
                    :price="departureDestination + '-' + arrivalDestination"
                    :piece="
                      getBaggageInformation(arrivalFareOption.baggage_info)
                    "
                    pieceIcon="bagIcon"
                  >
                  </AgFlightFare>
                  <AgHeading variant="h2" :title="getGrossFareHandler()" />
                </AgRow>
              </AgColumn>
            </AgRow>
            <AgRow
              style="
                display: flex;
                align-items: right;
                justify-content: flex-end;
              "
            >
              <MButton
                type="filled"
                :disabled="isBookingFare"
                @click.stop="onClickBookFareHandler()"
              >
                Book Fare
              </MButton>
            </AgRow>
          </template>
          <template #tabSection>
            <hr />
            <AgTabs test-id="test">
              <template #TabHeading>
                <v-tab value="f_info_tab_1"> Flight Information</v-tab>
                <v-tab value="f_info_tab_2"> Cancellation</v-tab>
                <v-tab value="f_info_tab_3"> Date Change</v-tab>
                <v-tab value="f_info_tab_4"> Baggage</v-tab>
              </template>

              <template #TabDetail>
                <v-window-item
                  value="f_info_tab_1"
                  transition="fade"
                  reverse-transition="fade"
                >
                  <AgDiv class="complete_flight_details">
                    <AgRow>
                      <AgColumn sm="2" md="2" lg="2">
                        <AgIconBox
                          title="Departure"
                          :sub-title="
                            parseTimeOrDate(
                              departureFlightOption.departure_time
                            )
                          "
                          icon="departureIcon"
                        ></AgIconBox>
                      </AgColumn>
                      <AgColumn sm="10" md="10" lg="10">
                        <AgDiv class="inner_left_border">
                          <ReturnFlightRouteCard
                            :departure-airline-name="
                              departureFlightOption.airline.name
                            "
                            :departure-airline-logo="
                              departureFlightOption.airline.logo
                            "
                            :departure-airline-code="
                              departureFlightOption.flight_numbers.join(' . ')
                            "
                            :departure-duration="departureFlightOption.duration"
                            :departure-from-time="
                              departureFlightOption.departure_time
                            "
                            :departure-to-time="
                              departureFlightOption.arrival_time
                            "
                            :arrival-airline-code="
                              arrivalFlightOption.flight_numbers.join(' . ')
                            "
                            :arrival-duration="arrivalFlightOption.duration"
                            :arrival-from-time="
                              arrivalFlightOption.departure_time
                            "
                            :arrival-to-time="arrivalFlightOption.arrival_time"
                            :departure-origin-segment-iata-code="
                              departureOriginSegmentIataCode
                            "
                            :departure-destination-segment-iata-code="
                              departureDestinationSegmentIataCode
                            "
                            :arrival-origin-segment-iata-code="
                              arrivalOriginSegmentIataCode
                            "
                            :arrival-destination-segment-iata-code="
                              arrivalDestinationSegmentIataCode
                            "
                          />

                          <AgHeading
                            class="flight_info_green_box"
                            :title="
                              parseDateHandler(
                                departureFareOption.segments[0]
                                  .departure_datetime
                              )
                            "
                            variant="h4"
                          />
                          <FlightRoute
                            v-for="(
                              segment, index
                            ) in departureFareOption.segments"
                            :next-departure-time="getNextDepartureTime(index)"
                            :show-lay-off="
                              index + 1 < departureFareOption.segments.length
                            "
                            :key="
                              segment.flight_number[0] +
                              segment.departure_datetime
                            "
                            :segment="segment"
                          />

                          <AgHeading
                            class="flight_info_green_box"
                            :title="
                              parseDateHandler(
                                arrivalFareOption.segments[0].departure_datetime
                              )
                            "
                            variant="h4"
                          />
                          <FlightRoute
                            v-for="(
                              segment, index
                            ) in arrivalFareOption.segments"
                            :next-departure-time="getNextArrivalTime(index)"
                            :show-lay-off="
                              index + 1 < arrivalFareOption.segments.length
                            "
                            :key="
                              segment.flight_number[0] +
                              segment.departure_datetime
                            "
                            :segment="segment"
                          />
                        </AgDiv>
                      </AgColumn>
                    </AgRow>
                  </AgDiv>
                </v-window-item>
                <rules-tabs
                  :origin="departureDestination"
                  :destination="arrivalDestination"
                  :pre_booking_token="arrivalFareOption.pre_booking_token"
                  :isRefundable="departureFareOption.is_refundable"
                />
              </template>
            </AgTabs>
          </template>
        </ag-accordion-panel>
      </ag-accordion>
    </MCard>
  </template>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { formatStringToRoutePath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

import {
  DealDiscount,
  FareOption,
  FlightOption,
  ReturnFlightPair,
} from "../../types/Flights";
import { Price } from "../../types/BookingResponse";
import { TimeUtility } from "../../utils/TimeUtility";
import { getCurrencyFormatter, getGrossFare } from "../../utils";
import { AMOUNT_TYPE, FARE_TYPE } from "../../enums/fare_calculator";
import FlightRoute from "./FlightRoute.vue";

import RulesTabs from "./RulesTabs.vue";

import analyticsService from "@/analytic.service";
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents";
import { AgHeading } from "@aeroglobe/ag-core-ui";

import { MCard } from "@aeroglobe/ag-core-ui";
import DiscountFlag from "../DiscountFlag.vue";
import ReturnFlightRouteCard from "./ReturnFlightRouteCard.vue";
import { BaggageInfo } from "@/ag-flight-components/types/BookingResponse";
import { ExtendedPrice } from "@/modules/ManageQuotations/views/utils/BookingResponse";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "ReturnFlightDetailCardItem",
  props: {
    returnFlightPair: {
      type: Object as PropType<ReturnFlightPair>,
      required: true,
    },
    showFareBreakDown: {
      type: Boolean,
      default: true,
    },
  },
  data(): {
    fareOption: FareOption | null;
    isPanelExpanded: boolean;
    isBookingFare: boolean;
  } {
    return {
      fareOption: null,
      isPanelExpanded: false,
      isBookingFare: false,
    };
  },
  methods: {
    getGrossFare,
    parseDiscountLabel(data: DealDiscount | null): string {
      return UTILS.parseDiscountLabel(data);
    },
    handleSuccessCallBack(id: string) {
      this.$router.push(
        formatStringToRoutePath(PATH.FLIGHTS_BOOKING_FORM, { id })
      );
    },

    addToQuote(pair: ReturnFlightPair): void {
      const existingQuotationsString =
        localStorage.getItem("Quotations-Return");
      const existingQuotations: ReturnFlightPair[] = existingQuotationsString
        ? JSON.parse(existingQuotationsString)
        : [];
      const flightName = this.departureFlightOption
        ? this.departureFlightOption.name
        : "";
      const firstRBD = this.departureFareOption.rbd;
      const secondRBD = this.arrivalFareOption.rbd;

      const isFlightAlreadyAdded = existingQuotations.some(
        (pair: ReturnFlightPair) => {
          return (
            pair.departure.flight_option?.name == flightName &&
            pair.departure.fare_option.rbd == firstRBD &&
            pair.arrival.fare_option.rbd == secondRBD
          );
        }
      );

      if (!isFlightAlreadyAdded) {
        if (existingQuotations.length < 10) {
          existingQuotations.push(pair);
          localStorage.setItem(
            "Quotations-Return",
            JSON.stringify(existingQuotations)
          );
          notificationService.type = NOTIFICATION_TYPES.SUCCESS;
          notificationService.description = "Flight Added To Quotations";
          notificationService.triggerNotification();
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "Delete few quotations to add more";
          notificationService.triggerNotification();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Flight already exists in quotations";
        notificationService.triggerNotification();
      }
      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.ADD_TO_QUOTE,
        {
          dataAdded: localStorage.getItem("Quotations-Return"),
        }
      );
    },
    getSummaryTitleHandler(amount: number): string {
      if (amount > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    formatTime(time: Date): string {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration(durationInNumber: number): string {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    calculateFlightPrice(fareOption: FareOption): number {
      const fareCalculationAmount = Number(
        this.$store.state.flightModule.fareCalculatorAmount
      );

      if (!fareCalculationAmount) {
        return this.getGrossFare(fareOption);
      }

      const fareType = this.$store.state.flightModule.fareType;
      const amountType = this.$store.state.flightModule.amountType;

      let fare = this.getGrossFare(fareOption);
      let amountToAdd = fareCalculationAmount;
      let tax = 0;

      if (fareType === FARE_TYPE.BASE) {
        fare = Number(fareOption.price.base_fare.value);
        tax = Number(fareOption.price.tax.value);
      }

      if (amountType === AMOUNT_TYPE.PERCENTAGE) {
        amountToAdd = (fare / 100) * fareCalculationAmount;
      }

      return fare + amountToAdd + tax;
    },
    formatCurrency(amount: number, currency_format: string): string {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    getNextDepartureTime(index: number): Date {
      return index + 1 < this.departureFareOption.segments.length
        ? this.departureFareOption.segments[index + 1].departure_datetime
        : new Date();
    },
    getNextArrivalTime(index: number): Date {
      return index + 1 < this.arrivalFareOption.segments.length
        ? this.arrivalFareOption.segments[index + 1].departure_datetime
        : new Date();
    },
    parseDateHandler(departure_date: Date): string {
      return TimeUtility.parsedFlightTimeorDate(
        departure_date,
        "EEEE, MMMM dd yyyy"
      );
    },
    parseTimeOrDate(date: string | Date): string {
      return TimeUtility.parsedFlightTimeorDate(date);
    },
    async onClickBookFareHandler(): Promise<void> {
      this.isBookingFare = true;

      try {
        const pre_booking_token = this.arrivalFareOption.pre_booking_token;

        const payload = {
          pre_booking_tokens: [pre_booking_token],
        };

        await this.$store.dispatch("initiateBooking", {
          payload,
          successCallback: this.handleSuccessCallBack,
        });

        const analyticsPayload = {
          departure: { ...this.departureFareOption },
          arrival: { ...this.arrivalFareOption },
        };

        analyticsService.logActionEvent(
          FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOKING_CONFIRMED_VIA_RETURN_PAIRS,
          analyticsPayload
        );
      } catch (_) {
        this.isBookingFare = false;
      } finally {
        this.isBookingFare = false;
      }
    },
    renderRBDHandler(rbds: string[]): string {
      return `RBD: ${rbds.join(" - ")}`;
    },
    getFareHandler(
      fareType:
        | "base_fare"
        | "gross_fare"
        | "ag_gross_fare"
        | "tax"
        | "ag_price_difference"
        | "ag_deal_discount"
    ): string {
      const fareOption = this.arrivalFareOption;

      const fare = fareOption.price[fareType];

      return fare
        ? this.formatCurrency(Number(fare.value), fare.currency ?? "")
        : "";
    },
    getGrossFareHandler(): string {
      const fareOption = this.arrivalFareOption;

      const calculatedFare = this.calculateFlightPrice(fareOption);

      return calculatedFare
        ? this.formatCurrency(
            Number(calculatedFare),
            fareOption.price.gross_fare.currency ?? ""
          )
        : "";
    },
    getBaggageInformation(baggage_info: BaggageInfo | undefined): string {
      if (baggage_info) {
        const { pieces, weight, unit } = baggage_info;
        if (pieces < 1) {
          return "Baggage included (As per airline policy)";
        }
        return `${pieces} piece - ${weight} ${unit}`;
      } else {
        return "No - Baggage";
      }
    },
  },
  computed: {
    departureFlightOption(): FlightOption | undefined {
      return this.returnFlightPair.departure.flight_option;
    },
    departureFareOption(): FareOption {
      return this.returnFlightPair.departure.fare_option;
    },
    arrivalFlightOption(): FlightOption | undefined {
      return this.returnFlightPair.arrival.flight_option;
    },
    arrivalFareOption(): FareOption {
      return this.returnFlightPair.arrival.fare_option;
    },

    agPriceDifference(): number {
      return this.arrivalFareOption.price.ag_price_difference.value;
    },
    agDealDiscount(): ExtendedPrice | null | undefined {
      const discount = this.arrivalFareOption.price.ag_deal_discount;

      return discount;
    },
    departureDestination(): string {
      const segments = this.arrivalFareOption.segments;

      return segments[0].origin.iata_code;
    },
    arrivalDestination(): string {
      const segments = this.arrivalFareOption.segments;

      return segments[segments.length - 1].destination.iata_code;
    },

    departureOriginSegmentIataCode(): string {
      const segments = this.departureFareOption.segments;

      return segments[0].origin.iata_code;
    },
    departureDestinationSegmentIataCode(): string {
      const segments = this.departureFareOption.segments;

      return segments[segments.length - 1].destination.iata_code;
    },
    arrivalOriginSegmentIataCode(): string {
      const segments = this.arrivalFareOption.segments;

      return segments[0].origin.iata_code;
    },
    arrivalDestinationSegmentIataCode(): string {
      const segments = this.arrivalFareOption.segments;

      return segments[segments.length - 1].destination.iata_code;
    },
    discountData(): DealDiscount {
      const fareOption = this.arrivalFareOption;

      return fareOption.discount_data;
    },
  },
  components: {
    AgHeading,
    FlightRoute,
    RulesTabs,
    ReturnFlightRouteCard,
    MCard,
    DiscountFlag,
  },
});
</script>

<style lang="css">
.flight-details-card {
  margin-bottom: 20px;
  position: relative;
}
.flight-details-card .flight_accordion_wrap.return-flight-card {
  margin: 20px 0 0;
}
</style>
