import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c37f964"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  "test-id": "",
  class: "add_multiple_flight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MulticityFlightItem = _resolveComponent("MulticityFlightItem")!
  const _component_MTravelerCountBoxItem = _resolveComponent("MTravelerCountBoxItem")!
  const _component_MTravelerCountBox = _resolveComponent("MTravelerCountBox")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_row = _resolveComponent("ag-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.multiCityLegs, (leg, index) => {
      return (_openBlock(), _createBlock(_component_MulticityFlightItem, {
        errors: _ctx.errors,
        "multicity-leg": leg,
        index: index,
        key: index,
        onValidateFields: _ctx.validateSearchFeilds
      }, null, 8, ["errors", "multicity-leg", "index", "onValidateFields"]))
    }), 128)),
    (_ctx.showAddFlightBtn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addFlight && _ctx.addFlight(...args))),
            "test-id": "",
            class: "add_another_flight_link"
          }, " + Add Another Flight ")
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ag_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_column, {
          xs: "12",
          sm: "12",
          md: "7",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AgRow, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MTravelerCountBox, {
                      label: "Travelers",
                      "prepend-icon": "m-traveler-count-icon",
                      "adult-count": _ctx.adultCount,
                      "child-count": _ctx.childCount,
                      "infant-count": _ctx.infantCount
                    }, {
                      items: _withCtx(() => [
                        _createVNode(_component_MTravelerCountBoxItem, {
                          name: "adultCount",
                          label: "Adult",
                          icon: "m-adult-traveler-icon",
                          hint: "(12 years and above)",
                          count: _ctx.adultCount,
                          "onUpdate:adultCount": _ctx.updateAdultCount
                        }, null, 8, ["count", "onUpdate:adultCount"]),
                        _createVNode(_component_MTravelerCountBoxItem, {
                          name: "childCount",
                          label: "Children",
                          icon: "m-child-traveler-icon",
                          hint: "(2 to 11 years)",
                          count: _ctx.childCount,
                          "onUpdate:childCount": _ctx.updateChildCount
                        }, null, 8, ["count", "onUpdate:childCount"]),
                        _createVNode(_component_MTravelerCountBoxItem, {
                          name: "infantCount",
                          label: "Infants",
                          icon: "m-infant-traveler-icon",
                          hint: "(0 to less than 2)",
                          count: _ctx.infantCount,
                          "onUpdate:infantCount": _ctx.updateInfantCount
                        }, null, 8, ["count", "onUpdate:infantCount"])
                      ]),
                      _: 1
                    }, 8, ["adult-count", "child-count", "infant-count"])
                  ]),
                  _: 1
                }),
                (_ctx.isSuperUserOrOperationUser)
                  ? (_openBlock(), _createBlock(_component_ag_column, {
                      key: 0,
                      xs: "12",
                      sm: "12",
                      md: "6",
                      cols: "12"
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "financial-profile-combobox", {}, undefined, true)
                      ]),
                      _: 3
                    }))
                  : (_openBlock(), _createBlock(_component_ag_column, {
                      key: 1,
                      xs: "12",
                      sm: "12",
                      md: "6",
                      cols: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MCombobox, {
                          inputValue: _ctx.selectedCabinClass,
                          "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCabinClass) = $event)),
                          label: "Cabin Class",
                          itemValue: "value",
                          itemLabel: "label",
                          options: _ctx.CabinClassOptions,
                          class: "no-margin",
                          focusedBorderEnabled: ""
                        }, null, 8, ["inputValue", "options"])
                      ]),
                      _: 1
                    }))
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createVNode(_component_ag_column, {
          xs: "12",
          sm: "12",
          md: "5",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_row, null, {
              default: _withCtx(() => [
                (_ctx.isSuperUserOrOperationUser)
                  ? (_openBlock(), _createBlock(_component_ag_column, {
                      key: 0,
                      xs: "12",
                      sm: "12",
                      md: "6",
                      cols: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MCombobox, {
                          inputValue: _ctx.selectedCabinClass,
                          "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCabinClass) = $event)),
                          label: "Cabin Class",
                          itemValue: "value",
                          itemLabel: "label",
                          options: _ctx.CabinClassOptions,
                          class: "no-margin",
                          focusedBorderEnabled: ""
                        }, null, 8, ["inputValue", "options"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12",
                  class: "search-btn-container"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MButton, {
                      type: "square",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFlightSearch()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Search Flights ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 3
    })
  ], 64))
}