<template>
  <AgCard test-id="">
    <AgIconInfoBar
      test-id=""
      icon="contactTravelerIcon"
      title="Traveler Details"
    />
    <ag-accordion
      class="flight_accordion_wrap margin_bottom_0"
      :panel-value="[0]"
    >
      <ag-accordion-panel>
        <template #flightInfo>
          <ag-heading
            variant="h3"
            :title="renderAccordionTitle"
            class="margin_bottom_0"
          />
        </template>
        <template #tabSection>
          <AgRow test-id="" class="padding_top_40">
            <AgColumn test-id="" md="7" lg="7" cols="12">
              <AgRow test-id="">
                <AgColumn test-id="" md="12" lg="9">
                  <AgSelect
                    :items="renderTitleData"
                    @change="(value:any) => handleFieldChange(value,'title')"
                    :value="getValueByNameandIndex('title')"
                    label="Title"
                    variant="outlined"
                    test-id=""
                    :error="renderError('title')"
                    :id="generateIds('title')"
                  />
                  <AGTextField
                    label="First Name and Middle Name (if any)"
                    @input="(value:any) => handleTextFieldChange(value, 'first_name')"
                    :value="getValueByNameandIndex('first_name')"
                    test-id=""
                    variant="outlined"
                    :error="renderError('first_name')"
                    :id="generateIds('first_name')"
                  />
                  <AGTextField
                    label="Last Name"
                    @input="(value:any) => handleTextFieldChange(value, 'last_name')"
                    :value="getValueByNameandIndex('last_name')"
                    test-id=""
                    variant="outlined"
                    :error="renderError('last_name')"
                    :id="generateIds('last_name')"
                  />
                  <ag-calendar
                    label="Date of Birth"
                    @update:date_of_birth="(value:any) => handleDateSelect(value, 'date_of_birth')"
                    calendar-name="date_of_birth"
                    :date="
                      $store.state.hotelModule.travelers[index]['date_of_birth']
                    "
                    :min-date="disabledDates.to"
                    :max-date="disabledDates.from"
                    :year-range="disabledDates.yearsToShow"
                    :error="renderError('date_of_birth')"
                    :id="generateIds('date_of_birth')"
                  />
                </AgColumn>
              </AgRow>
            </AgColumn>

            <AgColumn test-id="" md="5" lg="5" cols="12">
              <AgTravelDetail
                test-id=""
                :items="{
                  [TravelerCardDetails.TITLE]: getValueByNameandIndex('title'),
                  [TravelerCardDetails.FIRST_NAME]:
                    getValueByNameandIndex('first_name'),
                  [TravelerCardDetails.LAST_NAME]:
                    getValueByNameandIndex('last_name'),
                  [TravelerCardDetails.DOB]: dateOfBirth,
                }"
              >
                <template #headingArea>
                  <AgHeading
                    variant="h2"
                    title="Contact Details"
                    class="margin_bottom_5"
                  />
                </template>
              </AgTravelDetail>
            </AgColumn>
          </AgRow>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </AgCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { addYears, format, sub } from "date-fns";

import { titles } from "../constants/titles";
import { HotelTraveler } from "@/ag-portal-common/types/hotel";
import { getTravelerTypeName, TravelerTypes } from "../constants/travelerTypes";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";
import { TravelerCardDetails } from "@/ag-portal-common/enums/TRAVELER_CARD_DETAILS";

export default defineComponent({
  name: "TavelerCard",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    traveler: {
      type: Object as PropType<HotelTraveler>,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      titles,
    };
  },
  computed: {
    TravelerCardDetails(): typeof TravelerCardDetails {
      return TravelerCardDetails;
    },
    dateOfBirth(): string {
      const dob = this.getValueByNameandIndex("date_of_birth");
      const date = new Date(dob);

      return dob ? getFormattedDateTime(date, FORMAT_DD_MMM_YYYY) : "";
    },
    renderAccordionTitle(): string {
      const passenger_type = getTravelerTypeName(this.traveler?.type as string);

      return `Traveler ${(this.index || 0) + 1}: ${passenger_type}`;
    },
    disabledDates() {
      const currentDate = new Date();
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
      const nextDate = new Date(currentDate.getTime() + oneDayInMilliseconds);

      let minDate = null;
      let maxDate = new Date();
      let yearsToShow: Array<string> = [];

      const passengerType = getTravelerTypeName(this.traveler?.type as string);

      switch (passengerType) {
        case "Adult":
          minDate = addYears(currentDate, -100);
          maxDate = addYears(currentDate, -18);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
          break;
        case "Child":
          minDate = addYears(nextDate, -18);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
      }

      return {
        to: minDate,
        from: maxDate,
        yearsToShow: yearsToShow,
      };
    },
    renderTitleData(): {
      label: string;
      value: string;
      type: TravelerTypes;
    }[] {
      return titles.filter((x) => x.type === (this.traveler?.type as string));
    },
  },

  methods: {
    handleFieldChange(value: any, key: string) {
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    renderError(key: string) {
      return this.errors?.[`guests[${this.index}].${key}`];
    },
    handleDateSelect(value: Date, key: string) {
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    getValueByNameandIndex(key: keyof HotelTraveler) {
      const item = this.$store.state.hotelModule.travelers[this.index][key];

      return item ? item : "";
    },
    handleTextFieldChange(event: InputEvent, key: string) {
      const value = (event.target as HTMLInputElement).value;
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    generateIds(key: string) {
      return this.errors?.[`guests[${this.index}].${key}`];
    },
  },
  mounted() {
    this.handleDateSelect(this.disabledDates.from, "date_of_birth");
  },
});
</script>
