<template>
  <ag-modal :is-open="isOpen" @close="onCloseModal" modal-width="60%">
    <template #header>
      <agheading
        >Pending Receipt and Invoice of
        <b>{{ financialProfile.financial_profile_name }}</b></agheading
      >
    </template>
    <template #body>
      <div class="postings">
        <MAccordion
          :initial-open="false"
          v-if="pendingPostingFlightInvoices.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                icon="currentBalance"
                title="Flight Invoices"
                class="accordion-title accordions"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="flightInvoicesHeaders"
              :data="pendingPostingFlightInvoices"
              :item-per-page="10"
              :total-item-count="pendingPostingFlightInvoices.length"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #ticket_no="{ item }">
                {{ item.ticket_no }}
              </template>
              <template #pnr="{ item }">
                <MChip class="chip-width" :border-less="true" variant="warning"
                  >{{ item.pnr }}
                </MChip>
              </template>
              <template #posting_status="{ item }">
                <MChip class="chip-width" :border-less="true" variant="error"
                  >{{ item.posting_status }}
                </MChip>
              </template>
            </MDataTable>
          </template>
        </MAccordion>
        <MAccordion
          :initial-open="false"
          v-if="pendingPostingFlightReceipts.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                icon="currentBalance"
                title="Flight Receipts"
                class="accordion-title accordions"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="flightReceiptsHeaders"
              :data="pendingPostingFlightReceipts"
              :item-per-page="10"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #payment_id="{ item }">
                {{ item.payment_id }}
              </template>
              <template #amount="{ item }">
                <MChip class="chip-width" :border-less="true" variant="warning"
                  >{{ item.amount }}
                </MChip>
              </template>
              <template #posting_status="{ item }">
                <MChip class="chip-width" :border-less="true" variant="error"
                  >{{ item.posting_status }}
                </MChip>
              </template>
            </MDataTable>
          </template>
        </MAccordion>

        <MAccordion
          :initial-open="false"
          v-if="pendingPostingHotelInvoices.length > 0"
        >
          <template v-slot:title>
            <span class="accordion-title">
              <AgIconInfoBar
                test-id=""
                icon="currentBalance"
                title="Hotel Invoices"
                class="accordion-title accordions"
            /></span>
          </template>
          <template #content>
            <MDataTable
              :headers="hotelInvoicesHeaders"
              :data="pendingPostingHotelInvoices"
              :item-per-page="10"
              :has-search="false"
              :is-api-paginated="false"
            >
              <template #booking_id="{ item }">
                {{ item.booking_id }}
              </template>
              <template #status="{ item }">
                <MChip class="chip-width" :border-less="true" variant="error"
                  >{{ item.status }}
                </MChip>
              </template>
            </MDataTable>
          </template>
        </MAccordion>
      </div>
      <AgNotFound
        v-if="
          pendingPostingFlightInvoices.length === 0 &&
          pendingPostingFlightReceipts.length === 0 &&
          pendingPostingHotelInvoices.length === 0
        "
        test-id=""
        heading="No Pending Receipts & Invoices Found"
        description=""
      />
    </template>
    <template #footer>
      <AGButton @click="onCloseModal" variant="primary">Close</AGButton>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatNumber } from "@/ag-portal-common/utils/helpers";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import {
  HotelInvoices,
  MDataTableHeader,
  PostingInvoice,
  PostingReceipts,
} from "@/ag-portal-common/types";

export default defineComponent({
  name: "ViewNotInvoicedReceiptsDialog",
  props: {
    financialProfile: {
      type: Object as PropType<IFinancialProfile>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    selectedId: string;
    isModalOpen: boolean;
    isLoading: boolean;
    pendingPostingFlightReceipts: PostingReceipts[];
    pendingPostingFlightInvoices: PostingInvoice[];
    pendingPostingHotelInvoices: HotelInvoices[];
    flightReceiptsHeaders: MDataTableHeader[];
    flightInvoicesHeaders: MDataTableHeader[];
    hotelInvoicesHeaders: MDataTableHeader[];
  } {
    return {
      selectedId: "",
      isLoading: false,
      isModalOpen: false,
      pendingPostingFlightReceipts: [],
      pendingPostingFlightInvoices: [],
      pendingPostingHotelInvoices: [],
      hotelInvoicesHeaders: [
        {
          title: "Booking Id",
          value: "booking_id",
          key: "booking_id",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
          align: "left",
        },
      ],
      flightReceiptsHeaders: [
        {
          title: "Payment Id",
          value: "payment_id",
          key: "payment_id",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          align: "left",
          sortable: false,
        },
      ],
      flightInvoicesHeaders: [
        {
          title: "Ticket Number",
          value: "ticket_no",
          key: "ticket_no",
          sortable: false,
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
          align: "left",
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
          align: "left",
        },
      ],
    };
  },
  methods: {
    formatNumber,
    getPendingPostingInvoices() {
      return this.$store.getters.getPendingPostingInvoices;
    },
    getPendingHotelInvoices(): HotelInvoices[] {
      const invoices = this.getPendingPostingInvoices();
      return invoices.hotelInvoices;
    },
    getPendingFlightInvoices(): PostingInvoice[] {
      const invoices = this.getPendingPostingInvoices();
      return invoices.flightInvoices || [];
    },
    getPendingFlightReceipts(): PostingReceipts[] {
      const invoices = this.getPendingPostingInvoices();
      return invoices.flightReceipts || [];
    },
    async openModal() {
      this.isLoading = true;
      await this.$store.dispatch("fetchPendingReceiptAndInvoice", {
        id: this.financialProfile?.public_id,
      });
      this.pendingPostingFlightInvoices = this.getPendingFlightInvoices();
      this.pendingPostingFlightReceipts = this.getPendingFlightReceipts();
      this.pendingPostingHotelInvoices = this.getPendingHotelInvoices();
      this.isLoading = false;
    },
    onCloseModal() {
      this.$emit("handleClose");
    },
  },
  watch: {
    isOpen(isDialogOpen) {
      if (isDialogOpen) {
        this.openModal();
      }
    },
  },
});
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

.financial-profile-name {
  font-weight: bold;
  font-size: 18px;
  color: black;
  margin: 0;
}

.financial-profile-name-sub-items {
  color: black;
  margin-right: 10px;
  font-weight: 600;
}

.financial-profile-status-container {
  color: black;
  margin-right: 10px;
  font-weight: 600;
}

.financial-profile-status-active {
  background: green;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}

.financial-profile-status-inactive {
  background: red;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}
.chip-width {
  max-width: max-content;
}
.postings {
  padding-left: 16px;
}
.accordions {
  border: none;
  margin: 0%;
  padding: 0%;
}
</style>
