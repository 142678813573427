import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5b9ae50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_ResultNotFound = _resolveComponent("ResultNotFound")!

  return (_ctx.$store.getters.isSalesMyTeamLoading)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_MCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_MTypography, {
              class: "description",
              type: "headline"
            }, {
              default: _withCtx(() => [
                _createTextVNode("My Team")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.myTeam.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myTeam, (item, index) => {
                return (_openBlock(), _createBlock(_component_MCard, { key: index }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgIconInfoBar, {
                      title: _ctx.renderSectoName(item.sector_name)
                    }, null, 8, ["title"]),
                    _createVNode(_component_ag_accordion, {
                      class: "flight_accordion_wrap margin_bottom_0",
                      "panel-value": [0]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_accordion_panel, null, {
                          tabSection: _withCtx(() => [
                            _createVNode(_component_MDataTable, {
                              headers: _ctx.headers,
                              data: item.my_team_members,
                              "item-per-page": 30,
                              "has-search": false,
                              simpleDataTable: true
                            }, null, 8, ["headers", "data"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_MCard, {
              key: 1,
              class: "m-card"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ResultNotFound, { title: "No Sales Team Found" })
              ]),
              _: 1
            }))
      ], 64))
}