export enum TravelerTypes {
  ADULT = "ADT",
  ADLT = "ADLT",
  CHILD = "CNN",
  INFANT = "INF",
  CHLD = "CHLD",
}

export function getTravelerTypeName(type: string) {
  switch (type) {
    case TravelerTypes.ADULT:
      return "Adult";
    case TravelerTypes.CHILD:
      return "Child";
    case TravelerTypes.INFANT:
      return "Infant";
    default:
      return "Adult";
  }
}

export function getMaterialTravelerTypeName(type: string) {
  switch (type) {
    case TravelerTypes.ADULT:
      return "Adult";
    case TravelerTypes.ADLT:
      return "Adult";
    case TravelerTypes.CHILD:
      return "Child";
    case TravelerTypes.CHLD:
      return "Child";
    case TravelerTypes.INFANT:
      return "Infant";
    default:
      return "Adult";
  }
}
export function getMaterialTravelerTypeIcon(type: string) {
  switch (type) {
    case TravelerTypes.ADULT:
      return "m-adult-icon";
    case TravelerTypes.ADLT:
      return "m-adult-icon";
    case TravelerTypes.CHILD:
      return "m-child-icon";
    case TravelerTypes.CHLD:
      return "m-child-icon";
    case TravelerTypes.INFANT:
      return "m-infant-icon";
    default:
      return "m-adult-icon";
  }
}
