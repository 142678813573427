import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cc08dd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src", "onError", "onClick"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = {
  key: 0,
  class: "featured"
}
const _hoisted_6 = { class: "news-wrapper" }
const _hoisted_7 = { class: "subtitle" }
const _hoisted_8 = { class: "modal-body" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "content" }
const _hoisted_11 = {
  key: 0,
  class: "featured"
}
const _hoisted_12 = { class: "news-wrapper" }
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_modal = _resolveComponent("ag-modal")!
  const _component_ImageLightBox = _resolveComponent("ImageLightBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.newsLoaded && _ctx.filteredNews.length > 0)
      ? (_openBlock(), _createBlock(_component_ag_div, {
          key: 0,
          class: "slider-container"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredNews, (news, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "slide",
                  key: index,
                  onClick: ($event: any) => (_ctx.onClickNewsHandler(index))
                }, [
                  _createElementVNode("img", {
                    class: "news-image",
                    src: news?.featured_image,
                    onError: ($event: any) => (
            news.featured_image = require('../../../assets/news-icon.png')
          ),
                    alt: "news image",
                    onClick: _withModifiers(($event: any) => (_ctx.onClickImageHandler(news.featured_image)), ["stop"])
                  }, null, 40, _hoisted_3),
                  _createElementVNode("div", _hoisted_4, [
                    (news?.show_on_banner === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Featured "))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_MTypography, {
                        class: "description title",
                        type: "body"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(news?.news_title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_MTypography, {
                          class: "description",
                          type: "label"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(news?.news_description), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _createVNode(_component_AGButton, {
                      class: "action",
                      onClick: ($event: any) => (_ctx.onNewsActionClickHandler(news))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(news?.call_to_action_label), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ], 8, _hoisted_2))
              }), 128))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ag_modal, {
      class: "news-modal",
      "is-open": _ctx.isNewModalOpened,
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isNewModalOpened = false)),
      "modal-width": "50%",
      persistent: _ctx.imageModal
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "close-action",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNewModalOpened = false))
          }),
          _createElementVNode("img", {
            class: "news-image",
            src: _ctx.filteredNews[_ctx.selectedNewsIndex]?.featured_image,
            onError: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.filteredNews[
              _ctx.selectedNewsIndex
            ].featured_image = require('../../../assets/news-icon.png')
          )),
            alt: "news image",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
            _ctx.onClickImageHandler(_ctx.filteredNews[_ctx.selectedNewsIndex].featured_image)
          ), ["stop"]))
          }, null, 40, _hoisted_9),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.filteredNews[_ctx.selectedNewsIndex]?.show_on_banner === true)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Featured "))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.filteredNews[_ctx.selectedNewsIndex]?.news_title), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.filteredNews[_ctx.selectedNewsIndex]?.news_description), 1)
            ]),
            _createVNode(_component_AGButton, {
              class: "action",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onNewsActionClickHandler(_ctx.filteredNews[_ctx.selectedNewsIndex])))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.filteredNews[_ctx.selectedNewsIndex]?.call_to_action_label), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["is-open", "persistent"]),
    _createVNode(_component_ImageLightBox, {
      state: _ctx.imageModal,
      "onUpdate:state": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.imageModal) = $event)),
      "image-src": _ctx.selectedImageSrc
    }, null, 8, ["state", "image-src"])
  ], 64))
}