import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_select = _resolveComponent("ag-select")!

  return (_openBlock(), _createBlock(_component_ag_select, {
    items: _ctx.financialProfilesItems,
    modelValue: _ctx.financialProfilePublicId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.financialProfilePublicId) = $event)),
    error: _ctx.error,
    value: _ctx.financialProfilePublicId,
    label: _ctx.label,
    variant: "outlined"
  }, null, 8, ["items", "modelValue", "error", "value", "label"]))
}