<template>
  <ag-modal :is-open="isOpen" modal-width="40%" @close="$emit('handleClose')">
    <template #body>
      <MTypography type="title"> Delete Organization </MTypography>
      <hr />
      <div>
        Are you really want to delete
        <b>{{ organization_name }} organization</b>?
      </div>
      <div class="delete-organization-footer">
        <div class="delete-organization-btn-container">
          <MButton
            class="delete-organization-reject_btn"
            @click="$emit('handleClose')"
            >No</MButton
          >
          <MButton :disabled="isLoading" @click="$emit('handleAccept')"
            >Yes</MButton
          >
        </div>
      </div>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MTypography, MButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "DeleteOrganizationConfirmationDialog",
  components: { MTypography, MButton },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organization_name: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isUpdatingOrganization;
    },
  },
});
</script>

<style lang="css" scoped>
.delete-organization-btn-container {
  display: flex;
  float: right;
}
.delete-organization-reject_btn {
  margin-right: 10px;
  background-color: var(--m-error-color);
}
.delete-organization-footer {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
}
</style>
