import { HotelLocationType, HotelSearchSuggestion } from "../types/hotel/index";

export const getPreHotelsSuggestions = (): HotelSearchSuggestion[] => {
  // ****************************************************************
  // ******* Prod Locations *******
  if (process.env.NODE_ENV === "production") {
    return [
      {
        search_id: "VW5V35ON2LS",
        display_name: "Karachi",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Tomb_Jinnah.jpg/150px-Tomb_Jinnah.jpg",
        description:
          "A vibrant metropolis with coastal beauty & historical landmarks.",
      },
      {
        search_id: "VW5V35IOQL0",
        display_name: "Lahore",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Royal_mosque_Lahore.jpg/268px-Royal_mosque_Lahore.jpg",
        description:
          "The cultural heart of Pakistan with rich history & Mughal architecture.",
      },
      {
        search_id: "VW5V35ULGSU",
        display_name: "Islamabad",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Faisal_Mosque%2C_Islamabad_III.jpg/266px-Faisal_Mosque%2C_Islamabad_III.jpg",
        description:
          "The serene capital city known for its greenery & the iconic Faisal Mosque.",
      },
      {
        search_id: "VW5V35IORWZ",
        display_name: "Mecca",
        sub_display_name: "Saudi Arabia",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRcKsoWVDpgVvpMii-qap7G9ekBg8Tzo-YhNmA2uTHwU6c0V1MoKDcPljpoVp0-dDY8",
        description:
          "The holiest city in Islam, attracting millions for the Hajj pilgrimage.",
      },
      {
        search_id: "VW5V366I4KF",
        display_name: "Medina",
        sub_display_name: "Saudi Arabia",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTjYcVXBiM-9MuQnZK-QPLRQKozIBuKmTbolTbg0-D486S12vxYmYUwVkqtMjRJDo4W",
        description:
          "The second holiest city in Islam, home to the Prophet's Mosque.",
      },
      {
        search_id: "VW5V35IORH8",
        display_name: "Dubai",
        sub_display_name: "United Arab Emirates",
        type: HotelLocationType.CITY,
        image:
          "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTnavcE0R1cW9vbZSJU9A_92W41dYEqmnQRVz51osw9lq-dYLXMRz2uqIIL1LrWzvQN",
        description: "A modern city famous for skyscrapers & luxury shopping.",
      },
      {
        search_id: "VW5V350TQEL",
        display_name: "Istanbul",
        sub_display_name: "Turkey",
        type: HotelLocationType.CITY,
        image:
          "https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcQMTvwtG7h0Qvf-Cb9yl9JomL5LGkuCdg9Y5Pu_K85MPufi-Mg9BWauCTjsTTey6qch",
        description:
          "A city where East meets West, with stunning mosques & rich culture.",
      },
      {
        search_id: "VW5V35IORM8",
        display_name: "Baku",
        sub_display_name: "Azerbaijan",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQ0D_WKwN09neI6JL37-wxJEcqbhAPL8jaVi8OUrZlHEGGthPEYQZbm6OR2XVIwPhfz",
        description:
          "An ancient city with modern attractions like the Flame Towers.",
      },
      {
        search_id: "VW5V34UVEH0",
        display_name: "Kuala Lumpur",
        sub_display_name: "Malaysia",
        type: HotelLocationType.CITY,
        image:
          "https://cdn.britannica.com/49/102749-050-B4874C95/Kuala-Lumpur-Malaysia.jpg",
        description:
          "A bustling city known for the Petronas Towers & diverse culture.",
      },
      {
        search_id: "VW5V360H9SX",
        display_name: "Phuket",
        sub_display_name: "Thailand",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Phuket_Aerial.jpg/800px-Phuket_Aerial.jpg",
        description:
          "A tropical paradise with beautiful beaches & vibrant nightlife.",
      },
      {
        search_id: "VW5V35CQEJE",
        display_name: "London",
        sub_display_name: "United Kingdom",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTF9OtLxnbxSpLFzyhzXERKiAbnHlG25WfRzxRTNcRiTk0lHvsOXNKH9KNAOvTWi_sS",
        description: "A global hub of history, culture, & iconic landmarks.",
      },
      {
        search_id: "VW5V35ON5MT",
        display_name: "Denpasar",
        sub_display_name: "Indonesia",
        type: HotelLocationType.CITY,
        image:
          "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcS1BWJs0qug7Cw0UQtD-4vLv_e_TzBdLftSukG8FSh_Vv_mFgh9irMws_LYErAuJvoX",
        description: "A gateway to Bali, offering vibrant markets & temples.",
      },
      {
        search_id: "VW5V35ULEKL",
        display_name: "Tokyo",
        sub_display_name: "Japan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/b/b2/Skyscrapers_of_Shinjuku_2009_January.jpg",
        description:
          "A bustling metropolis that blends tradition with futuristic innovations.",
      },
      {
        search_id: "VW5V35IOQJ8",
        display_name: "Bangkok",
        sub_display_name: "Thailand",
        type: HotelLocationType.CITY,
        image:
          "https://cdn.britannica.com/57/20057-004-404C9F85/Grand-Palace-Bangkok-Thailand.jpg",
        description:
          "A dynamic city known for its ornate temples & bustling markets.",
      },
      {
        search_id: "VW5V34UVEXI",
        display_name: "Berlin",
        sub_display_name: "Germany",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Museumsinsel_Berlin_Juli_2021_1_%28cropped%29.jpg/640px-Museumsinsel_Berlin_Juli_2021_1_%28cropped%29.jpg",
        description:
          "A city rich in history, with a blend of cultural heritage & modern art.",
      },
      {
        search_id: "VW5V34UVE7S",
        display_name: "Maldives",
        sub_display_name: "Maldives",
        type: HotelLocationType.CITY,
        image:
          "https://afar.brightspotcdn.com/dims4/default/5af1313/2147483647/strip/true/crop/3000x1592+0+323/resize/1440x764!/quality/90/?url=https%3A%2F%2Fafar-media-production-web.s3.us-west-2.amazonaws.com%2Fbrightspot%2Fb2%2Ff4%2F9a1ebe3f427f8e5eb937f8df8998%2Ftravelguides-maldives-videomediastudioeurope-shutterstock.jpg",
        description:
          "A tropical paradise known for its stunning white sand beaches.",
      },
    ];
  }
  // ****************************************************************
  // ******* Dev Locations *******
  return [
    {
      search_id: "W13HCO428FD",
      display_name: "Karachi",
      sub_display_name: "Pakistan",
      description:
        "A vibrant metropolis with coastal beauty & historical landmarks.",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Tomb_Jinnah.jpg/150px-Tomb_Jinnah.jpg",
    },
    {
      search_id: "W13HCOXTWOW",
      display_name: "Lahore",
      sub_display_name: "Pakistan",
      description:
        "The cultural heart of Pakistan with rich history & Mughal architecture.",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Royal_mosque_Lahore.jpg/268px-Royal_mosque_Lahore.jpg",
    },
    {
      search_id: "W13HCOXR4KW",
      display_name: "Islamabad",
      sub_display_name: "Pakistan",
      description:
        "The serene capital city known for its greenery & the iconic Faisal Mosque.",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Faisal_Mosque%2C_Islamabad_III.jpg/266px-Faisal_Mosque%2C_Islamabad_III.jpg",
    },
    {
      search_id: "W13HCNS5KZ1",
      display_name: "Mecca",
      sub_display_name: "Saudi Arabia",
      description:
        "The holiest city in Islam, attracting millions for the Hajj pilgrimage.",
      type: HotelLocationType.CITY,
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRcKsoWVDpgVvpMii-qap7G9ekBg8Tzo-YhNmA2uTHwU6c0V1MoKDcPljpoVp0-dDY8",
    },
    {
      search_id: "W13HCNM794Z",
      display_name: "Medina",
      sub_display_name: "Saudi Arabia",
      type: HotelLocationType.CITY,
      description:
        "The second holiest city in Islam, home to the Prophet's Mosque.",
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTjYcVXBiM-9MuQnZK-QPLRQKozIBuKmTbolTbg0-D486S12vxYmYUwVkqtMjRJDo4W",
    },
    {
      search_id: "W13HCO3ZFQV",
      display_name: "Dubai",
      sub_display_name: "United Arab Emirates",
      type: HotelLocationType.CITY,
      description: "A modern city famous for skyscrapers & luxury shopping.",
      image:
        "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTnavcE0R1cW9vbZSJU9A_92W41dYEqmnQRVz51osw9lq-dYLXMRz2uqIIL1LrWzvQN",
    },
    {
      search_id: "W13HCP3S8PZ",
      display_name: "Istanbul",
      sub_display_name: "Turkey",
      description:
        "A city where East meets West, with stunning mosques & rich culture.",
      type: HotelLocationType.CITY,
      image:
        "https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcQMTvwtG7h0Qvf-Cb9yl9JomL5LGkuCdg9Y5Pu_K85MPufi-Mg9BWauCTjsTTey6qch",
    },
    {
      search_id: "W13HCO428Y5",
      display_name: "Baku",
      sub_display_name: "Azerbaijan",
      type: HotelLocationType.CITY,
      description:
        "An ancient city with modern attractions like the Flame Towers.",
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQ0D_WKwN09neI6JL37-wxJEcqbhAPL8jaVi8OUrZlHEGGthPEYQZbm6OR2XVIwPhfz",
    },
    {
      search_id: "W13HCOFYWKI",
      display_name: "Kuala Lumpur",
      sub_display_name: "Malaysia",
      type: HotelLocationType.CITY,
      description:
        "A bustling city known for the Petronas Towers & diverse culture.",
      image:
        "https://cdn.britannica.com/49/102749-050-B4874C95/Kuala-Lumpur-Malaysia.jpg",
    },
    {
      search_id: "W13HCNY1O32",
      display_name: "Phuket",
      sub_display_name: "Thailand",
      description:
        "A tropical paradise with beautiful beaches & vibrant nightlife.",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Phuket_Aerial.jpg/800px-Phuket_Aerial.jpg",
    },
    {
      search_id: "W13HCNM78AQ",
      display_name: "London",
      sub_display_name: "United Kingdom",
      description: "A global hub of history, culture, & iconic landmarks.",
      type: HotelLocationType.CITY,
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTF9OtLxnbxSpLFzyhzXERKiAbnHlG25WfRzxRTNcRiTk0lHvsOXNKH9KNAOvTWi_sS",
    },
    {
      search_id: "W13HCNS5LRS",
      display_name: "Denpasar",
      sub_display_name: "Indonesia",
      description: "A gateway to Bali, offering vibrant markets & temples.",

      type: HotelLocationType.CITY,
      image:
        "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcS1BWJs0qug7Cw0UQtD-4vLv_e_TzBdLftSukG8FSh_Vv_mFgh9irMws_LYErAuJvoX",
    },
    {
      search_id: "W13HCOXTWN5",
      display_name: "Tokyo",
      sub_display_name: "Japan",
      description:
        "A bustling metropolis that blends tradition with futuristic innovations.",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/b/b2/Skyscrapers_of_Shinjuku_2009_January.jpg",
    },
    {
      search_id: "W13HCOXTWNX",
      display_name: "Bangkok",
      sub_display_name: "Thailand",
      type: HotelLocationType.CITY,
      description:
        "A dynamic city known for its ornate temples & bustling markets.",
      image:
        "https://cdn.britannica.com/57/20057-004-404C9F85/Grand-Palace-Bangkok-Thailand.jpg",
    },
    {
      search_id: "W13HCO9XRNO",
      display_name: "Berlin",
      sub_display_name: "Germany",
      description:
        "A city rich in history, with a blend of cultural heritage & modern art.",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Museumsinsel_Berlin_Juli_2021_1_%28cropped%29.jpg/640px-Museumsinsel_Berlin_Juli_2021_1_%28cropped%29.jpg",
    },
    {
      search_id: "W13HCORSRFL",
      display_name: "Maldives",
      sub_display_name: "Maldives",
      description:
        "A tropical paradise known for its stunning whitesand beaches.",
      type: HotelLocationType.CITY,
      image:
        "https://afar.brightspotcdn.com/dims4/default/5af1313/2147483647/strip/true/crop/3000x1592+0+323/resize/1440x764!/quality/90/?url=https%3A%2F%2Fafar-media-production-web.s3.us-west-2.amazonaws.com%2Fbrightspot%2Fb2%2Ff4%2F9a1ebe3f427f8e5eb937f8df8998%2Ftravelguides-maldives-videomediastudioeurope-shutterstock.jpg",
    },
  ];
};
