import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import GroupFlightsList from "@/modules/FDG/views/GroupFlightsList.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import GroupFlightDetail from "@/modules/FDG/views/GroupFlightDetail.vue";
import BookingConfirmationForm from "@/modules/FDG/views/BookingConfirmationForm.vue";
import UmrahList from "@/modules/FDG/views/UmrahList.vue";
import UmrahDetail from "@/modules/FDG/views/UmrahDetail.vue";
import BookingDetails from "@/modules/FDG/views/BookingDetails.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.FDG_FLIGHTS,
    name: "fdgList",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.PERMISSIONS,
    },
    component: GroupFlightsList,
  },
  {
    path: PATH.FDG_FLIGHT_DETAIL,
    name: "fdgDetail",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.PERMISSIONS,
    },
    component: GroupFlightDetail,
  },
  {
    path: PATH.FDG_FLIGHT_BOOKING_CONFIRMATION,
    name: "groupFlightBookingConfirmation",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.PERMISSIONS,
    },
    component: BookingConfirmationForm,
  },
  {
    path: PATH.FDG_UMRAH,
    name: "umrah",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.PERMISSIONS,
    },
    component: UmrahList,
  },
  {
    path: PATH.FDG_UMRAH_DETAIL,
    name: "umrahDetail",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_UMRAH.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_UMRAH.PERMISSIONS,
    },
    component: UmrahDetail,
  },
  {
    path: PATH.FDG_BOOKING_DETAILS,
    name: "groupFlightDetails",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.PERMISSIONS,
    },
    component: BookingDetails,
  },
];

export default routes;
