<template>
  <div class="user-profile">
    <ag-card class="up-container">
      <div class="profile-header">
        <ag-heading variant="h3" title="Preferences Management" />
        <ag-heading variant="p" title="Update your preferences" />
        <hr />
      </div>

      <div class="user-profile">
        <div class="up-input">
          <div class="label">Username</div>

          <AGTextField
            v-model="preferences.username"
            :value="preferences.username"
            type="text"
            label=""
            class="up-text-field"
            disabled="true"
            :isSmall="true"
          />
        </div>

        <div class="up-input">
          <div class="label">Email</div>

          <AGTextField
            v-model="preferences.email"
            :value="preferences.email"
            type="email"
            label=""
            class="up-text-field"
            disabled="true"
          />
        </div>

        <div class="up-input">
          <div class="label">Phone Number</div>

          <AgPhoneField
            class="up-text-field up-phone-field"
            test-id=""
            :default-value="preferences.phoneNumber"
            :error="preferencesErrors['contact_number']"
            @update-value="onParsePhoneNumberHandler"
          />
        </div>

        <div class="up-input">
          <div class="label">WhatsApp Number</div>

          <AgPhoneField
            class="up-text-field up-phone-field"
            test-id=""
            :default-value="preferences.whatsappNumber"
            :error="preferencesErrors['whatsapp_number']"
            @update-value="onParseWhatsAppNumberHandler"
          />
        </div>

        <div class="up-input">
          <div class="label">Return flights default view</div>

          <v-switch
            class="up-toggle"
            color="primary"
            inset
            v-model="preferences.flightPairViewToggle"
            :error="preferencesErrors['flight_search_pairs_view']"
            :disabled="isPreferencesLoading"
            :label="`${
              preferences.flightPairViewToggle ? 'Pair View' : 'Single View'
            }`"
          />
        </div>

        <MButton
          @click="onUpdatePreferencesHandler"
          :disabled="isPreferencesLoading"
        >
          Update
        </MButton>
      </div>
    </ag-card>

    <ag-card class="up-container">
      <div class="profile-header">
        <ag-heading variant="h3" title="Password Management" />
        <ag-heading variant="p" title="Change your password" />
        <hr />
      </div>

      <div class="">
        <MButton @click="onChangePasswordHandler"> Change Password </MButton>
      </div>
    </ag-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  IProfilePreferences,
  PhoneFieldValue,
} from "@/ag-portal-common/interfaces/profile.interface";

import { preferencesUpdateValidation } from "@/modules/Profile/validations/preferencesUpdateValidation";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { User } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "userProfile",
  data() {
    return {
      preferences: {
        username: "",
        email: "",
        phoneNumber: "",
        whatsappNumber: "",
        flightPairViewToggle: false,
      },

      isPreferencesLoading: false,
      preferencesErrors: {} as { [index: string]: string },

      isPrefPhoneNumberValid: false,
      isPrefWhatsappNumberValid: false,
    };
  },
  computed: {
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },

    savedPreferences(): IProfilePreferences {
      const savedPreferences = localStorage.getItem("user-preferences");

      if (savedPreferences) {
        return JSON.parse(savedPreferences);
      }

      return this.$store.state.profileModule.preferences;
    },
  },
  watch: {
    savedPreferences() {
      this.onSyncPreferencesHandler();
    },
  },
  created() {
    if (this.user) {
      this.preferences.email = this.user.email;
      this.preferences.username = `${this.user.first_name} ${this.user.last_name}`;
    }

    this.onSyncPreferencesHandler();
  },
  methods: {
    async onUpdatePreferencesHandler() {
      this.isPreferencesLoading = true;
      this.preferencesErrors = {};

      this.onValidatePhoneNumbers();

      try {
        if (!this.isPrefPhoneNumberValid || !this.isPrefWhatsappNumberValid) {
          throw new Error("Invalid Data");
        }

        const { phoneNumber, whatsappNumber, flightPairViewToggle } =
          this.preferences;

        const payload = await preferencesUpdateValidation.validate(
          {
            contact_number: phoneNumber,
            whatsapp_number: whatsappNumber,
            flight_search_pairs_view: flightPairViewToggle,
          },
          { abortEarly: false }
        );

        const agentId = (this.user as any).agent.agent_id as string;

        await this.$store.dispatch("updatePreferences", {
          body: payload,
          agentId,
        });
      } catch (exception) {
        if (exception instanceof ValidationError) {
          this.preferencesErrors = yupValidationErrorAsSchema(exception);
        }
      } finally {
        this.isPreferencesLoading = false;
      }
    },
    onChangePasswordHandler() {
      this.$router.push("/profile/changePassword");
    },

    onSyncPreferencesHandler() {
      this.preferences.phoneNumber = this.savedPreferences.contact_number;
      this.preferences.whatsappNumber = this.savedPreferences.whatsapp_number;
      this.preferences.flightPairViewToggle =
        this.savedPreferences.flight_search_pairs_view;
    },
    onValidatePhoneNumbers() {
      this.preferencesErrors = {
        contact_number: !this.isPrefPhoneNumberValid
          ? "Invalid phone number"
          : "",
        whatsapp_number: !this.isPrefWhatsappNumberValid
          ? "Invalid whatsapp number"
          : "",
      };
    },
    onParsePhoneNumberHandler(value: PhoneFieldValue) {
      if (value.e164) {
        this.preferences.phoneNumber = value.e164;

        this.isPrefPhoneNumberValid = value.isValid;

        this.onValidatePhoneNumbers();
      }
    },
    onParseWhatsAppNumberHandler(value: PhoneFieldValue) {
      if (value.e164) {
        this.preferences.whatsappNumber = value.e164;

        this.isPrefWhatsappNumberValid = value.isValid;

        this.onValidatePhoneNumbers();
      }
    },
  },
});
</script>

<style lang="scss">
.up-toggle {
  margin-bottom: 10px;

  .v-selection-control__wrapper {
    color: #dadada;
  }

  .v-selection-control {
    min-height: 0 !important;
  }
}

.up-text-field {
  position: relative;

  .v-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-input__control {
      flex: 1;
    }

    .v-input__append {
      right: 2px;
      background-color: white;
      padding: 10px;
      margin: 0;
    }

    .error-text {
      width: calc(100% - 10px);
      position: absolute;
      inset: 58px 10px;
    }
  }
}

.up-phone-field {
  margin-bottom: 30px;

  .error-text {
    margin-left: 10px;
  }

  .m-input {
    height: 56px !important;
  }

  .m-input-wrapper {
    border-width: 1px !important;
  }
}
</style>

<style scoped lang="scss">
[disabled="true"] {
  opacity: 0.5 !important;
  pointer-events: none;
  user-select: none;
  cursor: not-allowed;
}

.up-container {
  margin: 0 auto 16px;
}

.user-profile {
  width: 100%;
  padding: 4px 0 0;

  .up-text-field {
    max-width: 360px;
    .v-input {
      margin: 0;
    }
  }
  .up-input {
    .label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 12px;
  }
}

@media screen and (max-width: 470px) {
  .up-action {
    width: 100% !important;
  }
}
</style>
