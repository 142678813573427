<template>
  <MCard class="organization-snapshot-header">
    <div class="field-container">
      <OrganizationAutocomplete
        @onChange="handleOrganizationChange"
        :default-value="lastOrganizationId"
      />
    </div>
    <ShimmerCard
      card-height="400px"
      v-if="$store.getters.isOrganizationStatsLoading"
    />
    <template v-else>
      <template v-if="showDetails">
        <div class="name-container">
          <img :src="organizationStats?.organization?.org_logo" />
          <div class="agency-name-container">
            <p class="agency-name">{{ renderOrgName() }}</p>
            <p>
              <a
                class="website-slug"
                :href="`https://${generateWebsiteDomain(renderWebsite())}`"
                target="_blank"
              >
                {{ generateWebsiteDomain(renderWebsite()) }}
              </a>
            </p>
          </div>
        </div>
        <hr />
        <MCard>
          <ag-row>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Owners"
                :value="organizationStats?.owner_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-my-team"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Sub Agents"
                :value="organizationStats?.sub_agent_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-my-team"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Employees"
                :value="organizationStats?.employee_count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-my-team"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Website Status"
                :value="organizationStats?.organization?.website_status"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-website-management"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Theme Color"
                :value="renderColorCode()"
              >
                <template #icon>
                  <!-- <ag-color-chip class="color-chip" :color-name="renderColorCode()" :color-code="renderColorCode()" /> -->

                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-color-icon"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
          </ag-row>
        </MCard>

        <MAccordion :initial-open="false">
          <template v-slot:title>
            <AgIconInfoBar
              mPrepandIcon="m-agents"
              title="Organization Users"
              class="accordion-header"
            />
          </template>
          <template #content>
            <MDataTable
              :headers="headers"
              :data="users() || []"
              :item-per-page="10"
              :has-search="true"
            >
              <template #last_login="{ item }">
                <MTypography
                  type="body"
                  class="description td-full-width"
                  v-if="item.formatted_last_login_date"
                >
                  {{
                    getFormattedDateTime(
                      item.formatted_last_login_date,
                      FORMAT_DD_MMM_YYYY_WITH_DASH
                    )
                  }}
                </MTypography>
                <MTypography type="label" class="description td-full-width">
                  {{
                    item.formatted_last_login_date
                      ? getFormattedDateTime(
                          item.formatted_last_login_date,
                          FORMAT_HH_SS_24_HOURS
                        )
                      : "-"
                  }}
                </MTypography>
              </template>
            </MDataTable>
          </template>
        </MAccordion>
      </template>
      <ResultNotFound v-if="showResultNotFound" />
    </template>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDistance } from "date-fns";
import _ from "lodash";

import {
  IOrganizationStats,
  OrganizationUserInfo,
} from "@/ag-portal-common/interfaces/organizationStatistics.interface";
import OrganizationAutocomplete from "@/modules/Organization/components/OrganizationAutocomplete.vue";
import {
  formatQueryPath,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import ShimmerCard from "@/components/ShimmerCard.vue";
import ResultNotFound from "../OrganizationDetails/ResultNotFound.vue";
import {
  MDataTable,
  MAccordion,
  MCard,
  MTypography,
} from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import MSnapshotItemCountTile from "../MSnapshotItemCountTile.vue";
import {
  FORMAT_HH_SS_24_HOURS,
  FORMAT_DD_MMM_YYYY_WITH_DASH,
} from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "OrganizationSnapshotAnalyticsTab",
  components: {
    OrganizationAutocomplete,
    ShimmerCard,
    ResultNotFound,
    MDataTable,
    MAccordion,
    MCard,
    MSnapshotItemCountTile,
    MTypography,
  },
  data(): {
    headers: MDataTableHeader[];
    lastOrganizationId: string;
    isInitial: boolean;
    FORMAT_DD_MMM_YYYY_WITH_DASH: string;
    FORMAT_HH_SS_24_HOURS: string;
  } {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS,
      headers: [
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
          align: "left",
          contentAlign: "left",
        },
        {
          title: "User Type",
          value: "user_type",
          key: "user_type",
          sortable: true,
        },
        {
          title: "Last Login",
          value: "last_login",
          key: "last_login",
          sortable: true,
        },
        {
          title: "Days Ago",
          value: "days_ago",
          key: "days_ago",
          sortable: true,
        },
      ],
      lastOrganizationId: "",
      isInitial: true,
    };
  },
  methods: {
    getFormattedDateTime,
    generateWebsiteDomain(slug: string) {
      const isDev = process.env.NODE_ENV === "development";
      return `${slug}${isDev ? ".dev" : ""}.web.aeroglobe.pk`;
    },
    formatLastLogin(date: Date | null): string {
      return formatDistance(date ? date : new Date(), new Date(), {
        addSuffix: true,
      });
    },
    handleOrganizationChange(value: string) {
      if (value) {
        const path = `${PATH.ORGANIZATION_SNAPSHOT}${formatQueryPath({
          org_id: value,
        })}`;
        this.$router.push(path);
      }
    },
    fetchOrganization() {
      const { org_id } = this.$route.query;
      this.isInitial = false;
      if (org_id) {
        this.$store.dispatch("getOrganizationStats", org_id);
      }
    },
    renderOrgName() {
      return this.organizationStats?.organization?.org_name || "Aeroglobe";
    },
    renderWebsite() {
      return this.organizationStats?.organization?.website_slug || "Aeroglobe";
    },
    renderColorCode() {
      return this.organizationStats?.organization?.org_default_color;
    },
    users() {
      const Users = this.organizationStats?.users || [];
      const formatedUsers = Users.map((item: OrganizationUserInfo) => {
        return {
          email: item.email,
          last_login: item.last_logged_in_at_iso
            ? new Date(item.last_logged_in_at_iso)
            : "",
          formatted_last_login_date: item.last_logged_in_at_iso,
          user_type: item.user_type.replace("_", " "),
          days_ago: item.days_ago,
        };
      });
      return formatedUsers;
    },
  },
  computed: {
    websiteEnabled() {
      return "Enabled";
    },
    isStatsFetching(): boolean {
      return this.$store.getters.isOrganizationStatsLoading;
    },
    organizationStats(): IOrganizationStats {
      return this.$store.getters.organizationStats;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.organizationStats);
      return isNotEmpty;
    },
    showResultNotFound(): boolean {
      return !this.isInitial && !this.isStatsFetching && !this.showDetails;
    },
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          if (this.lastOrganizationId !== value?.org_id) {
            this.fetchOrganization();
            this.lastOrganizationId = value?.org_id;
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch("fetchOrganizations");
  },
});
</script>

<style lang="scss" scoped>
.organization-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    margin-top: 40px;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}
.white-icon {
  color: #fff;
}

.accordion-header {
  border: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
</style>
