import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37257b13"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "flight_search_box",
  class: "combine_flight_search combobox-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MAirportComboBox = _resolveComponent("MAirportComboBox")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_MDatePicker = _resolveComponent("MDatePicker")!
  const _component_MTravelerCountBoxItem = _resolveComponent("MTravelerCountBoxItem")!
  const _component_MTravelerCountBox = _resolveComponent("MTravelerCountBox")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createBlock(_component_ag_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "7"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ag_row, { "test-id": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MAirportComboBox, {
                      inputValue: _ctx.selectedOriginIata,
                      label: "From",
                      options: _ctx.renderOriginPlaces(),
                      "prepend-icon": "m-flight-departure-icon",
                      placeholder: "Leave from",
                      "is-loading": _ctx.loadingOriginAirports,
                      "onUpdate:inputValue": _ctx.handleSelectOriginItem,
                      "onUpdate:inputText": _ctx.handleOriginMAirportInputText,
                      "has-error": !!_ctx.errors?.origin,
                      "error-message": _ctx.errors?.origin
                    }, null, 8, ["inputValue", "options", "is-loading", "onUpdate:inputValue", "onUpdate:inputText", "has-error", "error-message"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", {
                  class: _normalizeClass(["m-swap-flights-icon-container m-index-level-one", { flipped: _ctx.swapIconFlipped }]),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.swapFields && _ctx.swapFields(...args))),
                  variant: "link"
                }, [
                  _createVNode(_component_MIcon, { name: "m-swap-flight-icon" })
                ], 2),
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MAirportComboBox, {
                      inputValue: _ctx.selectedDestinationIata,
                      label: "To",
                      options: _ctx.renderDestinationPlaces(),
                      "prepend-icon": "m-flight-arrival-icon",
                      placeholder: "Going to",
                      "is-loading": _ctx.loadingDestinationAirports,
                      "onUpdate:inputValue": _ctx.handleSelectDestinationItem,
                      "onUpdate:inputText": _ctx.handleDestinationMAirportInputText,
                      "has-error": !!_ctx.errors?.destination,
                      "error-message": _ctx.errors?.destination,
                      foucsInput: _ctx.nextFocusInputDest
                    }, null, 8, ["inputValue", "options", "is-loading", "onUpdate:inputValue", "onUpdate:inputText", "has-error", "error-message", "foucsInput"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "5",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ag_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MDatePicker, {
                      label: "Departure Date",
                      startDate: _ctx.startDate,
                      endDate: _ctx.endDate,
                      "min-date": _ctx.minimumDate,
                      onOnRangeDateChange: _ctx.onRangeDateChange,
                      onRangeStartDate: _ctx.onRangeStartDepartureDate,
                      placeholder: "Departing",
                      dateType: "startDate",
                      hasRange: _ctx.isReturnFlight,
                      multiCalendar: _ctx.isMultiCalendar,
                      foucsInput: _ctx.nextFocusDatePicker,
                      position: "center",
                      "auto-position": false,
                      onHandleDatePickerFocused: _ctx.handleDepartureDatePickerFocused,
                      isFocused: _ctx.isDepartureDatePickerFocused,
                      error: _ctx.errors?.departure_date
                    }, null, 8, ["startDate", "endDate", "min-date", "onOnRangeDateChange", "onRangeStartDate", "hasRange", "multiCalendar", "foucsInput", "onHandleDatePickerFocused", "isFocused", "error"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ag_column, {
                  xs: "12",
                  sm: "12",
                  md: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MDatePicker, {
                      label: "Return Date",
                      startDate: _ctx.startDate,
                      endDate: _ctx.endDate,
                      "min-date": _ctx.minimumDate,
                      onOnRangeDateChange: _ctx.onRangeDateChange,
                      onRangeStartDate: _ctx.onRangeStartArrivalDate,
                      placeholder: "Returning",
                      dateType: "endDate",
                      disabled: _ctx.disableReturnDatePicker,
                      multiCalendar: _ctx.isMultiCalendar,
                      hasRange: _ctx.isReturnFlight,
                      position: "right",
                      "auto-position": false,
                      onHandleDatePickerFocused: _ctx.handleReturnDatePickerFocused,
                      isFocused: _ctx.isReturnDatePickerFocused,
                      error: _ctx.errors?.return_date,
                      ref: "MReturnDatePickerRef"
                    }, null, 8, ["startDate", "endDate", "min-date", "onOnRangeDateChange", "onRangeStartDate", "disabled", "multiCalendar", "hasRange", "onHandleDatePickerFocused", "isFocused", "error"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "7",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "6",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MTravelerCountBox, {
                    label: "Travelers",
                    "prepend-icon": "m-traveler-count-icon",
                    "adult-count": _ctx.adultCount,
                    "child-count": _ctx.childCount,
                    "infant-count": _ctx.infantCount
                  }, {
                    items: _withCtx(() => [
                      _createVNode(_component_MTravelerCountBoxItem, {
                        name: "adultCount",
                        label: "Adult",
                        icon: "m-adult-traveler-icon",
                        hint: "(12 years and above)",
                        count: _ctx.adultCount,
                        "onUpdate:adultCount": _ctx.updateAdultCount
                      }, null, 8, ["count", "onUpdate:adultCount"]),
                      _createVNode(_component_MTravelerCountBoxItem, {
                        name: "childCount",
                        label: "Children",
                        icon: "m-child-traveler-icon",
                        hint: "(2 to 11 years)",
                        count: _ctx.childCount,
                        "onUpdate:childCount": _ctx.updateChildCount
                      }, null, 8, ["count", "onUpdate:childCount"]),
                      _createVNode(_component_MTravelerCountBoxItem, {
                        name: "infantCount",
                        label: "Infants",
                        icon: "m-infant-traveler-icon",
                        hint: "(0 to less than 2)",
                        count: _ctx.infantCount,
                        "onUpdate:infantCount": _ctx.updateInfantCount
                      }, null, 8, ["count", "onUpdate:infantCount"])
                    ]),
                    _: 1
                  }, 8, ["adult-count", "child-count", "infant-count"])
                ]),
                _: 1
              }),
              (_ctx.isSuperUserOrOperationUser)
                ? (_openBlock(), _createBlock(_component_ag_column, {
                    key: 0,
                    xs: "12",
                    sm: "12",
                    md: "6",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "financial-profile-combobox", {}, undefined, true)
                    ]),
                    _: 3
                  }))
                : (_openBlock(), _createBlock(_component_ag_column, {
                    key: 1,
                    xs: "12",
                    sm: "12",
                    md: "6",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MCombobox, {
                        inputValue: _ctx.selectedCabinClass,
                        "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCabinClass) = $event)),
                        label: "Cabin Class",
                        itemValue: "value",
                        itemLabel: "label",
                        options: _ctx.CabinClassOptions,
                        class: "no-margin",
                        focusedBorderEnabled: ""
                      }, null, 8, ["inputValue", "options"])
                    ]),
                    _: 1
                  }))
            ]),
            _: 3
          })
        ]),
        _: 3
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "5",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_row, null, {
            default: _withCtx(() => [
              (_ctx.isSuperUserOrOperationUser)
                ? (_openBlock(), _createBlock(_component_ag_column, {
                    key: 0,
                    xs: "12",
                    sm: "12",
                    md: "6",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MCombobox, {
                        inputValue: _ctx.selectedCabinClass,
                        "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCabinClass) = $event)),
                        label: "Cabin Class",
                        itemValue: "value",
                        itemLabel: "label",
                        options: _ctx.CabinClassOptions,
                        class: "no-margin",
                        focusedBorderEnabled: ""
                      }, null, 8, ["inputValue", "options"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "6",
                cols: "12",
                class: "search-btn-container"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MButton, {
                    type: "square",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFlightSearch()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Search Flights ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}