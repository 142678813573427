<template>
  <ag-modal :is-open="isOpen" modal-width="40%" @close="$emit('handleClose')">
    <template #body>
      <AgHeading title="Approve Organization" variant="h3" test-id="" />
      <AgDiv
        >Are you sure to approve <b>{{ organization_name }}</b
        >?</AgDiv
      >
      <p>
        <b>Note:</b> Please make sure before approving this organization you
        have already CREATED and LINKED <b>Financial Profile</b> to this
        organization.
      </p>
      <AgDiv class="btn_container">
        <AGButton
          class="reject_btn"
          test-id=""
          @click="$emit('handleClose')"
          variant="danger"
          type="button"
          >Close
        </AGButton>
        <AGButton
          :disbaled="isLoading"
          :is-loading="isLoading"
          test-id=""
          @click="$emit('handleAccept')"
          variant="primary"
          type="button"
          >Approve</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ApproveOrganizationDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organization_name: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters.isEliteOrganizationStatusUpdating;
    },
  },
});
</script>

<style scoped>
.btn_container {
  display: flex;
  float: right;
}
.reject_btn {
  margin-right: 10px;
}
</style>
