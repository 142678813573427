<template>
  <AgTabs class="tabs-container" test-id="test">
    <template #TabHeading>
      <v-tab value="one">
        <ag-icon test-id="flightIcon"></ag-icon>
        <span>Flight Issuance</span>
      </v-tab>
      <v-tab value="two">
        <ag-icon test-id="hotelIcon"></ag-icon>
        <span>Hotel Issuance</span></v-tab
      >
    </template>
    <template #TabDetail>
      <v-window-item value="one" transition="fade" reverse-transition="fade">
        <FlightIssuanceChart
      /></v-window-item>
      <v-window-item value="two" transition="fade" reverse-transition="fade">
        <HotelIssuanceChart />
      </v-window-item>
    </template>
  </AgTabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HotelIssuanceChart from "../../Dashboard/components/HotelIssuanceChart.vue";
import FlightIssuanceChart from "../../Dashboard/components/FlightIssuanceChart.vue";

export default defineComponent({
  name: "DailyIssuancePage",
  components: {
    HotelIssuanceChart,
    FlightIssuanceChart,
  },
});
</script>
