<template>
  <ag-modal :is-open="isOpen" @close="closeModal" modal-width="40%">
    <template #header>
      <v-card-title>Add Agent</v-card-title>
      <a-g-button
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'add-agent-modal-close')"
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <form @submit="onSubmit">
        <ag-select
          :items="roles"
          v-model="role"
          :value="role"
          required
          :testId="
            genTestId(ELEMENT_TYPES.SELECT_FIELD, 'add-agent-modal-role')
          "
          variant="outlined"
          label="Role"
          :error="errors['role']"
        />

        <a-g-text-field
          label="Name"
          v-model="full_name"
          :testId="
            genTestId(ELEMENT_TYPES.TEXT_FIELD, 'add-agent-modal-full_name')
          "
          :error="errors['full_name']"
        />
        <a-g-text-field
          label="Email"
          type="email"
          :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'add-agent-modal-email')"
          v-model="email"
          :error="errors['email']"
        />
        <a-g-button
          type="submit"
          :disabled="$store.getters.isAddingAgent"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'add-agent-modal-submit')"
          :isLoading="$store.getters.isAddingAgent"
          class="submit-btn"
          >Submit
        </a-g-button>
      </form>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { agentAddValidation } from "@/modules/Organization/validations/agentAdd.validation";
import { ValidationError } from "yup";
import {
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "AddAgentModal",
  events: ["handleClose"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    isUserAgent() {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
  },
  created() {
    if (this.isUserAgent) {
      this.roles.push(AGENT_SUB_ROLES.SUB_AGENT, AGENT_SUB_ROLES.EMPLOYEE);
    } else if (this.isSuperUser) {
      this.roles.push(AGENT_SUB_ROLES.OWNER);
    }
  },
  methods: {
    genTestId,
    closeModal() {
      this.$emit("handleClose");
    },
    async onSubmit(e: any) {
      e.preventDefault();
      try {
        const payload = await agentAddValidation.validate(
          {
            full_name: this.full_name,
            email: this.email,
            role: this.role,
          },
          { abortEarly: false }
        );
        this.$emit("onSubmit", payload);
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
  },
  data(): {
    roles: Array<AGENT_SUB_ROLES>;
    full_name: string;
    email: string;
    role: AGENT_SUB_ROLES;
    errors: { [index: string]: string };
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
  } {
    return {
      ELEMENT_TYPES,
      errors: {},
      roles: [],
      full_name: "",
      email: "",
      role: UTILS.isUser(USER_ROLES.AGENT)
        ? AGENT_SUB_ROLES.SUB_AGENT
        : AGENT_SUB_ROLES.OWNER,
    };
  },
});
</script>

<style lang="scss" scoped>
.submit-btn {
  float: right;
}
</style>
