<template>
  <AgCard>
    <template v-if="isAGSuperUser()">
      <ag-loader v-if="$store.getters.isFetchingOrganizations" />
      <template v-else>
        <AgDiv class="autocomplete-container">
          <p class="label">Select Organization</p>
          <OrganizationAutocomplete @onChange="handleOrganizationChange" />
        </AgDiv>
      </template>
    </template>
    <template v-if="showWhatsAppManagement">
      <AgDiv class="header-title-container">
        <AgDiv>
          <ag-heading variant="h2" :title="renderOrganizationName" />
          <p class="paragraph">
            You can manage your whatsapp numbers authorized to contact our
            support team here. Authorized numbers can contact our support team
            for all support matters: Ticket Issuance, Refund, Reissue, Billing,
            Payments, Hotels and everything else.
          </p>
          <p>
            For more details contact
            <a
              class="whatsapp_link"
              href="https://wa.me/923111145623"
              target="_blank"
              ><AgIcon
                name="whatsappIcon"
                class="whatsapp-management-whatsappIcon"
              />
              support
            </a>
          </p>
        </AgDiv>
        <MButton
          class="add-whatsapp-member"
          @click="handleNewWhatsappMember"
          :disabled="isWhatsappMembersLoading"
        >
          Add New Whatsapp Member
        </MButton>
      </AgDiv>
      <ag-loader v-if="isWhatsappMembersLoading" />
      <template v-else>
        <AgNotFound
          v-if="showNoReults()"
          test-id=""
          heading="No Whatsapp Member Found"
        />
        <MDataTable
          v-else
          :headers="membersTableHeader"
          :data="whatsappMembers"
          :items-per-page="10"
          :has-pagination="true"
          :has-search="false"
          :simpleDataTable="true"
        >
          <template #is_authorized="{ item }">
            <div class="icon-cell-container">
              <RoundCheckCrossIcon :is-check="item.is_authorized" />
            </div>
          </template>
          <template #remove="{ item }">
            <div class="delete-btn-container">
              <MFabButton
                class="delete-icon"
                icon="m-delete"
                @click="handleDelete($event, item)"
              />
            </div>
          </template>
          <template #action="{ item }">
            <div class="whatsapp-action-btn-container">
              <div>
                <MButton
                  @click="handleGenerateOTP($event, item)"
                  type="filled"
                  :disabled="isGenerateOTPLoading(item)"
                >
                  {{ item.is_authorized ? "Unauthorize" : "Authorize" }}
                </MButton>
              </div>
            </div>
          </template>
        </MDataTable>
      </template>
    </template>
    <AgNotFound v-else test-id="" heading="Please Select Organization" />
  </AgCard>

  <DeleteWhatsappMemberConfirmationModel
    :is-loading="isConfirmationDialogLoading"
    :is-open="confirmationDialogOpen"
    @handleAccept="handleConfirmDeleteWhatsappMember"
    @handleReject="handleDialogClose"
  />
  <OTPInputModal
    :is-open="isOTPModalOpen"
    :isSubmitLoading="$store.getters.isWhatsappActionLoading"
    @submitOtp="handleSubmitOTP"
    @handleCloseDialog="handleCloseOTPDialog"
  />
  <AddWhatsappMemberModal
    :is-open="isAddWhatsappMemberModalOpen"
    @closeModal="handleCloseAddWhatsappMemberModal"
  />
</template>

<script lang="ts">
import RoundCheckCrossIcon from "@/modules/Organization/components/OrganizationDetails/CheckCrossIconComponent.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { formatQueryPath, genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { defineComponent } from "vue";
import _ from "lodash";

import { IWhatsappMember } from "@/ag-portal-common/interfaces/whatsappMember.interface";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import AddWhatsappMemberModal from "@/modules/Organization/components/AddWhatsappMemberModal.vue";
import DeleteWhatsappMemberConfirmationModel from "@/modules/Organization/components/DeleteWhatsappMember.vue";
import OTPInputModal from "@/ag-portal-common/components/OTPInputModal.vue";
import OrganizationAutocomplete from "@/modules/Organization/components/OrganizationAutocomplete.vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import UTILS from "@/ag-portal-common/utils";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "WhatsappManagement",
  components: {
    AddWhatsappMemberModal,
    DeleteWhatsappMemberConfirmationModel,
    OTPInputModal,
    OrganizationAutocomplete,
    RoundCheckCrossIcon,
  },
  mounted() {
    if (!this.isAGSuperUser()) {
      this.fetchMembersWithClearState();
    }
  },
  methods: {
    genTestId,
    handleDelete(e: Event, whatsappMember: IWhatsappMember) {
      e.stopImmediatePropagation();
      this.selectedMember = whatsappMember;
      this.confirmationDialogOpen = true;
    },
    handleNewWhatsappMember() {
      this.isAddWhatsappMemberModalOpen = true;
    },
    handleDialogClose() {
      this.selectedMember = null;
      this.confirmationDialogOpen = false;
    },
    fetchMembersWithClearState() {
      this.selectedMember = null;
      this.confirmationDialogOpen = false;
      this.fetchWhatsappMembers();
    },
    handleConfirmDeleteWhatsappMember() {
      const orgId = this.getOrganizationId();

      this.$store.dispatch("deleteWhatsappMemberById", {
        whatsappMemberId: this.selectedMember?.public_id,
        organizationId: orgId,
        successHandler: this.fetchMembersWithClearState,
      });
    },
    showNoReults() {
      return (
        !this.$store.getters.isWhatsappMembersLoading &&
        this.whatsappMembers.length < 1
      );
    },
    handleCloseAddWhatsappMemberModal() {
      this.isAddWhatsappMemberModalOpen = false;
    },
    fetchWhatsappMembers() {
      const orgId = this.getOrganizationId();
      this.$store.dispatch("getWhatsappMembers", orgId);
    },
    handleSuccessHanlderGenerateOTP(data: string) {
      this.generatedOTPData = data;
      this.isOTPModalOpen = true;
    },
    handleGenerateOTP(e: Event, whatsappMember: IWhatsappMember) {
      this.currentLoadingGenerateOTPButton = whatsappMember;
      const orgId = this.getOrganizationId();
      this.$store.dispatch("generateOTPForWhatsappMember", {
        whatsappMemberId: whatsappMember?.public_id,
        organizationId: orgId,
        successHandler: this.handleSuccessHanlderGenerateOTP,
      });
    },
    handleCloseOTPDialog() {
      this.currentLoadingGenerateOTPButton = null;
      this.generatedOTPData = {};
      this.isOTPModalOpen = false;
    },
    handleSuccessHanlderSubmitOTP() {
      this.isOTPModalOpen = false;
      this.generatedOTPData = {};
      this.fetchWhatsappMembers();
    },
    handleSubmitOTP(otp: string) {
      const payload = {
        authorization_id: this.generatedOTPData?.authorization_id,
        is_authorized: this.currentLoadingGenerateOTPButton?.is_authorized
          ? false
          : true,
        otp_code: otp,
      };
      const orgId = this.getOrganizationId();
      this.$store.dispatch("authorizeOTPForWhatsappMember", {
        whatsappMemberId: this.currentLoadingGenerateOTPButton?.public_id,
        organizationId: orgId,
        payload: payload,
        successHandler: this.handleSuccessHanlderSubmitOTP,
      });
    },
    isGenerateOTPLoading(item: any) {
      const currentItemLoading =
        this.currentLoadingGenerateOTPButton?.public_id === item?.public_id;
      return this.$store.getters.isGenerateOTPLoading && currentItemLoading;
    },
    handleOrganizationChange(value: string) {
      if (value) {
        const path = `${PATH.WHATSAPP_MANAGEMENT}${formatQueryPath({
          org_id: value,
        })}`;
        this.$router.push(path);
      }
    },
    isAGSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    getOrganizationId(): string {
      let organizationId = "";

      if (this.isAGSuperUser()) {
        const { org_id } = this.$route.query;

        organizationId = org_id ? org_id.toString() : "";
      } else {
        organizationId = this.organization
          ? this.organization.organization_id
          : "";
      }

      return organizationId;
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    whatsappMembers(): IWhatsappMember[] {
      const whatsappMembers = this.$store.getters.whatsappMembers || [];
      return whatsappMembers.map((item: IWhatsappMember) => ({
        ...item,
        action_delete: "Delete",
        generate_otp: "Generate OTP",
      }));
    },
    isWhatsappMembersLoading(): boolean {
      return this.$store.getters.isWhatsappMembersLoading;
    },
    isConfirmationDialogLoading(): boolean {
      return this.$store.getters.isWhatsappActionLoading;
    },
    showWhatsAppManagement(): boolean {
      const { org_id } = this.$route.query;
      if (this.isAGSuperUser()) {
        return Boolean(org_id);
      }
      return true;
    },
    renderOrganizationName(): string {
      const { org_id } = this.$route.query;
      if (this.isAGSuperUser() && org_id) {
        const organizations: IOrganization[] =
          this.$store.getters.organizations;
        const name =
          organizations.find((x: IOrganization) => x.public_id == org_id)
            ?.name || "";
        return `Whatsapp Management - (${name})`;
      }
      return "Whatsapp Management";
    },
  },
  data(): {
    ELEMENT_TYPES: IObject;
    AGENT_SUB_ROLES: IObject;
    membersTableHeader: MDataTableHeader[];
    selectedMember: IWhatsappMember | null;
    confirmationDialogOpen: boolean;
    isAddWhatsappMemberModalOpen: boolean;
    isOTPModalOpen: boolean;
    generatedOTPData: any;
    currentLoadingGenerateOTPButton: IWhatsappMember | null;
    lastOrganizationId: string | null;
  } {
    return {
      isAddWhatsappMemberModalOpen: false,
      lastOrganizationId: null,
      ELEMENT_TYPES,
      AGENT_SUB_ROLES,
      membersTableHeader: [
        {
          title: "Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Contact Number",
          value: "contact_number",
          key: "contact_number",
          sortable: true,
        },
        {
          title: "Authorized",
          value: "is_authorized",
          key: "is_authorized",
          sortable: false,
        },
        {
          title: "Created By",
          value: "created_by",
          key: "created_by",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
        {
          title: "Remove",
          value: "remove",
          key: "remove",
          sortable: false,
        },
      ],
      selectedMember: null,
      confirmationDialogOpen: false,
      isOTPModalOpen: false,
      currentLoadingGenerateOTPButton: null,
      generatedOTPData: {},
    };
  },
  created() {
    if (this.isAGSuperUser()) {
      this.$store.dispatch("fetchOrganizations");
    }
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          if (this.lastOrganizationId !== value?.org_id) {
            this.fetchMembersWithClearState();
            this.lastOrganizationId = value?.org_id;
          }
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="css" scoped>
.delete-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: #e65a5a !important;
}

.delete-whatsapp-member {
  margin-right: 10px;
}
.whatsapp-action-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.is-authorized-icon-container {
  margin-left: 30px;
}

.header-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
}

.whatsapp-delete-icon {
  svg {
    stroke: #d21414;
  }
}

.autocomplete-container {
  max-width: 400px;
  .label {
    font-weight: bold;
  }
}

.whatsapp_link:hover {
  color: blue;
  text-decoration: underline;
}

.whatsapp_link svg path {
  fill: green;
}

.paragraph {
  max-width: 600px;
  text-align: justify;
  .whatsapp_link {
    color: green;
  }
  line-height: 1.3;
}

.whatsapp-management-whatsappIcon {
  display: inline;
  margin-left: 5px;
}
.icon-cell-container {
  display: flex;
  justify-content: center;
}
.add-whatsapp-member {
  margin-right: 10px;
}
@media screen and (max-width: 600px) {
  .header-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
}
</style>
