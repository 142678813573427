import * as yup from "yup";
import { Place } from "../types";
import { ROUTE_TYPE } from "../enums/route_type";

export const normalFlightSearchValidation = yup.object().shape({
  origin: yup.string().nullable().required("Please select origin"),
  destination: yup.string().nullable().required("Please select destination"),
  departure_date: yup
    .string()
    .nullable()
    .when("route_type", {
      is: ROUTE_TYPE.RETURN,
      then: yup.string().nullable().required("Please select departure date"),
      otherwise: yup
        .string()
        .nullable()
        .required("Please select departure date"),
    }),
  return_date: yup
    .string()
    .nullable()
    .when("route_type", {
      is: ROUTE_TYPE.RETURN,
      then: yup.string().nullable().required("Please select return date"),
      otherwise: yup.string().nullable(),
    }),
  route_type: yup.string().required("Please select route type"),
});

export const createMulticityFlightSearchValidation = (
  pakistaniAirports: Place[]
) => {
  return yup.array().of(
    yup.object().shape({
      origin: yup
        .object()
        .nullable()
        .required("Please select origin")
        .test(
          "check-domestic",
          "Domestic multicity is not supported",
          function (value) {
            const { destination } = this.parent;
            const isOriginPakistani = pakistaniAirports.some(
              (airport) => airport.iata_code === value?.iata_code
            );
            const isDestinationPakistani = pakistaniAirports.some(
              (airport) => airport.iata_code === destination?.iata_code
            );
            return !(isOriginPakistani && isDestinationPakistani);
          }
        ),
      destination: yup
        .object()
        .nullable()
        .required("Please select destination")
        .test(
          "check-domestic",
          "Domestic multicity is not supported",
          function (value) {
            const { origin } = this.parent;
            const isOriginPakistani = pakistaniAirports.some(
              (airport) => airport.iata_code === origin?.iata_code
            );
            const isDestinationPakistani = pakistaniAirports.some(
              (airport) => airport.iata_code === value?.iata_code
            );
            return !(isOriginPakistani && isDestinationPakistani);
          }
        ),
      departure_date: yup.date().required("Please select departure date"),
    })
  );
};
