<template>
  <ag-card>
    <ag-heading variant="h3" title="Website management"></ag-heading>
    <ag-heading
      variant="p"
      title="Update your website configuration here"
    ></ag-heading>
    <hr />
  </ag-card>
  <ag-loader v-if="$store.getters.isWebsiteConfigLoading" />
  <template v-else>
    <AgDiv class="form-container">
      <AgRow test-id="" class="justify-content-center">
        <AgColumn test-id="" md="12" lg="10">
          <!-- ********** BASIC CONFIGURATION ********** -->
          <MAccordion :initial-open="true">
            <template #title>
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-website-management"
                title="Basic Website Configuration"
                class="section-title"
              />
            </template>
            <template #content>
              <AgRow test-id="" class="section-content">
                <AgColumn test-id="" md="12" lg="10">
                  <p>
                    <b>Note:</b> Slug for subdomain should start with a letter
                    or number, contain only letters, numbers, or hyphens, and
                    not start or end with a hyphen.
                  </p>
                  <m-textfield
                    v-model:inputValue.trim="website_slug"
                    label="Website slug"
                    :hasError="renderSlugError.hasError"
                    :errorMessage="renderSlugError.message"
                  />
                  <AgDiv>
                    <h5>Your website address will look like this.</h5>

                    <p>
                      <a
                        class="website-slug"
                        :href="`https://${generateWebsiteDomain(
                          response_website_slug
                        )}`"
                        target="_blank"
                      >
                        {{ generateWebsiteDomain(website_slug) }}
                      </a>
                    </p>
                  </AgDiv>
                  <hr />
                  <p>
                    You can toggle your website's accessibility using this
                    checkbox.
                  </p>
                  <v-switch
                    hide-details
                    inset
                    color="primary"
                    v-model="is_website_enabled"
                    :value="true"
                    :label="renderWebsiteEnabledLabel"
                  />
                  <!-- <p>You can toggle OTA search box visibilty in your website using this checkbox.</p>
                <v-switch
                  hide-details
                  inset
                  color="primary"
                  v-model="show_ota_search"
                  :value="true"
                  :label="renderOTAEnabledLabel"
                /> 

                <hr />
                -->
                </AgColumn>
                <AgColumn
                  test-id=""
                  md="12"
                  lg="12"
                  class="section-content-button"
                >
                  <MButton
                    test-id=""
                    style="float: right"
                    :disabled="$store.getters.isUpdateWebsiteLoading"
                    @click="onWebsiteConfigUpdate"
                    >Save Website Configuration
                  </MButton>
                </AgColumn>
              </AgRow>
            </template>
          </MAccordion>

          <!-- ********** BANNER SECTION ********** -->
          <MAccordion :initial-open="true">
            <template #title>
              <AgIconInfoBar
                test-id=""
                title="Banner Section"
                class="section-title"
                mPrepandIcon="m-banner"
              />
            </template>
            <template #content>
              <AgRow test-id="" class="section-content">
                <AgColumn test-id="" md="12" lg="10">
                  <m-textfield
                    v-model:inputValue.trim="banner_title"
                    label="Banner Title"
                  />
                  <m-textarea
                    v-model:inputValue.trim="banner_body"
                    label="Banner Body"
                  />
                </AgColumn>
                <AgColumn
                  test-id=""
                  md="12"
                  lg="12"
                  class="section-content-button"
                >
                  <MButton
                    test-id=""
                    style="float: right"
                    :disabled="$store.getters.isUpdateWebsiteLoading"
                    @click="handleSaveBannerSection()"
                    >Save Banners
                  </MButton>
                </AgColumn>
              </AgRow>
            </template>
          </MAccordion>

          <!-- ********** CONTACT SECTION ********** -->

          <MAccordion :initial-open="true">
            <template #title>
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-contact"
                title="Contact Section"
                class="section-title"
              />
            </template>
            <template #content>
              <AgRow test-id="" class="section-content">
                <AgColumn test-id="" md="12" lg="10">
                  <m-textfield
                    v-model:inputValue.trim="email_address"
                    label="Email Address"
                    :hasError="isValidEmail().isValid"
                    :errorMessage="isValidEmail().message"
                  />
                  <m-textfield
                    v-model:inputValue.trim="whatsapp_number"
                    label="Whatsapp Number"
                  />
                  <m-textfield
                    v-model:inputValue.trim="phone_number"
                    label="Phone Number"
                  />
                  <m-textfield
                    v-model:inputValue.trim="mobile_number"
                    label="Mobile Number"
                  />
                  <m-textarea
                    v-model:inputValue.trim="physical_address"
                    label="Physical Address"
                  />
                </AgColumn>
                <AgColumn
                  test-id=""
                  md="12"
                  lg="12"
                  class="section-content-button"
                >
                  <MButton
                    test-id=""
                    style="float: right"
                    :disabled="$store.getters.isUpdateWebsiteLoading"
                    @click="handleSaveContactSection()"
                    >Save Contacts
                  </MButton>
                </AgColumn>
              </AgRow>
            </template>
          </MAccordion>

          <!-- ********** ABOUT US SECTION ********** -->
          <MAccordion :initial-open="true">
            <template #title>
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-about-us"
                title="About Us Section"
                class="section-title"
              />
            </template>
            <template #content>
              <AgRow test-id="" class="section-content">
                <AgColumn test-id="" md="12" lg="10">
                  <m-textfield
                    v-model:inputValue.trim="about_us_title"
                    label="About Us Title"
                    :hasError="isValidAboutUsTitle.isValid"
                    :errorMessage="isValidAboutUsTitle.message"
                  />
                  <m-textarea
                    v-model:inputValue.trim="about_us_description"
                    label="About Us Description"
                    :hasError="isValidAboutUsDescription.isValid"
                    :errorMessage="isValidAboutUsDescription.message"
                  />
                  <AgDiv
                    class="package_list_item"
                    v-if="about_us_image.url && about_us_image.isSaved"
                  >
                    <div class="list-header">
                      <img
                        class="list-thumb"
                        :src="about_us_image.url"
                        alt="about-us-image"
                      />
                      <p>About Us Image</p>
                    </div>
                    <div class="edit_container">
                      <p>Active</p>
                      <MButton test-id="" @click="handleEditAboutUsImage"
                        >Edit</MButton
                      >
                      <MButton test-id="" @click="handleRemoveAboutUsImage"
                        >Remove</MButton
                      >
                    </div>
                  </AgDiv>
                  <AgFile
                    v-if="!about_us_image.isSaved && isAboutUsModelOpen"
                    test-id=""
                    class="ag-file-theme"
                    label="About Us Image"
                    :multiple="false"
                    @on:change="onFileChangeHandler"
                  />
                  <p>
                    <b>Globe Image:</b> Please select the globe image to have a
                    customized view on your OTA website.
                  </p>
                  <v-sheet class="mx-auto margin_bottom_20">
                    <v-slide-group show-arrows>
                      <v-slide-group-item
                        v-for="n in updatedColors"
                        :key="n"
                        v-slot="{ isSelected, toggle }"
                      >
                        <v-btn
                          v-model="selectedImageId"
                          :color="
                            isSelected
                              ? getOrganizationColor(n.code)
                              : 'undefined'
                          "
                          style="margin: 0.1cm; padding: 0.5cm"
                          rounded
                          @click="toggle"
                        >
                          <p
                            :style="{
                              color: isSelected ? '#ffffff' : 'black',
                            }"
                          >
                            {{ n.name }}
                          </p>
                          <GlobeSvg :colorCode="n.code" />
                        </v-btn>
                      </v-slide-group-item>
                    </v-slide-group>
                  </v-sheet>
                </AgColumn>
                <AgColumn
                  test-id=""
                  md="12"
                  lg="12"
                  class="section-content-button"
                >
                  <MButton
                    test-id=""
                    style="float: right"
                    :disabled="$store.getters.isUpdateWebsiteLoading"
                    @click="handleSaveAboutUsSection()"
                    >Save About Us
                  </MButton>
                </AgColumn>
              </AgRow>
            </template>
          </MAccordion>

          <!-- ********** PACKAGES SECTION ********** -->
          <MAccordion :initial-open="true">
            <template #title>
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-packages"
                title="Package Section"
                class="section-title"
              />
            </template>
            <template #content>
              <AgRow test-id="" class="section-content">
                <AgColumn test-id="" md="12" lg="10">
                  <v-switch
                    hide-details
                    inset
                    color="primary"
                    v-model="show_packages"
                    :value="true"
                    :label="renderPackagesActiveLabel"
                  />

                  <AgDiv
                    class="package_list_item"
                    v-for="(item, index) in package_list"
                    :key="index"
                  >
                    <div class="list-header">
                      <template v-if="item.display_image_url">
                        <img
                          class="list-thumb"
                          :src="item.display_image_url"
                          alt="package-image"
                        />
                      </template>
                      <p>{{ item?.name }}</p>
                    </div>
                    <div class="edit_container">
                      <p>{{ item?.active ? "Active" : "Disabled" }}</p>
                      <MButton
                        test-id=""
                        @click="handleEditPackage(item, index)"
                        >Edit</MButton
                      >
                      <MButton
                        test-id=""
                        @click="handleOpenRemovePackageItemDialog(index)"
                        >Remove</MButton
                      >
                    </div>
                  </AgDiv>
                  <hr />
                  <MButton test-id="" @click="handleOpenPackageDialog"
                    >Add New Package</MButton
                  >
                </AgColumn>
                <AgColumn
                  test-id=""
                  md="12"
                  lg="12"
                  class="section-content-button"
                >
                  <MButton
                    test-id=""
                    style="float: right"
                    :disabled="$store.getters.isUpdateWebsiteLoading"
                    @click="handleSavePackageSection()"
                    >Save Packages
                  </MButton>
                </AgColumn>
              </AgRow>
            </template>
          </MAccordion>

          <!-- ********** SERVICES SECTION ********** -->
          <MAccordion :initial-open="true">
            <template #title>
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-services"
                title="Service Section"
                class="section-title"
              />
            </template>
            <template #content>
              <AgRow test-id="" class="section-content">
                <AgColumn test-id="" md="12" lg="10">
                  <v-switch
                    hide-details
                    inset
                    color="primary"
                    v-model="show_services"
                    :value="true"
                    :label="renderServicesActiveLabel"
                  />

                  <AgDiv
                    class="package_list_item"
                    v-for="(item, index) in services_list"
                    :key="index"
                  >
                    <div class="list-header">
                      <template v-if="item.display_image_url">
                        <img
                          class="list-thumb"
                          :src="item.display_image_url"
                          alt="service-image"
                        />
                      </template>
                      <p>{{ item?.name }}</p>
                    </div>
                    <div class="edit_container">
                      <MButton
                        test-id=""
                        @click="handleEditServices(item, index)"
                        >Edit</MButton
                      >
                      <MButton
                        test-id=""
                        @click="handleOpenRemoveServiceItemDialog(index)"
                        >Remove</MButton
                      >
                    </div>
                  </AgDiv>
                  <hr />
                  <MButton test-id="" @click="handleOpenServiceDialog"
                    >Add New Service</MButton
                  >
                </AgColumn>
                <AgColumn
                  test-id=""
                  md="12"
                  lg="12"
                  class="section-content-button"
                >
                  <MButton
                    test-id=""
                    style="float: right"
                    :disabled="$store.getters.isUpdateWebsiteLoading"
                    @click="handleSaveServicesSection()"
                    >Save Services
                  </MButton>
                </AgColumn>
              </AgRow>
            </template>
          </MAccordion>

          <!-- ********** SOCIAL SECTION ********** -->
          <MAccordion :initial-open="true">
            <template #title>
              <AgIconInfoBar
                test-id=""
                mPrepandIcon="m-social"
                title="Social Address Section"
                class="section-title"
              />
            </template>
            <template #content>
              <AgRow test-id="" class="section-content">
                <AgColumn test-id="" md="12" lg="10">
                  <m-textfield
                    v-model:inputValue.trim="facebook"
                    label="Facebook"
                    :hasError="isValidFacebookLink.isValidFacebookLink"
                    :errorMessage="isValidFacebookLink.message"
                  />
                  <m-textfield
                    v-model:inputValue.trim="youtube"
                    label="Youtube"
                    :hasError="isValidYoutubeLink.isValidYoutubeLink"
                    :errorMessage="isValidYoutubeLink.message"
                  />
                  <m-textfield
                    v-model:inputValue.trim="instagram"
                    label="Instagram"
                    :hasError="isValidInstagramLink.isValidInstagramLink"
                    :errorMessage="isValidInstagramLink.message"
                  />
                </AgColumn>
                <AgColumn
                  test-id=""
                  md="12"
                  lg="12"
                  class="section-content-button"
                >
                  <MButton
                    test-id=""
                    style="float: right"
                    :disabled="$store.getters.isUpdateWebsiteLoading"
                    @click="handleSaveSocials()"
                    >Save Socials
                  </MButton>
                </AgColumn>
              </AgRow>
            </template>
          </MAccordion>

          <AgCard test-id="">
            <AgRow test-id="">
              <AgColumn test-id="" md="12" lg="12">
                <MButton
                  test-id=""
                  style="float: right"
                  :disabled="$store.getters.isWebsiteContentLoading"
                  @click="handleSaveSettings"
                  >Save Settings
                </MButton>
              </AgColumn>
            </AgRow>
          </AgCard>
        </AgColumn>
      </AgRow>
    </AgDiv>
    <AddPackageModal
      :is-edit="packageDialogModeIsEdit"
      :data="selectedPackage"
      :is-open="isPackageModelOpen"
      @closeModal="closeAddPackageModal"
      @onSubmit="onPackageSave"
    />
    <AddServiceModal
      :is-edit="serviceDialogModeIsEdit"
      :data="selectedService"
      :is-open="isServiceModelOpen"
      @closeModal="closeAddServiceModal"
      @onSubmit="onServiceSave"
    />
    <RemoveItemConfirmationModal
      :is-open="isRemoveItemModelOpen"
      :type="removeItemDialogType"
      @handleReject="handleCloseRemoveItemDialog"
      @handleAccept="handleAcceptRemoveItemDialog"
    />
  </template>
</template>

<script lang="ts">
import GlobeSvg from "@/modules/Organization/components/GlobeSvg.vue";
import { defineComponent } from "vue";
import { format } from "date-fns";
import { clone } from "lodash";
import { ORGANIZATION_COLORS } from "@/modules/Organization/constants";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { WebsiteStatus, WebsiteType } from "@/ag-portal-common/enums/WEBSITE";
import AddPackageModal from "@/modules/Organization/components/AddPackageModel.vue";
import AddServiceModal from "@/modules/Organization/components/AddServiceModel.vue";
import RemoveItemConfirmationModal from "@/modules/Organization/components/RemoveItemConfirmationModel.vue";
import { AddPackageDTO } from "@/modules/Organization/dtos/package.dto";
import { AddServiceDTO } from "@/modules/Organization/dtos/websiteService.dto";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import analyticsService from "@/analytic.service";
import { ORGANIZATION_ANALYTICS_EVENTS } from "@/modules/Organization/constants/analyticsEvents";
import {
  ContentUpload,
  UPLOAD_CONTENT_CATEGORY,
  UPLOAD_CONTENT_SUB_CATEGORY,
} from "@/modules/Organization/dtos/organization.dto";
import { Organization } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "WebsiteMangement",
  components: {
    AddPackageModal,
    AddServiceModal,
    RemoveItemConfirmationModal,
    GlobeSvg,
  },
  data(): {
    response_website_slug: string;
    website_slug: string;
    is_valid_subdomain: boolean;
    is_website_enabled: boolean;
    show_ota_search: boolean;
    banner_title: string;
    banner_body: string;
    email_address: string;
    whatsapp_number: string;
    phone_number: string;
    mobile_number: string;
    physical_address: string;
    facebook: string;
    instagram: string;
    youtube: string;
    about_us_title: string;
    about_us_description: string;
    show_packages: boolean;
    package_list: any[];
    show_services: boolean;
    services_list: any[];
    isPackageModelOpen: boolean;
    isAboutUsModelOpen: boolean;
    selectedPackageIndex: number;
    packageDialogModeIsEdit: boolean;
    selectedPackage: object;
    isServiceModelOpen: boolean;
    selectedServiceIndex: number;
    serviceDialogModeIsEdit: boolean;
    selectedService: object;
    isRemoveItemModelOpen: boolean;
    removeItemDialogType: string;
    removeItemIndex: number;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    about_us_image: {
      url: string | null;
      file: File | null;
      isSaved: boolean;
      id: string | null;
    };
    selectedImageId: string;
  } {
    return {
      ELEMENT_TYPES,
      response_website_slug: "",
      website_slug: "",
      is_valid_subdomain: true,
      is_website_enabled: true,
      show_ota_search: false,
      banner_title: "",
      banner_body: "",
      email_address: "",
      whatsapp_number: "",
      phone_number: "",
      mobile_number: "",
      physical_address: "",
      facebook: "",
      instagram: "",
      youtube: "",
      about_us_title: "",
      about_us_description: "",
      show_packages: true,
      package_list: [],
      show_services: true,
      services_list: [],
      // Packages
      isPackageModelOpen: false,
      isAboutUsModelOpen: false,
      selectedPackageIndex: 0,
      packageDialogModeIsEdit: false,
      selectedPackage: {},
      // Services
      isServiceModelOpen: false,
      selectedServiceIndex: 0,
      serviceDialogModeIsEdit: false,
      selectedService: {},
      // Remove Item
      isRemoveItemModelOpen: false,
      removeItemDialogType: "",
      removeItemIndex: 0,
      about_us_image: {
        url: null,
        file: null,
        isSaved: true,
        id: null,
      },
      selectedImageId: "#10B981",
    };
  },
  methods: {
    genTestId,
    getOrganizationColor(colorId: string) {
      this.selectedImageId = colorId;
      const organization = this.organization;
      const orgColor = organization
        ? organization.org_default_color
        : "#10B981";

      return orgColor;
    },
    async onContentUploadHandler() {
      if (this.about_us_image.file) {
        if (!this.organization) {
          return;
        }

        const organizationId = this.organization.organization_id;
        const payload: ContentUpload = {
          image_content: this.about_us_image.file,
          content_category: UPLOAD_CONTENT_CATEGORY.WEBSITE,
          content_sub_category: UPLOAD_CONTENT_SUB_CATEGORY.PACKAGE,
        };

        const response = await this.$store.dispatch("websiteUploadContent", {
          payload,
          organizationId,
        });

        this.about_us_image.id = response[0];
        this.about_us_image.url = response[1];
      }
    },
    handleEditAboutUsImage() {
      this.isAboutUsModelOpen = true;
      this.about_us_image.isSaved = false;
    },
    async onWebsiteConfigLoadHandler() {
      if (!this.organization) {
        return;
      }

      const organizationId = this.organization.organization_id;

      await this.$store.dispatch("getWebsiteConfiguration", {
        organizationId,
      });
    },
    isValidEmail() {
      const emailRegex = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (emailRegex.test(this.email_address)) {
        return {
          isValid: false,
        };
      } else {
        return {
          isValid: true,
          message: "Enter Valid Email",
        };
      }
    },

    validateSubdomain() {
      const pattern =
        /^(?!:\/\/)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.[a-zA-Z0-9-]{1,63})*$/;
      this.is_valid_subdomain = pattern.test(this.website_slug);
      return this.is_valid_subdomain;
    },
    onWebsiteConfigUpdate() {
      const pattern =
        /^(?!:\/\/)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.[a-zA-Z0-9-]{1,63})*$/;
      if (!pattern.test(this.website_slug)) {
        this.renderSlugError;
      } else {
        if (!this.organization) {
          return;
        }

        const organizationId = this.organization.organization_id;
        const payload = {
          web_slug: this.website_slug,
          search_and_book_enabled: this.show_ota_search,
          enabled: this.is_website_enabled,
          domain_name: "web.aeroglobe.pk",
        };
        const analyticsPayload = {
          "web-slug": this.website_slug,
          "search-and-book-enabled": this.show_ota_search,
          enabled: this.is_website_enabled,
          "domain-name": "web.aeroglobe.pk",
        };
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_BASIC_CONFIGURATION_SAVE,
          analyticsPayload
        );
        this.response_website_slug = this.website_slug;
        this.$store.dispatch("updateWebsiteConfiguration", {
          payload,
          organizationId,
        });

        this.onWebsiteConfigLoadHandler();
      }
    },
    closeAddPackageModal() {
      this.isPackageModelOpen = false;
    },
    closeAddServiceModal() {
      this.isServiceModelOpen = false;
    },
    clearPackageDetails() {
      this.selectedPackage = {};
      this.selectedPackageIndex = 0;
      this.packageDialogModeIsEdit = false;
    },
    clearServiceDetails() {
      this.selectedService = {};
      this.selectedServiceIndex = 0;
      this.serviceDialogModeIsEdit = false;
    },
    onPackageSave(payload: AddPackageDTO) {
      const updatedPackages = this.package_list;
      const updatedPackage = { ...payload, display_image_url: null };
      if (this.packageDialogModeIsEdit) {
        const oldPackage = updatedPackages[this.selectedPackageIndex];

        if (oldPackage.display_image_id === updatedPackage.display_image_id) {
          updatedPackage.display_image_url = oldPackage.display_image_url;
        }

        updatedPackages[this.selectedPackageIndex] = updatedPackage;
      } else {
        updatedPackages.push(updatedPackage);
      }

      this.package_list = updatedPackages;

      const eventName = this.packageDialogModeIsEdit
        ? ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_PACKAGE_UPDATE
        : ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_PACKAGE_ADD;

      analyticsService.logActionEvent(eventName, payload);

      this.closeAddPackageModal();
      this.clearPackageDetails();
    },
    onServiceSave(payload: AddServiceDTO) {
      const updatedServices = this.services_list;
      const updatedService = { ...payload, display_image_url: null };
      if (this.serviceDialogModeIsEdit) {
        const oldService = updatedServices[this.selectedServiceIndex];

        if (oldService.display_image_id === updatedService.display_image_id) {
          updatedService.display_image_url = oldService.display_image_url;
        }

        updatedServices[this.selectedServiceIndex] = updatedService;
      } else {
        updatedServices.push(updatedService);
      }

      this.services_list = updatedServices;

      const eventName = this.packageDialogModeIsEdit
        ? ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_SERVICE_UPDATE
        : ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_SERVICE_ADD;

      analyticsService.logActionEvent(eventName, payload);

      this.closeAddServiceModal();
      this.clearServiceDetails();
    },
    handleOpenPackageDialog() {
      this.clearPackageDetails();
      this.isPackageModelOpen = true;
    },
    handleOpenAboutUsImageDialog() {
      this.isAboutUsModelOpen = true;
    },
    handleOpenServiceDialog() {
      this.clearServiceDetails();
      this.isServiceModelOpen = true;
    },
    isValidLink() {
      const validInstagram = "https://www.instagram.com/";
      const validYoutube = "https://www.youtube.com/" || "https://youtube.com/";
      const validFacebook = "https://www.facebook.com/";
      if (
        this.youtube.includes(validYoutube) &&
        this.instagram.includes(validInstagram) &&
        this.facebook.includes(validFacebook)
      ) {
        return true;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Invalid Social Link";
        notificationService.triggerNotification();
        return false;
      }
    },
    handleSaveSocials() {
      const validSocials =
        !this.isValidFacebookLink.isValidFacebookLink &&
        !this.isValidInstagramLink.isValidInstagramLink &&
        !this.isValidYoutubeLink.isValidYoutubeLink;
      if (validSocials) {
        const payload = {
          social_links: {
            facebook: this.facebook,
            instagram: this.instagram,
            youtube: this.youtube,
          },
        };
        if (!this.organization) {
          return;
        }

        const organizationId = this.organization.organization_id;
        const analyticsPayload = {
          "social-facebook": this.facebook,
          "social-instagram": this.instagram,
          "social-youtube": this.youtube,
        };
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_SOCIALS_SAVE,
          analyticsPayload
        );
        this.$store.dispatch("updateWebsiteContent", {
          payload,
          organizationId,
        });

        this.onWebsiteConfigLoadHandler();
      }
    },
    handleSaveAboutUsSection() {
      if (
        !this.isValidAboutUsDescription.isValid &&
        !this.isValidAboutUsTitle.isValid
      ) {
        const payload = {
          about_us: {
            title: this.about_us_title,
            description: this.about_us_description,
            image_url: this.about_us_image.url,
            image_id: this.about_us_image.id,
          },
          header_section_details: {
            heading_text: this.banner_title,
            body_text: this.banner_body,
            cta_text: null,
            body_image_url: this.selectedImageId,
          },
        };
        const analyticsPayload = {
          "about-us-title": this.about_us_title,
          "about-us-description": this.about_us_description,
          "about-us-image_url": this.about_us_image.url,
          "about-us-image_id": this.about_us_image.id,
        };

        if (!this.organization) {
          return;
        }

        const organizationId = this.organization.organization_id;

        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_ABOUT_US_SAVE,
          analyticsPayload
        );
        this.$store.dispatch("updateWebsiteContent", {
          payload,
          organizationId,
        });

        this.onWebsiteConfigLoadHandler();
      }
    },
    handleSaveBannerSection() {
      const payload = {
        header_section_details: {
          heading_text: this.banner_title,
          body_text: this.banner_body,
          cta_text: null,
          body_image_url: this.selectedImageId,
        },
      };
      const analyticsPayload = {
        "header-heading": this.banner_title,
        "header-body": this.banner_body,
        "header-cta": null,
        "header-image-id": this.selectedImageId,
      };

      if (!this.organization) {
        return;
      }

      const organizationId = this.organization.organization_id;

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_BANNER_SAVE,
        analyticsPayload
      );
      this.$store.dispatch("updateWebsiteContent", {
        payload,
        organizationId,
      });

      this.onWebsiteConfigLoadHandler();
    },
    handleSaveContactSection() {
      if (!this.isValidEmail().isValid) {
        const payload = {
          contact_info: {
            email_address: this.email_address,
            whatsapp_number: this.whatsapp_number,
            phone_number: this.phone_number,
            mobile_number: this.mobile_number,
            physical_address: this.physical_address,
          },
        };

        if (!this.organization) {
          return;
        }

        const organizationId = this.organization.organization_id;
        const analyticsPayload = {
          "contact-email": this.email_address,
          "contact-whatsapp": this.whatsapp_number,
          "contact-phone": this.phone_number,
          "contact-mobile": this.mobile_number,
          "contact-address": this.physical_address,
        };
        analyticsService.logActionEvent(
          ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_CONTACT_SAVE,
          analyticsPayload
        );

        this.$store.dispatch("updateWebsiteContent", {
          payload,
          organizationId,
        });

        this.onWebsiteConfigLoadHandler();
      }
    },
    handleSavePackageSection() {
      const payload = {
        packages_details: {
          is_enabled: this.show_packages,
          package_list: this.package_list.map((item: AddPackageDTO) => {
            return {
              ...item,
              start_date: item?.start_date
                ? format(new Date(item?.start_date), FORMAT_YYY_MM_DD)
                : "",
              end_date: item?.end_date
                ? format(new Date(item?.end_date), FORMAT_YYY_MM_DD)
                : "",
            };
          }),
        },
      };

      if (!this.organization) {
        return;
      }

      const organizationId = this.organization.organization_id;

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_PACKAGES_SAVE,
        payload
      );

      this.$store.dispatch("updateWebsiteContent", {
        payload,
        organizationId,
      });

      this.onWebsiteConfigLoadHandler();
    },
    handleSaveServicesSection() {
      const payload = {
        services_details: {
          is_enabled: this.show_services,
          service_list: this.services_list,
        },
      };

      if (!this.organization) {
        return;
      }

      const organizationId = this.organization.organization_id;

      this.$store.dispatch("updateWebsiteContent", {
        payload,
        organizationId,
      });
      this.onWebsiteConfigLoadHandler();
    },
    handleSaveSettings() {
      if (!this.isValidEmail().isValid) {
        if (this.isValidLink()) {
          const payload = {
            social_links: {
              facebook: this.facebook,
              instagram: this.instagram,
              youtube: this.youtube,
            },
            contact_info: {
              email_address: this.email_address,
              whatsapp_number: this.whatsapp_number,
              phone_number: this.phone_number,
              mobile_number: this.mobile_number,
              physical_address: this.physical_address,
            },
            header_section_details: {
              heading_text: this.banner_title,
              body_text: this.banner_body,
              cta_text: null,
              body_image_url: this.selectedImageId,
            },
            services_details: {
              is_enabled: this.show_services,
              service_list: this.services_list,
            },
            packages_details: {
              is_enabled: this.show_packages,
              package_list: this.package_list.map((item: AddPackageDTO) => {
                return {
                  ...item,
                  start_date: item?.start_date
                    ? format(new Date(item?.start_date), FORMAT_YYY_MM_DD)
                    : "",
                  end_date: item?.end_date
                    ? format(new Date(item?.end_date), FORMAT_YYY_MM_DD)
                    : "",
                };
              }),
            },
            about_us: {
              title: this.about_us_title,
              description: this.about_us_description,
              image_url: this.about_us_image.url,
              image_id: this.about_us_image.id,
            },
          };

          //
          const {
            about_us,
            contact_info,
            header_section_details,
            packages_details,
            services_details,
            social_links,
          } = payload;

          const analyticsPayload = {
            "about-title": about_us.title,
            "about-description": about_us.description,

            "contact-email": contact_info.email_address,
            "contact-mobile": contact_info.mobile_number,
            "contact-phone": contact_info.phone_number,
            "contact-address": contact_info.physical_address,
            "contact-whatsapp": contact_info.whatsapp_number,

            "header-heading": header_section_details.heading_text,
            "header-body": header_section_details.body_text,
            "header-cta": header_section_details.cta_text,

            "social-facebook": social_links.facebook,
            "social-instagram": social_links.instagram,
            "social-youtube": social_links.youtube,

            "packages-details": packages_details.package_list
              .map((pack) => pack.name)
              .join(", "),
            "services-details": services_details.service_list
              .map((serv) => serv.name)
              .join(", "),
          };

          if (!this.organization) {
            return;
          }

          const organizationId = this.organization.organization_id;

          analyticsService.logActionEvent(
            ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_CONFIG_SAVE,
            analyticsPayload
          );
          this.$store.dispatch("updateWebsiteContent", {
            payload,
            organizationId,
          });

          this.onWebsiteConfigLoadHandler();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Invalid Email";
        notificationService.triggerNotification();
      }
    },

    handleEditPackage(item: AddPackageDTO, index: number) {
      this.isPackageModelOpen = true;
      this.selectedPackage = item;
      this.selectedPackageIndex = index;
      this.packageDialogModeIsEdit = true;
    },

    handleEditServices(item: AddServiceDTO, index: number) {
      this.isServiceModelOpen = true;
      this.selectedService = item;
      this.selectedServiceIndex = index;
      this.serviceDialogModeIsEdit = true;
    },
    handleOpenRemoveServiceItemDialog(index: number) {
      this.isRemoveItemModelOpen = true;
      this.removeItemDialogType = "Service";
      this.removeItemIndex = index;
    },
    handleOpenRemovePackageItemDialog(index: number) {
      this.isRemoveItemModelOpen = true;
      this.removeItemDialogType = "Package";
      this.removeItemIndex = index;
    },
    handleRemoveAboutUsImage() {
      this.about_us_image.url = null;
      this.about_us_image.file = null;
      this.about_us_image.isSaved = true;

      const payload = {
        about_us: {
          title: this.about_us_title,
          description: this.about_us_description,
          image_url: null,
          image_id: null,
        },
      };
      const analyticsPayload = {
        "about-us-title": this.about_us_title,
        "about-us-description": this.about_us_description,
        "about-us-image_url": this.about_us_image.url,
        "about-us-image_id": this.about_us_image.id,
      };

      if (!this.organization) {
        return;
      }

      const organizationId = this.organization.organization_id;

      analyticsService.logActionEvent(
        ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_ABOUT_US_SAVE,
        analyticsPayload
      );
      this.$store.dispatch("updateWebsiteContent", {
        payload,
        organizationId,
      });

      this.onWebsiteConfigLoadHandler();
    },
    handleRemovePackageItem(index: number) {
      const _packages_list = clone(this.package_list);
      const _package = _packages_list[index];
      _packages_list.splice(index, 1);
      this.package_list = _packages_list;
      this.handleCloseRemoveItemDialog();

      const eventName = ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_PACKAGE_DELETE;

      analyticsService.logActionEvent(eventName, _package);
    },
    handleRemoveServiceItem(index: number) {
      const _services_list = clone(this.services_list);
      const _service = _services_list[index];
      _services_list.splice(index, 1);
      this.services_list = _services_list;
      this.handleCloseRemoveItemDialog();

      const eventName = ORGANIZATION_ANALYTICS_EVENTS.WEBSITE_PACKAGE_DELETE;

      analyticsService.logActionEvent(eventName, _service);
    },
    handleCloseRemoveItemDialog() {
      this.isRemoveItemModelOpen = false;
      this.removeItemDialogType = "";
      this.removeItemIndex = 0;
    },
    handleAcceptRemoveItemDialog() {
      if (this.removeItemDialogType == "Service") {
        this.handleRemoveServiceItem(this.removeItemIndex);
      } else {
        this.handleRemovePackageItem(this.removeItemIndex);
      }
    },
    generateWebsiteDomain(slug: string) {
      const isDev = process.env.NODE_ENV === "development";
      return `${slug}${isDev ? ".dev" : ""}.web.aeroglobe.pk`;
    },
    initialOrgLogo() {
      return this.about_us_image.url || null;
    },
    onFileChangeHandler(files: FileList) {
      const file = files[0];
      this.about_us_image.file = file;
      this.about_us_image.isSaved = false;
      this.onContentUploadHandler();
    },
  },
  computed: {
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isValidAboutUsTitle() {
      if (this.about_us_title.length > 0) {
        return {
          isValid: false,
        };
      } else {
        return {
          isValid: true,
          message: "title can't be blank",
        };
      }
    },
    isValidAboutUsDescription() {
      if (this.about_us_description.length > 0) {
        return {
          isValid: false,
        };
      } else {
        return {
          isValid: true,
          message: "description can be blank",
        };
      }
    },
    isValidFacebookLink() {
      const ValidFacebookLink = "https://www.facebook.com/";
      if (this.facebook.includes(ValidFacebookLink)) {
        return {
          isValidFacebookLink: false,
        };
      } else {
        return {
          isValidFacebookLink: true,
          message: "Invalid Facebook Link",
        };
      }
    },
    isValidInstagramLink() {
      const validInstagramLink = "https://www.instagram.com/";
      if (this.instagram.includes(validInstagramLink)) {
        return {
          isValidInstagramLink: false,
        };
      } else {
        return {
          isValidInstagramLink: true,
          message: "Invalid Instagram Link",
        };
      }
    },
    isValidYoutubeLink() {
      const ValidYoutubeLink =
        "https://www.youtube.com/" || "https://youtube.com/";
      if (this.youtube.includes(ValidYoutubeLink)) {
        return {
          isValidYoutubeLink: false,
        };
      } else {
        return {
          isValidYoutubeLink: true,
          message: "Invalid Youtube Link",
        };
      }
    },
    getOrganisationColor(): string {
      const organization = this.organization;
      const orgColor = organization
        ? organization.org_default_color
        : "#10B981";

      return orgColor;
    },
    updatedColors(): {
      code: string;
      name: string;
    }[] {
      return this.colorList.filter(
        (color: any) => color.code !== this.getOrganisationColor
      );
    },
    colorList() {
      return ORGANIZATION_COLORS;
    },
    renderSlugError() {
      if (this.validateSubdomain()) {
        return {
          message: "",
          hasError: false,
        };
      } else {
        return {
          message: "Please enter a valid slug for subdomain",
          hasError: true,
        };
      }
    },
    renderWebsiteEnabledLabel(): string {
      return `Website ${this.is_website_enabled ? "Enabled" : "Disabled"}`;
    },
    renderOTAEnabledLabel(): string {
      return `OTA search box ${this.show_ota_search ? "Enabled" : "Disabled"}`;
    },
    renderPackagesActiveLabel(): string {
      return `${this.show_packages ? "Show" : "Hide"} Packages Section`;
    },
    renderServicesActiveLabel(): string {
      return `${this.show_services ? "Show" : "Hide"} Services Section`;
    },
  },
  watch: {
    "$store.getters.websiteConfig": {
      handler(data) {
        this.response_website_slug = data?.web_slug;
        this.website_slug = data?.web_slug;
        this.is_website_enabled = data?.status === WebsiteStatus.ENABLED;
        this.show_ota_search = data?.website_type === WebsiteType.OTA;
      },
    },
    "$store.getters.websiteContent": {
      handler(data) {
        this.facebook = data?.social_links?.facebook || "";
        this.instagram = data?.social_links?.instagram || "";
        this.youtube = data?.social_links?.youtube || "";
        this.email_address = data?.contact_detail?.email_address || "";
        this.whatsapp_number = data?.contact_detail?.whatsapp_number || "";
        this.mobile_number = data?.contact_detail?.mobile_number || "";
        this.phone_number = data?.contact_detail?.phone_number || "";
        this.physical_address = data?.contact_detail?.physical_address || "";
        this.banner_title = data?.header_section_data?.heading_text || "";
        this.banner_body = data?.header_section_data?.body_text || "";
        this.about_us_title = data?.about_us?.title || "";
        this.about_us_description = data?.about_us?.description || "";
        this.package_list = data?.packages_detail?.package_list || [];
        this.show_packages = data?.packages_detail?.is_enabled || false;
        this.show_services = data?.services_detail?.is_enabled || false;
        this.services_list = data?.services_detail?.service_list || [];
        this.about_us_image.url = data?.about_us?.image_url || null;
        this.about_us_image.file = data?.about_us?.image_url || null;
        this.about_us_image.id = data?.about_us?.image_id || null;
        this.about_us_image.isSaved = data?.about_us?.image_url ? true : false;
        this.isAboutUsModelOpen = data?.about_us?.image_url ? false : true;
        this.selectedImageId = data?.header_section_data?.body_image_url || "";
      },
    },
  },
  created() {
    this.onWebsiteConfigLoadHandler();
  },
});
</script>

<style scoped lang="scss">
.section-title {
  margin: 0;
  border: none;
  padding: 0;
}
.section-content {
  padding-top: 20px;
}

.section-content-button {
  padding-top: 0px;
  align-self: self-end;
}
.form-container {
  background: #f6f6f6;
  padding: 20px 0;
  border-radius: 8px;
}

.website-slug {
  font-weight: bold;
  color: var(--green-color);
}
.list-header {
  display: flex;
  gap: 10px;
  align-items: center;
  .list-thumb {
    width: 45px;
    height: 45px;
    border-radius: 5px;
  }
}

.package_list_item {
  background: #f6f6f6;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  & > p {
    color: var(--green-color) !important;
  }
  p {
    margin: 0;
    font-weight: 600;
    font-size: 17px;
  }
  button {
    margin-right: 10px;
  }
  .edit_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      margin-right: 20px;
    }
  }
}
</style>

<style lang="scss">
.v-switch {
  .text-primary {
    color: var(--green-color) !important;
  }
}
</style>
