<template>
  <AgFlightTerminalInfo
    :origin-city="segment.origin.city"
    :origin-airport-name="segment.origin.airport"
    :origin-time="formatTime(segment.departure_datetime)"
    :duration="legDuration(segment.duration_minutes)"
    :destination-city="segment.destination.city"
    :destination-airport-name="segment.destination.airport"
    :destination-time="formatTime(segment.arrival_datetime)"
    :airline-logo="segment.operating_airline.logo"
    :airline-code="renderFlightNumber(segment.flight_number)"
    :airline-name="segment.operating_airline.name"
    test-id=""
  />
  <!-- Layoff Time duration -->
  <AgHeading
    v-if="showLayOff"
    class="flight_info_green_box highlighted"
    :title="renderLayoffTime"
    variant="h4"
    test-id=""
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Segment } from "../../types/Segment";

import { TimeUtility } from "../../utils/TimeUtility";

export default defineComponent({
  name: "FlightRoute",
  props: {
    segment: {
      type: Object as PropType<Segment>,
      default: () => ({}),
      requried: true,
    },
    nextDepartureTime: {
      type: Date,
      default: null,
    },
    showLayOff: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration: (minutes: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(minutes);
      return duration;
    },
    renderFlightNumber: (flightNumber: string[]) => {
      return flightNumber[0];
    },
  },
  computed: {
    renderLayoffTime(): string {
      const duration = TimeUtility.getTotlaDurationInText(
        this.segment.arrival_datetime,
        this.nextDepartureTime
      );
      return `${this.segment.destination.airport} ${duration} Stop 🕒`;
    },
  },
});
</script>
