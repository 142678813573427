import RestClientService from "@/ag-portal-common/services/restClient.service";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IFDG } from "@/interfaces/fdg.interface";
import { GroupFlightsResponseModel } from "@/modules/FDG/models/fdgList.response";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { FDG_FLIGHTS_API_PATH } from "@/modules/FDG/constants";
import { FdgMapper } from "@/modules/FDG/responseMappers/fdg.mapper";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { GroupFlightDetailResponseModel } from "@/modules/FDG/models/fdgDetail.response";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { IFDGBookingDetail } from "@/interfaces/fdgBookingDetail.interface";
import { FDGBookingDetailResponseModel } from "@/modules/FDG/models/fdgBookingDetail.response";
import { FDGBookingDetailRequestModel } from "@/modules/FDG/models/fdgBookingDetail.request";
import { FDGBookingConfirmRequestModel } from "@/modules/FDG/models/fdgBookingConfirm.request";
import { FDGBookingConfirmWithoutTravelerDetailsRequestModel } from "@/modules/FDG/models/fdgBookingConfirmWithoutTravelerDetails.request";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";

class FDGService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fetchGroupFlights(tourType: TOUR_TYPES): Promise<IFDG[]> {
    const methodName = "FdgService.fetchGroupFlights";
    let mappedGroupFlights: IFDG[] = [];
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<GroupFlightsResponseModel> =
        await this.restClient.get(
          `${FDG_FLIGHTS_API_PATH.GET}/?tour_type=${tourType}`
        );

      if (response.success && response.data) {
        const fdgListMapper = new FdgMapper();
        mappedGroupFlights = fdgListMapper.mapFDGListResponse(response.data);
      } else {
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedGroupFlights;
  }

  public async fetchGroupFlightDetail(id: string): Promise<IFDGDetail | null> {
    const methodName = "FdgService.fetchGroupFlightDetail";
    let mappedFDGDetail: IFDGDetail | null = null;
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<GroupFlightDetailResponseModel> =
        await this.restClient.get(
          formatString(FDG_FLIGHTS_API_PATH.GET_BY_ID, id)
        );

      if (response.success && response.data) {
        const fdgListMapper = new FdgMapper();
        mappedFDGDetail = fdgListMapper.mapFDGDetailResponse(response.data);
      } else {
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedFDGDetail;
  }

  public async fetchGroupFlightBookingDetail(
    id: string
  ): Promise<IFDGBookingDetail | null> {
    const methodName = "FdgService.fetchGroupFlightBookingDetail";
    let mappedGroupFlightBookingDetail: IFDGBookingDetail | null = null;
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<FDGBookingDetailResponseModel> =
        await this.restClient.get(
          formatString(FDG_FLIGHTS_API_PATH.GET_BOOKING_BY_ID, id)
        );

      if (response.success && response.data) {
        const fdgListMapper = new FdgMapper();
        mappedGroupFlightBookingDetail =
          fdgListMapper.mapFDGBookingDetailResponse(response.data);
      } else {
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedGroupFlightBookingDetail;
  }

  public async initiateBooking(
    payload: FDGBookingDetailRequestModel,
    callback: (bookingId: string) => void
  ): Promise<IFDGBookingDetail | null> {
    const methodName = "FdgService.initiateBooking";
    let mappedGroupFlightBookingDetail: IFDGBookingDetail | null = null;
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<FDGBookingDetailResponseModel> =
        await this.restClient.post(
          FDG_FLIGHTS_API_PATH.POST_INITIATE_BOOKING,
          payload
        );

      if (response.success && response.data) {
        const fdgListMapper = new FdgMapper();
        mappedGroupFlightBookingDetail =
          fdgListMapper.mapFDGBookingDetailResponse(response.data);
        callback(mappedGroupFlightBookingDetail?.bookingId || "");
      } else {
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedGroupFlightBookingDetail;
  }

  public async confirmBooking(
    payload: FDGBookingConfirmRequestModel,
    callback: () => void
  ) {
    const methodName = "FdgService.confirmBooking";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse = await this.restClient.post(
        FDG_FLIGHTS_API_PATH.POST_CONFIRM_BOOKING,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.success) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.description =
          NOTIFICATION_MESSAGES.FDG_FLIGHTS_CONFIRM_BOOKING_SUCCESS;
        callback();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();
  }

  public async confirmGroupFlightBookingWithoutTravelerDetails(
    payload: FDGBookingConfirmWithoutTravelerDetailsRequestModel,
    callback: () => void
  ) {
    const methodName = "FdgService.confirmBookingWithoutDetails";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse = await this.restClient.post(
        FDG_FLIGHTS_API_PATH.POST_CONFIRM_BOOKING_WITHOUT_TRAVELER_DETAILS,
        payload
      );
      if (response.success) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.description =
          NOTIFICATION_MESSAGES.FDG_FLIGHTS_CONFIRM_BOOKING_SUCCESS;
        callback();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();
  }
}

export default FDGService;
