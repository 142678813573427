<template>
  <MCard class="organization-snapshot-header">
    <MCard>
      <p class="heading">Statistics {{ renderOrgName() }}</p>
      <ag-row class="mt-10">
        <ag-column xs="12" sm="12" md="12" lg="5">
          <ag-calendar
            label="Start Date"
            @update:startDate="(value:any) => startDate = value"
            calendar-name="startDate"
            :max-date="new Date()"
            :date="startDate ? startDate : new Date()"
          />
        </ag-column>
        <ag-column xs="12" sm="12" md="12" lg="5">
          <ag-calendar
            label="End Date"
            @update:endDate="(value:any) => endDate = value"
            calendar-name="endDate"
            :max-date="new Date()"
            :min-date="startDate"
            :date="endDate ? endDate : new Date()"
          />
        </ag-column>
        <ag-column
          xs="12"
          sm="12"
          md="12"
          lg="2"
          class="d-flex align-items-center"
        >
          <MButton
            @click="handleFetchData"
            :disabled="isLoading"
            test-id=""
            variant="primary"
            class="fire_btn_wrap"
            >Fetch Statistics</MButton
          >
        </ag-column>
      </ag-row>
    </MCard>
    <template v-if="isOrganizationProductivityLoading">
      <ShimmerCard card-height="200px" />
      <ShimmerCard card-height="200px" />
      <ShimmerCard card-height="150px" />
      <ShimmerCard card-height="100px" />
    </template>

    <template v-else>
      <template v-if="showDetails">
        <!-- Overall Counts -->
        <SnapshotDetailAccordion title="Overall Counts">
          <template #overview>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Total Flight Bookings"
                :value="
                  organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_flight_bookings
                "
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-issue-ticket"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Total Issued Tickets"
                :value="
                  organizationProductivity?.total_tickets_issued
                    ?.total_issued_tickets
                "
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-issue-ticket"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Total Hotel Bookings"
                :value="
                  organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotel_bookings
                "
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-hotel"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Total Issued Hotels"
                :value="
                  organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotel_issued
                "
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-hotel"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Total Payment Created"
                :value="
                  organizationProductivity?.payment_details?.aggregates
                    .total_payments_created
                "
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-payment-management"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Total Credit Limit Requests"
                :value="organizationProductivity?.total_credit_limit_requests"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-credit-limit-management"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <MSnapshotItemCountTile
                title="Total Sabre Segments"
                :value="organizationProductivity?.total_sabre_segments"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-sabre-segment"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile> </ag-column
          ></template>
        </SnapshotDetailAccordion>

        <!-- Booking Card -->
        <SnapshotDetailAccordion title="Booked">
          <template #overview>
            <ag-column
              v-for="(item, index) in organizationProductivity
                ?.hotel_and_flight_details?.flight_bookings"
              :key="index"
              xs="12"
              sm="12"
              md="6"
              lg="3"
            >
              <MSnapshotItemCountTile
                :title="replaceUnderscoreWithSpace(item.provider_name)"
                :value="item.count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-flight"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <p class="empty-items-message" v-show="showBookedEmptyMsg">
              Nothing Booked Yet
            </p>
          </template>
        </SnapshotDetailAccordion>

        <!-- Issued Card -->
        <SnapshotDetailAccordion title="Issued">
          <template #overview>
            <ag-column
              v-for="(item, index) in organizationProductivity
                ?.total_tickets_issued?.issued_tickes"
              :key="index"
              xs="12"
              sm="12"
              md="6"
              lg="3"
            >
              <MSnapshotItemCountTile
                :title="replaceUnderscoreWithSpace(item.provider_name)"
                :value="item.count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-flight"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <p class="empty-items-message" v-show="showIssuedEmptyMsg">
              Nothing Issued Yet
            </p>
          </template>
        </SnapshotDetailAccordion>

        <!-- Payment Card -->
        <SnapshotDetailAccordion title="Payments">
          <template #overview>
            <ag-column
              v-for="(item, index) in organizationProductivity?.payment_details
                ?.payments"
              :key="index"
              xs="12"
              sm="12"
              md="6"
              lg="3"
            >
              <MSnapshotItemCountTile
                :title="replaceUnderscoreWithSpace(item.payment_method)"
                :value="item.count"
              >
                <template #icon>
                  <MIcon
                    class="details-type-icon icon white-icon"
                    name="m-payment-management"
                    width="24"
                    height="24"
                  />
                </template>
              </MSnapshotItemCountTile>
            </ag-column>
            <p class="empty-items-message" v-show="showPaymentsEmptyMsg">
              No Payment Found
            </p>
          </template>
        </SnapshotDetailAccordion>
      </template>
      <ResultNotFound v-if="showResultNotFound" />
    </template>
  </MCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";
import _ from "lodash";

import { IOrganizationProductivity } from "@/ag-portal-common/interfaces/organizationProductivity.interface";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { IOrganizationStats } from "@/ag-portal-common/interfaces/organizationStatistics.interface";
import { MCard, MButton, MIcon } from "@aeroglobe/ag-core-ui";
import ShimmerCard from "@/components/ShimmerCard.vue";
import SnapshotDetailAccordion from "@/components/SnapshotDetailAccordion.vue";
import MSnapshotItemCountTile from "../MSnapshotItemCountTile.vue";

import ResultNotFound from "../OrganizationDetails/ResultNotFound.vue";

export default defineComponent({
  name: "OrganizationSnapshotProductionTab",
  components: {
    MCard,
    MButton,
    ShimmerCard,
    ResultNotFound,
    SnapshotDetailAccordion,
    MSnapshotItemCountTile,
    MIcon,
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedOrg: string;
    isInitial: boolean;
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedOrg: "",
      isInitial: true,
    };
  },
  methods: {
    handleFetchData() {
      const { org_id } = this.$route.query;
      this.$store.dispatch("getOrganizationProductivity", {
        organizationId: org_id,
        payload: {
          start_date: format(this.startDate, FORMAT_YYY_MM_DD),
          end_date: format(this.endDate, FORMAT_YYY_MM_DD),
        },
      });
    },
    renderOrgName() {
      const org_name = this.organizationStats?.organization?.org_name;
      return org_name ? `- ${org_name}` : "";
    },
    replaceUnderscoreWithSpace(title: string) {
      return title.replaceAll("_", " ");
    },
  },
  computed: {
    organizationProductivity(): IOrganizationProductivity {
      return this.$store.getters.organizationProductivity;
    },
    isLoading(): boolean {
      return this.$store.getters.isFetchingOrganizations;
    },
    isOrganizationProductivityLoading(): boolean {
      return this.$store.getters.isOrganizationProductivityLoading;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.organizationProductivity);
      return isNotEmpty;
    },
    showIssuedEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.total_tickets_issued?.issued_tickes
          .length < 1
      );
    },
    showBookedEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.hotel_and_flight_details?.flight_bookings
          .length < 1
      );
    },
    showPaymentsEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.payment_details?.payments.length < 1
      );
    },
    organizationStats(): IOrganizationStats {
      return this.$store.getters.organizationStats;
    },
    showResultNotFound(): boolean {
      return (
        !this.isInitial &&
        !this.isOrganizationProductivityLoading &&
        !this.showDetails
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.organization-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-left: solid 8px var(--green-color);
}

.heading {
  font-size: 24px;
  font-weight: 600;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}
.empty-items-message {
  width: 100%;
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.white-icon {
  color: #fff;
}
</style>
