import { ActionContext, ActionTree } from "vuex";
import { IFinancialProfileState } from "@/ag-portal-common/interfaces/financialProfileState.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import FinancialProfileService from "@/modules/FinancialProfile/services/financialProfile.service";
import { LinkOrganizationDto } from "@/modules/FinancialProfile/dtos/linkOrganization.dto";
import { GetFinancialProfileDto } from "@/modules/FinancialProfile/dtos/financialProfile.dto";
import { INotification } from "@/ag-portal-common/interfaces/notification.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { StoreState } from "@/store/type";

const actions: ActionTree<IFinancialProfileState, StoreState> = {
  async fetchFinancialProfiles(
    context: ActionContext<IFinancialProfileState, StoreState>,
    params: GetFinancialProfileDto
  ) {
    const methodName = "actions.fetchFinancialProfiles";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsFetchingFinancialProfiles");
      const financialProfileService = new FinancialProfileService();
      const response: IAGResponse = await financialProfileService.getAll(
        params
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveFinancialProfiles", response.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("disableIsFetchingFinancialProfiles");
      loggerService.logError(`${methodName}:`, exception);
    }
  },

  async fetchFinancialProfile(
    context: ActionContext<IFinancialProfileState, StoreState>,
    { id, params }: { id: string; params: GetFinancialProfileDto }
  ) {
    const methodName = "actions.fetchFinancialProfile";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsFetchingFinancialProfile");
      const financialProfileService = new FinancialProfileService();
      const response: IAGResponse = await financialProfileService.getById(
        id,
        params
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveFinancialProfile", response.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      context.commit("disableIsFetchingFinancialProfile");
      loggerService.logError(`${methodName}:`, exception);
    }
  },

  async linkFinancialProfileWithOrganization(
    context: ActionContext<IFinancialProfileState, StoreState>,
    {
      body,
      params,
      failureCallback,
      successCallback,
      financialProfileId,
    }: {
      body: LinkOrganizationDto;
      params: GetFinancialProfileDto;
      failureCallback: () => void;
      successCallback: () => void;
      financialProfileId: string;
    }
  ) {
    const methodName = "actions.linkFinancialProfileWithOrganization";
    let response: IAGResponse = {
      success: false,
    };
    const notificationData: INotification = {
      type: NOTIFICATION_TYPES.INFO,
      description: "",
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsLinkingFinancialProfile");
      const financialProfileService = new FinancialProfileService();
      response = await financialProfileService.linkWithOrganization(
        body,
        financialProfileId,
        params
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationData.type = NOTIFICATION_TYPES.SUCCESS;
        notificationData.description =
          NOTIFICATION_MESSAGES.LINKED_FINANCIAL_PROFILE_SUCCESS;

        successCallback();
        context.commit("appendFinancialProfileInOrganization", response.data);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationData.type = NOTIFICATION_TYPES.ERROR;
      notificationData.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      failureCallback();
      loggerService.logError(`${methodName}:`, exception);
    } finally {
      notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, notificationData);
      context.commit("disableIsLinkingFinancialProfile");
    }
  },
  async unlinkFinancialProfile(
    context: ActionContext<IFinancialProfileState, StoreState>,
    { id, successCallback }: { id: string; successCallback: () => void }
  ): Promise<void> {
    const methodName = "actions.unlinkFinancialProfile";
    // const response: IAGResponse = {
    //   success: false,
    // };
    const notificationData: INotification = {
      type: NOTIFICATION_TYPES.INFO,
      description: "",
    };
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableIsUnlinkingFinancialProfile");
      const financialProfileService = new FinancialProfileService();
      await financialProfileService.unlinkFinancialProfile(id);
      context.commit("removeFinancialProfileFromOrganization", id);
      successCallback();
      notificationData.type = NOTIFICATION_TYPES.SUCCESS;
      notificationData.description =
        NOTIFICATION_MESSAGES.UNLINKED_FINANCIAL_PROFILE_SUCCESS;
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      notificationData.type = NOTIFICATION_TYPES.ERROR;
      notificationData.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      loggerService.logError(`${methodName}:`, exception);
    } finally {
      notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, notificationData);
      context.commit("disableIsUnlinkingFinancialProfile");
    }
  },
};

export default actions;
