<template>
  <AgDiv class="widget_border margin_top_20 margin_bottom_20" test-id="">
    <ag-accordion
      :panel-value="[1, 2]"
      class="flight_accordion_wrap margin_bottom_0"
      title="flight acc 2"
    >
      <ag-accordion-panel>
        <template #flightInfo>
          <AgRow test-id="">
            <AgColumn lg="3" md="4" sm="12" test-id="">
              <AgIconBox
                :sub-title="ietnaryDetail.itemName"
                :title="ietnaryDetail.itemType"
                icon="hotelIcon"
                test-id=""
              ></AgIconBox>
            </AgColumn>
            <AgColumn lg="9" md="8" sm="12" test-id="">
              <AgIconBox
                :sub-title="`Distance - ${ietnaryDetail.hotelItemDetails?.distance}`"
                :title="ietnaryDetail.hotelItemDetails?.propertyName"
                test-id=""
              ></AgIconBox>
            </AgColumn>
          </AgRow>
        </template>
        <template #tabSection>
          <AgPropertyReview
            :checkin-date="
              formattedDayAndDate(ietnaryDetail.hotelItemDetails?.checkIn)
            "
            :checkout-date="
              formattedDayAndDate(ietnaryDetail.hotelItemDetails?.checkOut)
            "
            :disableHeading="true"
            :hotel-name="ietnaryDetail.hotelItemDetails?.propertyName"
            :hotel-thumb="ietnaryDetail.hotelItemDetails?.images[0] || ''"
            :no-of-night="numberOfNights"
            test-id=""
          >
            <template #hotelImages>
              <ag-div>
                <ag-heading title="Includes" variant="h3" />

                <ag-chip
                  v-for="(facility, index) in ietnaryDetail.hotelItemDetails
                    ?.facilities"
                  v-bind:key="index"
                  class="margin_right_10"
                >
                  {{ facility }}
                </ag-chip>
              </ag-div>
            </template>
          </AgPropertyReview>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IFDGItem } from "@/interfaces/fdg.interface";
import {
  getDayFromDate,
  getDurationText,
  getFormattedDateTime,
  hotelNumberOfNightStayText,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_HH_SS_A,
  FORMAT_MMMM_DD_YYYY,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { DAYS } from "@/ag-portal-common/enums/DAYS";

export default defineComponent({
  name: "HotelItenary",
  props: {
    ietnaryDetail: {
      type: Object as PropType<IFDGItem>,
    },
  },
  methods: {
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_A);
    },
    formattedDayAndDate(date: Date) {
      return (
        DAYS[getDayFromDate(date)] +
        ", " +
        getFormattedDateTime(date, FORMAT_MMMM_DD_YYYY)
      );
    },
    getDurationText,
  },
  computed: {
    numberOfNights(): string {
      let _checkInDate = new Date(
        this.ietnaryDetail?.hotelItemDetails?.checkIn || Date.now()
      );
      let _checkOutDate = new Date(
        this.ietnaryDetail?.hotelItemDetails?.checkOut || Date.now()
      );
      return hotelNumberOfNightStayText(_checkInDate, _checkOutDate);
    },
  },
});
</script>
