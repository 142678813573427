<template>
  <AgCard class="insights_header">
    <p class="heading">24 Hours Hotels Issuance & Booking Data</p>
    <div class="insights_header_action_btn">
      <ag-calendar
        label="Issuance Date"
        @update:issuanceDate="(value) => (date = value)"
        calendar-name="issuanceDate"
        :max-date="new Date()"
        :date="date"
        :min-date="minDate"
      />
      <AGButton
        variant="primary"
        :is-loading="isLoading"
        class="fire_btn_wrap"
        id="fetch_btn"
        icon="refreshIcon"
        @click="fetchData"
        >Fetch</AGButton
      >
    </div>
  </AgCard>

  <IssuanceChartShimmer v-if="isLoading" />
  <template v-else>
    <AgCard class="chart_card">
      <highcharts class="hc" :options="issuanceChartOptions" ref="chart" />
    </AgCard>
    <AgCard class="chart_card">
      <highcharts class="hc" :options="bookingChartOptions" ref="chart" />
    </AgCard>
  </template>
</template>

<script>
import Highcharts from "highcharts";
import { sub, format } from "date-fns";
import exportingInit from "highcharts/modules/exporting";

import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import IssuanceChartShimmer from "./IssuanceChartShimmer.vue";

exportingInit(Highcharts);

export default {
  components: { IssuanceChartShimmer },
  data() {
    return {
      date: new Date(),
      hoursCategories: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isDashboardInsightsLoading;
    },
    issuanceChartOptions() {
      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: "Hotels Issuance In 24 Hours",
        },
        yAxis: {
          title: {
            text: "Total Issuances",
          },
          crosshair: true,
        },
        xAxis: {
          categories: this.hoursCategories,
          crosshair: true,
          accessibility: {
            description: "Hours",
          },
        },
        tooltip: {
          headerFormat: "<table>",
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        series: this.generateIssuanceSeries(),
        plotOptions: {
          bar: {
            grouping: true,
          },
        },
      };
      return options;
    },
    bookingChartOptions() {
      const options = {
        chart: {
          type: "column",
        },
        title: {
          text: "Hotels Bookings In 24 Hours",
        },
        yAxis: {
          title: {
            text: "Total Bookings",
          },
          crosshair: true,
        },
        xAxis: {
          categories: this.hoursCategories,
          crosshair: true,
          accessibility: {
            description: "Hours",
          },
        },
        tooltip: {
          headerFormat: "<table>",
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        series: this.generateBookingSeries(),
        plotOptions: {
          bar: {
            grouping: true,
          },
        },
      };
      return options;
    },
    minDate() {
      const minDate = sub(this.date, { days: 15 });
      return minDate;
    },
  },
  methods: {
    generateIssuanceSeries() {
      const dailyIssuanceData =
        this.$store.getters.dailyHotelIssuanceData || {};
      const hours = Object.keys(dailyIssuanceData?.issuance);
      const providerNames = Array.from(
        new Set(
          hours.flatMap((hour) =>
            dailyIssuanceData?.issuance[hour].providers.map(
              (provider) => provider.provider_name
            )
          )
        )
      );

      const seriesData = providerNames.map((provider) => {
        return {
          name: provider,
          data: hours.map((hour) => {
            const providerData = dailyIssuanceData?.issuance[
              hour
            ].providers.find((p) => p.provider_name === provider);
            return providerData ? providerData.count : 0;
          }),
        };
      });
      return seriesData;
    },
    generateBookingSeries() {
      const dailyBookingData = this.$store.getters.dailyHotelIssuanceData || {};
      const hours = Object.keys(dailyBookingData?.confirmed);
      const providerNames = Array.from(
        new Set(
          hours.flatMap((hour) =>
            dailyBookingData?.confirmed[hour].providers?.map(
              (provider) => provider?.provider_name
            )
          )
        )
      );

      const seriesData = providerNames?.map((provider) => {
        return {
          name: provider,
          data: hours.map((hour) => {
            const providerData = dailyBookingData?.confirmed[
              hour
            ].providers?.find((p) => p.provider_name === provider);
            return providerData ? providerData?.count : 0;
          }),
        };
      });
      return seriesData;
    },
    fetchData() {
      this.$store.dispatch(
        "getDailyHotelsIssuanceData",
        format(this.date, FORMAT_YYY_MM_DD)
      );
    },
  },
  beforeMount() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 500;
}
.insights_header {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}

.insights_header_action_btn {
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 10px;
  }
}
.chart_card {
  margin-top: 20px;
}
</style>
