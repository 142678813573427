import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import {
  IAGErrorResponse,
  IAGResponse,
} from "@/ag-portal-common/interfaces/agResponse.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import FlightService from "@/ag-flight-components/services/flight.service";
import AirportService from "@/ag-flight-components/services/airport.service";
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents";

import {
  ACTIVE_PROVIDERS_AND_AIRLINES_API_RESPONSE,
  FetchFlightsPayload,
  FlightPreBookingTokenType,
  FlightResponse,
} from "@/ag-flight-components/types/Flights";
import { Place } from "@/ag-flight-components/types/Place";
import { IFlightSearch } from "@/ag-flight-components/interfaces/IFlightSearch.interface";
import analyticsService from "@/analytic.service";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { StoreState } from "@/store/type";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";

const actions: ActionTree<IFlightSearch, StoreState> = {
  // **** Fetch Airports ****
  async fetchAirports(
    context: ActionContext<IFlightSearch, StoreState>,
    payload: { location: string; type: "origin" | "destination" }
  ) {
    const methodName = "actions.fetchAirports";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      if (payload.type === "origin") {
        context.commit("loadingOriginAirports", true);
      } else if (payload.type === "destination") {
        context.commit("loadingDestinationAirports", true);
      }

      const fetchAirports = new AirportService();
      const response: IAGResponse<any> = await fetchAirports.get(
        payload.location
      );
      if (response.success && response.status === StatusCodes.OK) {
        const formattedAirports = response.data?.data?.map((item: Place) => {
          return {
            ...item,
            label: `${item.city} (${item.iata_code})`,
          };
        });

        if (payload.location === "Pakistan") {
          context.commit("saveAirports", formattedAirports);
          context.commit("saveOriginAirports", formattedAirports);
          context.commit("saveDestinationAirports", formattedAirports);
        }

        if (payload.type === "origin") {
          context.commit("saveOriginAirports", formattedAirports);
        } else if (payload.type === "destination") {
          context.commit("saveDestinationAirports", formattedAirports);
        }

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      if (payload.type === "origin") {
        context.commit("loadingOriginAirports", false);
      } else if (payload.type === "destination") {
        context.commit("loadingDestinationAirports", false);
      }
    }
  },

  async fetchMultiCityAirports(
    context: ActionContext<IFlightSearch, StoreState>,
    payload: { location: string; type: "origin" | "destination"; index: number }
  ) {
    const methodName = "actions.fetchMultiCityAirports";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      if (payload.index !== null) {
        context.commit("setMulticityAirportInputLoading", {
          type: payload.type,
          index: payload.index,
        });
      }

      const fetchAirports = new AirportService();
      const response: IAGResponse<any> = await fetchAirports.get(
        payload.location
      );
      if (response.success && response.status === StatusCodes.OK) {
        const formattedAirports = response.data?.data?.map((item: Place) => {
          return {
            ...item,
            label: `${item.city} (${item.iata_code})`,
          };
        });

        if (payload.location === "Pakistan") {
          context.commit("saveAirports", formattedAirports);
          context.commit("setMulticityAirports", {
            airports: formattedAirports,
            type: "origin",
            index: 0,
          });
          context.commit("setMulticityAirports", {
            airports: formattedAirports,
            type: "destination",
            index: 0,
          });
          context.commit("setMulticityAirports", {
            airports: formattedAirports,
            type: "origin",
            index: 1,
          });
          context.commit("setMulticityAirports", {
            airports: formattedAirports,
            type: "destination",
            index: 1,
          });
        }

        if (payload?.index?.toString()) {
          context.commit("setMulticityAirports", {
            airports: formattedAirports,
            type: payload.type,
            index: payload.index,
          });
        }

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("setMulticityAirportInputLoading", {
        type: null,
        index: null,
      });
    }
  },

  // **** Fetch Flights ****
  async fetchFlights(
    context: ActionContext<IFlightSearch, StoreState>,
    payload: FetchFlightsPayload
  ) {
    const methodName = "actions.fetchFlights";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      if (!payload.poll_id) {
        context.commit("loadingFlights", true);
      }

      const flightService = new FlightService();
      const response: IAGResponse = await flightService.post(payload);

      if (response.success && response.status === StatusCodes.OK) {
        const flightResponse: FlightResponse = response.data.data;

        context.commit("setFlightsResponse", flightResponse);

        if (flightResponse.journey_legs.length > 0) {
          context.commit("saveFlights", flightResponse);
          context.commit("saveCabinClass", payload.cabin_class);
          context.commit("saveRouteType", payload.route_type);

          if (payload.route_type === ROUTE_TYPE.MULTI_CITY) {
            const journey_legs = flightResponse.journey_legs;
            const multicityAirports = journey_legs.map((leg) => {
              return {
                origin: [leg.origin],
                destination: [leg.destination],
              };
            });

            context.commit("saveBulkMulticityAirports", multicityAirports);

            const multicityLegs = journey_legs.map((leg) => {
              return {
                departure_date: new Date(leg.departure_date),
                origin: leg.origin,
                destination: leg.destination,
              };
            });

            context.commit("saveMulticityLegs", multicityLegs);
          } else {
            const origin = flightResponse.journey_legs[0]?.origin || {};
            const destination =
              flightResponse.journey_legs[0]?.destination || {};
            const departure_date =
              flightResponse.journey_legs[0]?.departure_date;
            const return_date = flightResponse.journey_legs[1]?.departure_date;

            context.commit("saveOriginAirports", [origin]);
            context.commit("saveDestinationAirports", [destination]);
            context.commit("saveOrigin", origin);
            context.commit("saveDestination", destination);
            context.commit("saveDepartureDate", new Date(departure_date));
            if (return_date) {
              context.commit("saveReturnDate", new Date(return_date));
            }
          }

          context.commit("saveRouteType", payload.route_type);
          context.commit("saveTravelersCount", payload.traveler_count);
        }

        const analyticsPayload = {
          from: payload.origin,
          to: payload.destination,
          "departure-date": payload.departure_date,
          "return-date": payload.return_date,
          "route-type": payload.route_type,
          "adult-traveler-count": payload.traveler_count.adult_count,
          "child-traveler-count": payload.traveler_count.child_count,
          "infant-traveler-count": payload.traveler_count.infant_count,
          "cabin-class": payload.cabin_class,
          "non-stop-flight": false,
        };

        analyticsService.logActionEvent(
          FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOKING_SEARCH_APPLY,
          analyticsPayload
        );

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);

        context.commit("setIsPolling", flightResponse.keep_polling);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
      context.commit("setIsPolling", false);
    } finally {
      context.commit("loadingFlights", false);
    }
  },

  // INITIATE BOOKING
  async initiateBooking(
    context: ActionContext<IFlightSearch, StoreState>,
    {
      payload,
      successCallback,
    }: {
      payload: FlightPreBookingTokenType;
      successCallback: (booking_id: string) => void;
    }
  ) {
    const methodName = "actions.initiateBooking";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setLoading", true);
      const flightService = new FlightService();
      const response: IAGResponse = await flightService.initiateBooking(
        payload
      );
      if (response.success && response.status === StatusCodes.OK) {
        const booking_id = response?.data?.data?.booking_id;
        successCallback(booking_id);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    } finally {
      context.commit("setLoading", false);
    }
  },

  // FETCH AIRLINES AND SABRE PROVIDERS
  async fetchAirlinesAndProviders(
    context: ActionContext<IFlightSearch, StoreState>,
    organizationId: string
  ) {
    const methodName = "actions.fetchAirlinesAndProviders";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const flightService = new FlightService();
      const response: IAGResponse =
        await flightService.getActiveAirlinesAndAirlines(organizationId);

      if (response.success && response.status === StatusCodes.OK) {
        const responseData: ACTIVE_PROVIDERS_AND_AIRLINES_API_RESPONSE =
          response.data;

        context.commit("addActiveAirlinesAndProviders", responseData);

        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const exception = error as IAGErrorResponse;

      loggerService.logError(`${methodName}:`, exception);

      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
      notificationService.triggerNotification();
    }
  },
};
export default actions;
