import { GetterTree } from "vuex";

import {
  FetchFlightRefundPNRResponse,
  IFlightRefundState,
} from "@/ag-flight-components/interfaces/IFlightRefunds.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IFlightRefundState, StoreState> = {
  isPNRFetching: (state): boolean => state.isPNRFetching,
  refundPNRDetails: (state): FetchFlightRefundPNRResponse | null =>
    state.refundPNRDetails,
  refundPNR: (state): string | null => state.refundPNR,
  calculateRefundDetails: (state) => state.calculateDetails,
  isCalculatingRefund: (state): boolean => state.canCalculateRefund,
  isCommitingRefund: (state): boolean => state.isCommitingRefund,
};

export default getters;
