<template>
  <div class="refunds refunds-view-page">
    <section>
      <m-card class="refunds-inputs">
        <MFinancialProfileCombobox
          v-model:inputValue="flightInformation.financialProfile.value"
          label="Select Financial Profile"
          itemValue="value"
          itemLabel="label"
          :disabled="isFinancialProfilesLoading"
          :options="financialProfiles"
          :hasError="flightInformation.financialProfile.hasError"
          :errorMessage="flightInformation.financialProfile.errorMessage"
        />
        <div>
          <div class="row">
            <m-combobox
              v-model:inputValue="flightInformation.supplier.value"
              :options="suppliers"
              itemValue="value"
              itemLabel="label"
              label="Airline/Supplier"
              :hasError="flightInformation.supplier.hasError"
              :errorMessage="flightInformation.supplier.errorMessage"
              :disabled="isFetchingPNR"
            />

            <m-textfield
              v-model:inputValue.trim="flightInformation.pnrNumber.value"
              label="PNR Number"
              :hasError="flightInformation.pnrNumber.hasError"
              :errorMessage="flightInformation.pnrNumber.errorMessage"
              :disabled="isFetchingPNR"
            />
          </div>
        </div>

        <div class="actions">
          <m-button
            class="refunds-action"
            :disabled="!canFetchPnrDetails"
            type="filled"
            @click="canFetchPnrDetails ? handleFetchPnrDetails() : ''"
          >
            {{
              isFetchingPNR ? "Fetching Refund Details" : "Get Refund Details"
            }}
          </m-button>
          <m-button
            class="issuance-action"
            :disabled="infoHasErrors()"
            type="outlined"
            @click="handleDataReset()"
          >
            Reset
          </m-button>
        </div>
      </m-card>
    </section>
    <!-- note -->
    <template v-if="hasPnrDetails">
      <m-typography class="m-header" type="title"> Note: </m-typography>
      <m-typography class="m-header" type="body">
        {{ refundNote(flightInformation.supplier.value) }}
      </m-typography>
    </template>
    <!-- penalty details -->
    <PenaltyDetails v-if="hasPnrDetails && !calculatedRefundDetails" />

    <!-- selectable components -->
    <MAccordion
      :initial-open="true"
      :externalOpen="isPassengerDetailsOpen"
      v-if="hasPnrDetails && !calculatedRefundDetails"
    >
      <template #title>
        <AgIconInfoBar
          test-id=""
          mPrepandIcon="m-passenger-management"
          title="Passenger Details"
          class="section-title"
        />
      </template>
      <template #content>
        <div class="flight-refunds-content">
          <div>
            <div v-for="(passenger, index) in passengers" :key="index">
              <MAccordion class="passenger-accordion-header">
                <template #title>
                  <div class="refund-passenger">
                    <div class="widget_border refunds-checkbox">
                      <!-- Passenger Checkbox -->
                      <v-checkbox
                        v-model="passenger.selected"
                        v-show="
                          showPassenger && !isPassengerFullyRefunded(passenger)
                        "
                        @click.stop=""
                        @change.stop="handlePassengerSelect(passenger.selected)"
                      ></v-checkbox>
                      <MChip
                        v-if="
                          !(
                            showPassenger &&
                            !isPassengerFullyRefunded(passenger)
                          )
                        "
                        variant="error"
                      >
                        {{
                          isPassengerFullyRefunded(passenger)
                            ? "Fully Refunded"
                            : "Fully Travelled"
                        }}
                      </MChip>
                      <AgContactInfo
                        icon-name="pakFlag"
                        :title="renderPassengerTitle(passenger)"
                        class="contact-info"
                      />

                      <AgContactInfo
                        :mPrepandIcon="
                          renderPassengerType(passenger.passenger_type).icon
                        "
                        :title="
                          renderPassengerType(passenger.passenger_type).type
                        "
                        class="contact-info"
                      />

                      <AgContactInfo
                        icon-name="notInvoice"
                        :title="passenger.ticket_number"
                        class="contact-info"
                      />
                    </div>
                  </div>
                </template>
                <template #content>
                  <div v-if="hasLegDetails(passenger.leg_details)">
                    <MCard
                      v-for="(legs, legIndex) in passenger.leg_details"
                      :key="legIndex"
                      class="no-padding-card"
                    >
                      <div class="refund-passenger">
                        <div
                          :class="
                            isUnselected(legs.fare_detail.status)
                              ? 'un-selected-passenger'
                              : 'selected-passenger'
                          "
                        >
                          <!-- Leg Checkbox -->
                          <input
                            type="checkbox"
                            v-show="showLegs"
                            v-if="isUnselected(legs.fare_detail.status)"
                            @change="
                              onSelectLegHandler(legs.selected, legIndex)
                            "
                            v-model="legs.selected"
                          />
                          <RefundStatusFlag
                            v-else
                            :code="legs.fare_detail.status"
                          />
                          <MChip variant="success" class="mchip-checkbox">
                            <div class="m-multiline-chip-wrapper">
                              <div>
                                {{ renderSegmentNames(legs) }}
                              </div>
                              <div>
                                {{ renderSegmentDate(legs) }} -
                                {{ renderSegmentTime(legs) }}
                              </div>
                            </div>
                          </MChip>
                        </div>
                        <div class="leg-refund-details">
                          <div class="refund-charges-typographies">
                            <MTypography>Base Refund Charges</MTypography>
                            <MTypography type="label">{{
                              getLegsInfo(legs).base_charges
                            }}</MTypography>
                          </div>

                          <div class="refund-charges-typographies">
                            <MTypography>Other Refund Charges</MTypography>
                            <MTypography type="label">{{
                              getLegsInfo(legs).other_charges
                            }}</MTypography>
                          </div>

                          <div class="refund-charges-typographies">
                            <MTypography>Base Fare</MTypography>
                            <MTypography type="label">{{
                              getLegsInfo(legs).base_fare
                            }}</MTypography>
                          </div>
                          <div class="refund-charges-typographies">
                            <MTypography>Fees</MTypography>
                            <MTypography type="label">{{
                              getLegsInfo(legs).fees
                            }}</MTypography>
                          </div>

                          <div class="refund-charges-typographies">
                            <MTypography>Taxes</MTypography>
                            <MTypography type="label">{{
                              getLegsInfo(legs).taxes
                            }}</MTypography>
                          </div>
                          <div class="refund-charges-typographies">
                            <MTypography>Others</MTypography>
                            <MTypography type="label">{{
                              getLegsInfo(legs).others
                            }}</MTypography>
                          </div>

                          <div class="refund-charges-typographies">
                            <MTypography>Total Fare</MTypography>
                            <MTypography type="label">{{
                              getLegsInfo(legs).total_fare
                            }}</MTypography>
                          </div>
                        </div>
                      </div>
                    </MCard>
                  </div>
                </template>
              </MAccordion>
            </div>
          </div>

          <div v-if="hasSelectedLegs" class="text-right">
            <MButton
              class="calculate-btn"
              variant="filled"
              :disabled="isCalculatingRefund"
              @click="handleCalculateRefundDetails()"
            >
              {{
                isCalculatingRefund ? "Calculating Refund" : "Calculate Refund"
              }}
            </MButton>
          </div>
        </div>
      </template>
    </MAccordion>

    <!-- calculate summary -->
    <CalculateSummary v-if="hasCalculatedRefundDetails()" />

    <!-- otp action -->
    <MCard v-if="calculatedRefundDetails">
      <div class="m-refunds">
        <m-textfield
          class="m-otp"
          v-model:inputValue="OTP.value"
          label="OTP"
          :hasError="OTP.hasError"
          :errorMessage="OTP.errorMessage"
        />
        <m-button
          type="filled"
          @click="handleCommitRefundDetails"
          :disabled="isCommitingRefund"
        >
          Commit Refund
        </m-button>
      </div>
    </MCard>
  </div>
</template>

<script lang="ts">
import RefundStatusFlag from "../components/RefundedFlag.vue";
import { REFUND_TYPES } from "@/ag-flight-components/interfaces/IFlightRefunds.interface";
import { defineComponent } from "vue";
import { format, parseISO } from "date-fns";
import PenaltyDetails from "@/modules/FlightRefunds/components/PenaltyDetails.vue";
import CalculateSummary from "@/modules/FlightRefunds/components/CalculateSummary.vue";
import {
  MCard,
  MTextfield,
  MCombobox,
  MButton,
  MTypography,
  MChip,
} from "@aeroglobe/ag-core-ui";

import {
  TravelerTypes,
  getMaterialTravelerTypeName,
  getMaterialTravelerTypeIcon,
} from "@/ag-flight-components/constants/TravelerTypes";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";
import { fetchRefundPNRValidation } from "@/ag-flight-components/validations/pnrFetchValidation";
import { ValidationError } from "yup";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { FLIGHT_REFUNDS_ANALYTICS_EVENTS } from "@/modules/FlightRefunds/constants/analyticsEvents";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import AnalyticsService from "@/analytic.service";
import {
  LegDetail,
  PassengerDetail,
  Penalty,
} from "@/modules/FlightRefunds/constants/index";
import {
  CalculateFlightRefundPNRResponse,
  FetchFlightRefundPNRResponse,
  PenaltyDetail,
} from "@/ag-flight-components/interfaces/IFlightRefunds.interface";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";

export default defineComponent({
  name: "FlightRefunds",
  components: {
    MCard,
    MTextfield,
    MCombobox,
    MButton,
    PenaltyDetails,
    CalculateSummary,
    RefundStatusFlag,
    MChip,
    MTypography,
  },
  unmounted() {
    this.handleDataReset();
  },
  computed: {
    financialProfiles(): FPComboboxOptions[] {
      let financialProfiles = this.$store.getters.issuanceFinancialProfiles;

      if (financialProfiles) {
        return financialProfiles?.map((fp: IFinancialProfile) => {
          const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
          const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
          return {
            id: fp?.platform_id,
            label: fp?.financial_profile_name,
            value: fp?.public_id,
            isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
            status: fp?.status,
            sector: sector,
            type: planType,
          };
        });
      } else {
        return [];
      }
    },
    isFinancialProfilesLoading(): boolean {
      return this.$store.getters.isFinancialProfilesFetching;
    },
    hasPnrDetails(): boolean {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return fetchRefundDetails ? true : false;
    },
    hasPenalties(): boolean {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return Object.keys(fetchRefundDetails.penalties).length > 0
        ? true
        : false;
    },
    hasPassengers(): boolean {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return Object.keys(fetchRefundDetails.passenger_details).length > 0
        ? true
        : false;
    },
    passengers(): PassengerDetail[] {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      return fetchRefundDetails.passenger_details;
    },
    canFetchPnrDetails(): boolean {
      return !(this.infoHasErrors() || this.$store.getters.isPNRFetching);
    },
    suppliers(): {
      label: string;
      value: string;
    }[] {
      const suppliers = [
        {
          label: "Airblue",
          value: "airblue",
        },
        {
          label: "Airsial",
          value: "airsial_api",
        },

        {
          label: "PIA",
          value: "pia",
        },
      ];
      return suppliers;
    },
    isFetchingPNR(): boolean {
      return this.$store.getters.isPNRFetching;
    },
    calculatedRefundDetails(): CalculateFlightRefundPNRResponse | null {
      return this.$store.getters.calculateRefundDetails;
    },
    isCalculatingRefund(): boolean {
      return this.$store.getters.isCalculatingRefund;
    },
    isCommitingRefund(): boolean {
      return this.$store.getters.isCommitingRefund;
    },
  },
  async created() {
    await this.handleFetchPnrDetailsByRoute(
      await this.$store.dispatch("fetchFinancialProfiles")
    );
  },
  watch: {
    async "flightInformation.financialProfile.value"(value: string) {
      if (value.length > 0 && this.flightInformation.pnrNumber.value) {
        await this.handleFetchPnrDetailsByRoute(true);
      }
    },
  },
  data() {
    return {
      isPassengerSelected: false,
      showPassenger: true,
      showLegs: false,
      selectedLegIds: [],
      hasSelectedLegs: false,
      isPassengerDetailsOpen: true,
      hasLegs: false,
      legsCount: 0,
      flightInformation: {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        supplier: {
          value: "",
          hasError: false,
          errorMessage: "",
          label: "",
        },
        pnrNumber: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },
      OTP: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      validateFlightInfoErrors: false,
      validateFlightrefundsErrors: false,
    };
  },
  methods: {
    async handleFetchPnrDetailsByRoute(canFetchPNRDetails = true) {
      const QUERY = this.$route.query;
      const PNR = QUERY.pnr as string | null;
      const SUPPLIER = QUERY.supplier as string | null;

      if (!(PNR && SUPPLIER)) {
        return;
      }

      this.$store.commit("setPnr", PNR);
      this.flightInformation.pnrNumber.value = PNR;

      const supplier = this.suppliers.find(
        (s) => s.label.toLowerCase() === SUPPLIER.toLowerCase()
      );
      this.flightInformation.supplier.value = supplier ? supplier.value : "";

      if (canFetchPNRDetails) {
        setTimeout(async () => {
          await this.handleFetchPnrDetails();
        }, 300);
      }
    },

    refundNote(supplierValue: string) {
      const refundLabel = this.getSupplierLabel(supplierValue);
      const refundTypeMessage = this.refundType();
      const refundNote = refundLabel + " offer " + refundTypeMessage;
      return refundNote;
    },
    getSupplierLabel(value: string) {
      const supplier = this.suppliers.find((s) => s.value === value);
      const label = supplier ? supplier.label : "";
      return label;
    },
    refundType() {
      const fetchRefundDetails = this.$store.getters.refundPNRDetails;
      const refund_types: string = fetchRefundDetails.refund_type;
      let message =
        this.refundHandler(refund_types)
          .replace(/_/g, " ")
          .toLocaleLowerCase() + " refund";
      return message;
    },
    refundHandler(refundType: string): string {
      switch (refundType) {
        case REFUND_TYPES.LEG_WISE:
          this.showLegs = true;
          this.showPassenger = false;
          return refundType;
        case REFUND_TYPES.PASSENGER_WISE:
          this.showPassenger = true;
          this.showLegs = false;
          return refundType;
        case REFUND_TYPES.BOTH:
          this.showLegs = true;
          this.showPassenger = true;
          return refundType;

        default:
          return "Not available";
      }
    },
    getSelectedPassengerLegs() {
      const fetchRefundDetails: FetchFlightRefundPNRResponse =
        this.$store.getters.refundPNRDetails;
      const passengerDetails: PassengerDetail[] =
        fetchRefundDetails.passenger_details;
      const legToRefund: LegDetail[] = [];

      if (!this.isPassengerSelected) {
        passengerDetails.forEach((passenger: any) => {
          passenger.leg_details.forEach((leg: any) => {
            if (
              leg.selected === true &&
              this.isUnselected(leg.fare_detail.status)
            ) {
              legToRefund.push(leg.fare_detail.public_id);
            }
          });
        });
      } else {
        passengerDetails.forEach((passenger: any) => {
          if (passenger.selected === true) {
            passenger.leg_details.forEach((leg: any) => {
              if (this.isUnselected(leg.fare_detail.status)) {
                legToRefund.push(leg.fare_detail.public_id);
              }
            });
          }
        });
      }

      return legToRefund;
    },
    isFullyRefunded(): boolean {
      const passengers = this.passengers;
      for (let passenger of passengers) {
        for (let leg of passenger.leg_details) {
          if (this.isUnselected(leg.fare_detail.status)) {
            return false;
          }
        }
      }
      return true;
    },
    handleDataReset(): void {
      this.selectedLegIds = [];
      this.flightInformation.pnrNumber.value = "";
      this.flightInformation.supplier.value = "";
      this.OTP.value = "";
      this.showLegs = false;
      this.showPassenger = false;
      this.flightInformation.financialProfile.value = "";
      this.$store.commit("resetRefundPNRDetails");
    },
    handlePassengerSelect(isSelected: boolean): void {
      if (isSelected) {
        this.showLegs = false;
        this.showPassenger = true;
        this.isPassengerSelected = true;
        this.hasSelectedLegs = true;
        this.OTP.value = "";
      } else {
        this.refundType();
        this.OTP.value = "";
        this.showPassenger = true;
        this.isPassengerSelected = false;
        this.hasSelectedLegs = false;

        const passengers = this.passengers;

        passengers.forEach((passenger) => {
          if (passenger.selected) {
            this.hasSelectedLegs = true;
            return;
          }
        });
      }
    },
    hasCalculatedRefundDetails(): CalculateFlightRefundPNRResponse | null {
      const hasCalculatedRefundDetails = this.calculatedRefundDetails;

      if (hasCalculatedRefundDetails) {
        this.isPassengerDetailsOpen = false;
      }

      return hasCalculatedRefundDetails;
    },
    isPassengerFullyRefunded(passenger: PassengerDetail): boolean {
      for (let leg of passenger.leg_details) {
        if (this.isUnselected(leg.fare_detail.status)) {
          return false;
        }
      }

      return true;
    },
    isUnselected(status: string): boolean {
      return status === "UNSELECTED";
    },
    onSelectLegHandler(selected: boolean, legIndex: number): void {
      const passengers = this.passengers;

      this.hasSelectedLegs = false;

      passengers.forEach((passenger) => {
        if (passenger.leg_details[legIndex]) {
          passenger.leg_details[legIndex].selected = selected;
        }
      });

      passengers.forEach((passenger) => {
        if (passenger.leg_details[legIndex]) {
          const selectedLeg = passenger.leg_details[legIndex].selected;
          if (selectedLeg === true) {
            this.hasSelectedLegs = true;
          }
        }
      });
    },
    handlePenalties(penalties: Penalty): PenaltyDetail[] {
      return penalties.penalty_details;
    },
    formattedTime(date: string): string {
      const arrivalTime = new Date(date);
      const parsedTime = parseISO(arrivalTime.toISOString());
      const formattedTime = format(parsedTime, "HH:mm");
      return formattedTime;
    },
    formattedDate(date: string): string {
      const arrivalDate = new Date(date);
      const parsedDate = parseISO(arrivalDate.toISOString());
      const formattedDate = format(parsedDate, "EEE, MMM dd, yyyy");
      return formattedDate;
    },
    renderSegmentNames(legs: LegDetail): string {
      const segmentDetails = legs.segment_details;
      let legInformation = "";

      for (const segment of segmentDetails) {
        legInformation +=
          segment.departure_city_code + " - " + segment.arrival_city_code + " ";
      }

      return legInformation.trim();
    },
    renderSegmentDate(legs: LegDetail): string {
      const segmentDetails = legs.segment_details;

      return this.formattedDate(segmentDetails[0].departure_date_time);
    },
    renderSegmentTime(legs: LegDetail): string {
      const segmentDetails = legs.segment_details;

      return this.formattedTime(segmentDetails[0].departure_date_time);
    },
    hasLegDetails(legs: LegDetail[]): boolean {
      this.legsCount = legs.length;
      this.hasLegs = legs ? true : false;

      return this.hasLegs;
    },
    getLegsInfo(legs: LegDetail): {
      fees: string;
      base_fare: string;
      others: string;
      taxes: string;
      total_fare: string;
      base_charges: string;
      other_charges: string;
    } {
      const fareInfo = legs.fare_detail.fare_info;
      const refundInfo = legs.fare_detail.refund_charges;
      const currency = legs.fare_detail.currency_code;

      const getCurrency = (
        type: "total_fare" | "base_fare" | "taxes" | "fees" | "others"
      ) => this.formattedCurrency(currency, fareInfo[type].value);

      const fees = getCurrency("fees");
      const base_fare = getCurrency("base_fare");
      const others = getCurrency("others");
      const total_fare = getCurrency("total_fare");
      const taxes = getCurrency("taxes");
      const base_charges = this.formattedCurrency(
        currency,
        refundInfo.base_refund.value
      );
      const other_charges = this.formattedCurrency(
        currency,
        refundInfo.other_charges.value
      );

      const legsData = {
        fees,
        base_fare,
        others,
        taxes,
        total_fare,
        base_charges,
        other_charges,
      };

      return legsData;
    },
    renderPassengerTitle(passenger: PassengerDetail): string {
      const { title, first_name, last_name } = passenger;

      return `${title ? title : ""} ${first_name} ${last_name}`;
    },
    renderPassengerType(passengerType: string): {
      type: string;
      icon: string;
    } {
      const type = getMaterialTravelerTypeName(passengerType as TravelerTypes);
      const icon = getMaterialTravelerTypeIcon(type as TravelerTypes);

      return {
        type,
        icon,
      };
    },
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
    transformPnrData(): {
      pnr: string;
      provider: string;
      financial_profile_public_id: string;
    } {
      const { pnrNumber } = this.flightInformation;

      const mappedData = {
        pnr: pnrNumber.value.trim(),
        provider: this.flightInformation.supplier.value,
        financial_profile_public_id:
          this.flightInformation.financialProfile.value,
      };

      return mappedData;
    },
    infoHasErrors(): boolean {
      const transformedArray = Object.keys(this.flightInformation).map(
        (key) => (this.flightInformation as any)[key]
      );

      this.handleFlightInfoErrors();
      return transformedArray.some((item) => item.hasError);
    },
    handleFlightInfoErrors(): void {
      if (this.validateFlightInfoErrors) {
        const { supplier, pnrNumber, financialProfile } =
          this.flightInformation;

        if (supplier.value === "") {
          supplier.hasError = true;
          supplier.errorMessage = "Supplier is required";
        } else {
          supplier.hasError = false;
          supplier.errorMessage = "";
        }
        if (pnrNumber.value === "") {
          pnrNumber.hasError = true;
          pnrNumber.errorMessage = "PNR is required";
        } else {
          pnrNumber.hasError = false;
          pnrNumber.errorMessage = "";
        }
        if (financialProfile.value === "") {
          financialProfile.hasError = true;
          financialProfile.errorMessage = "Financial Profile is required";
        } else {
          financialProfile.hasError = false;
          financialProfile.errorMessage = "";
        }
      }
    },
    handleFlightrefundsErrors(): void {
      if (this.validateFlightrefundsErrors) {
        if (this.OTP.value === "") {
          this.OTP.hasError = true;
          this.OTP.errorMessage = "OTP is required";
        } else {
          this.OTP.hasError = false;
          this.OTP.errorMessage = "";
        }
      }
    },
    async handleCalculateRefundDetails(): Promise<void> {
      if (this.hasSelectedLegs) {
        const selectedPassengerLegs = this.getSelectedPassengerLegs();
        const payload = {
          fare_refund_ids: selectedPassengerLegs,
          financial_profile_public_id:
            this.flightInformation.financialProfile.value,
        };
        const analyticsPayload = {
          "fare-refund-ids": selectedPassengerLegs,
          "financial-profile-public-id":
            this.flightInformation.financialProfile.value,
        };

        AnalyticsService.logActionEvent(
          FLIGHT_REFUNDS_ANALYTICS_EVENTS.CALCULATE_REFUND,
          analyticsPayload
        );

        await this.$store.dispatch("calculateRefundPNR", payload);
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "No selected leg found";
        notificationService.triggerNotification();
      }
    },
    async handleCommitRefundDetails(): Promise<void> {
      if (!this.calculatedRefundDetails) {
        return;
      }

      const payload = {
        otp: this.OTP.value,
        refund_reference_id:
          this.calculatedRefundDetails.refund_reference_public_id,

        financial_profile_public_id:
          this.flightInformation.financialProfile.value,
      };
      await this.$store.dispatch("commitRefundPNR", payload);
      const analyticsPayload = {
        otp: this.OTP.value,
        "refund-reference-id":
          this.calculatedRefundDetails.refund_reference_public_id,
        "financial-profile-public-id":
          this.flightInformation.financialProfile.value,
      };
      AnalyticsService.logActionEvent(
        FLIGHT_REFUNDS_ANALYTICS_EVENTS.COMMIT_REFUND,
        analyticsPayload
      );
    },

    // FETCH PNR
    async handleFetchPnrDetails(): Promise<void> {
      this.validateFlightInfoErrors = true;
      this.showLegs = false;
      this.handleFlightInfoErrors();
      if (this.infoHasErrors()) {
        return;
      }

      try {
        const payload = this.transformPnrData();
        const request = await fetchRefundPNRValidation.validate(payload, {
          abortEarly: false,
        });
        this.$store.commit("resetRefundPNRDetails");

        await this.$store.dispatch("fetchRefundPNR", request);

        const analyticsPayload = {
          "pnr-number": this.flightInformation.pnrNumber.value,
          provider: this.flightInformation.supplier.value,
          "financial-profile-public-id":
            this.flightInformation.financialProfile.value,
        };

        AnalyticsService.logActionEvent(
          FLIGHT_REFUNDS_ANALYTICS_EVENTS.FETCH_PNR_DETAILS,
          analyticsPayload
        );

        this.selectedLegIds = [];
      } catch (ex) {
        if (ex instanceof ValidationError) {
          yupValidationErrorAsSchema(ex);
        }
      } finally {
        this.handleFlightInfoErrors();
      }
    },
  },
});
</script>

<style lang="css" scoped>
.no-padding-card {
  padding: 0px;
  margin-bottom: 5px;
}
.refund-passenger {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.contact-info {
  min-width: inherit;
  margin-left: 10px;
}
.calculate-btn {
  margin-bottom: 10px;
  margin-top: 0;
}
</style>

<style scoped>
.flight-refunds-content {
  padding-top: 24px;
}
.accordion-header {
  border: none;
  padding-bottom: 0;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
}
.passenger-accordion-header {
  padding: 5px 20px;
  border: 1px solid var(--m-divider-color);
}
.refunds-checkbox {
  min-width: max-content;
  min-height: 50px;
  /* margin-top: 10px; */
  font-weight: bolder;
  margin-left: 10px;
  max-width: 100%;
  flex: 1;

  width: 100%;
  display: flex;
  align-items: center;
}
.mchip-checkbox {
  min-width: max-content;
  min-height: 50px;
  margin-top: 10px;
  font-weight: bolder;
  margin-left: 10px;
}
.refunds {
  --text-color: #49454f;
  --label-color: #636363;
  --border-color: #ababab;
  --error-color: #b3261e;
  --theme-color: var(--green-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.refunds-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.refunds-inputs,
.refunds-action {
  margin-bottom: 16px;
}
.refunds-inputs .row {
  display: flex;
  gap: 24px;
  width: 100%;
}
.refunds .actions {
  display: flex;
  gap: 16px;
}
.m-otp {
  max-width: 50%;
}
.m-success-message {
  color: #22bb33;
}
.m-multiline-chip-wrapper {
  text-align: left;
  max-lines: 2;
  align-items: start;
}

@media screen and (max-width: 600px) {
  .refunds-inputs .row {
    display: flex;
    flex-direction: column;
  }
  .m-refunds .m-otp {
    max-width: none;
  }
}

@media screen and (max-width: 500px) {
  .refunds .actions {
    flex-direction: column;
  }
  .refunds .actions button,
  .m-refunds button {
    width: 100%;
  }
  .refund-passenger {
    align-items: start;
  }
}
.refund-charges-typographies {
  margin-right: 25px;
}
.leg-refund-details {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}
.un-selected-passenger {
  display: flex;
  flex-wrap: "wrap";
  align-items: center;
  margin: 10px 0px;
}
.selected-passenger {
  display: auto;
  flex-wrap: "wrap";
  align-items: center;
  margin: 10px 0px;
}
</style>
