import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64447402"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "postings" }
const _hoisted_2 = { class: "accordion-title" }
const _hoisted_3 = { class: "accordion-title" }
const _hoisted_4 = { class: "accordion-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_agheading = _resolveComponent("agheading")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    onClose: _ctx.onCloseModal,
    "modal-width": "60%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_agheading, null, {
        default: _withCtx(() => [
          _createTextVNode("Pending Receipt and Invoice of "),
          _createElementVNode("b", null, _toDisplayString(_ctx.financialProfile.financial_profile_name), 1)
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.pendingPostingFlightInvoices.length > 0)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 0,
              "initial-open": false
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_component_AgIconInfoBar, {
                    "test-id": "",
                    icon: "currentBalance",
                    title: "Flight Invoices",
                    class: "accordion-title accordions"
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createVNode(_component_MDataTable, {
                  headers: _ctx.flightInvoicesHeaders,
                  data: _ctx.pendingPostingFlightInvoices,
                  "item-per-page": 10,
                  "total-item-count": _ctx.pendingPostingFlightInvoices.length,
                  "has-search": false,
                  "is-api-paginated": false
                }, {
                  ticket_no: _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.ticket_no), 1)
                  ]),
                  pnr: _withCtx(({ item }) => [
                    _createVNode(_component_MChip, {
                      class: "chip-width",
                      "border-less": true,
                      variant: "warning"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.pnr), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  posting_status: _withCtx(({ item }) => [
                    _createVNode(_component_MChip, {
                      class: "chip-width",
                      "border-less": true,
                      variant: "error"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.posting_status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "data", "total-item-count"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.pendingPostingFlightReceipts.length > 0)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 1,
              "initial-open": false
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_AgIconInfoBar, {
                    "test-id": "",
                    icon: "currentBalance",
                    title: "Flight Receipts",
                    class: "accordion-title accordions"
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createVNode(_component_MDataTable, {
                  headers: _ctx.flightReceiptsHeaders,
                  data: _ctx.pendingPostingFlightReceipts,
                  "item-per-page": 10,
                  "has-search": false,
                  "is-api-paginated": false
                }, {
                  payment_id: _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.payment_id), 1)
                  ]),
                  amount: _withCtx(({ item }) => [
                    _createVNode(_component_MChip, {
                      class: "chip-width",
                      "border-less": true,
                      variant: "warning"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.amount), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  posting_status: _withCtx(({ item }) => [
                    _createVNode(_component_MChip, {
                      class: "chip-width",
                      "border-less": true,
                      variant: "error"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.posting_status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "data"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.pendingPostingHotelInvoices.length > 0)
          ? (_openBlock(), _createBlock(_component_MAccordion, {
              key: 2,
              "initial-open": false
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, [
                  _createVNode(_component_AgIconInfoBar, {
                    "test-id": "",
                    icon: "currentBalance",
                    title: "Hotel Invoices",
                    class: "accordion-title accordions"
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createVNode(_component_MDataTable, {
                  headers: _ctx.hotelInvoicesHeaders,
                  data: _ctx.pendingPostingHotelInvoices,
                  "item-per-page": 10,
                  "has-search": false,
                  "is-api-paginated": false
                }, {
                  booking_id: _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.booking_id), 1)
                  ]),
                  status: _withCtx(({ item }) => [
                    _createVNode(_component_MChip, {
                      class: "chip-width",
                      "border-less": true,
                      variant: "error"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "data"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (
          _ctx.pendingPostingFlightInvoices.length === 0 &&
          _ctx.pendingPostingFlightReceipts.length === 0 &&
          _ctx.pendingPostingHotelInvoices.length === 0
        )
        ? (_openBlock(), _createBlock(_component_AgNotFound, {
            key: 0,
            "test-id": "",
            heading: "No Pending Receipts & Invoices Found",
            description: ""
          }))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_AGButton, {
        onClick: _ctx.onCloseModal,
        variant: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["is-open", "onClose"]))
}